import { HorseHistoryItem, Runner } from "../Forming/FormingTypes";


export function splitSTAT(str: string): string[] {
  if (!str || !str.includes(":")) {
    return [];
  }
  const pieces = str.split(":");
  return pieces[1] ? pieces[1].split("-") : [];
}

export function checkDistance(distance: string): number {
  let points = 0;

  const pieces = splitSTAT(distance);

  if (pieces.length >= 3) {
    if (Number(pieces[0]) > 0) {
      points += 2;
    }
    if (Number(pieces[1]) > 0) {
      points += 1;
    } else if (Number(pieces[2]) > 0) {
      points += 1;
    }
  }

  return points;
}

export const calculatePlacingPercentage = (stat: string): number => {
  if (!stat) return 0;

  const parts = stat.split(":");
  if (parts.length < 2) return 0;

  const totalRaces = parseInt(parts[0], 10);
  if (isNaN(totalRaces) || totalRaces <= 0) {
    return 0;
  }

  const placings = parts.slice(1).join("-").split("-").map(Number);

  const paddedPlacing =
    placings.length < totalRaces
      ? [...placings, ...new Array(totalRaces - placings.length).fill(0)]
      : placings;

  const totalPlacing = paddedPlacing.reduce((acc, placing) => acc + placing, 0);

  const averagePlacing =
    totalRaces === 0 ? 0 : (totalPlacing / totalRaces) * 100;

  return parseFloat(averagePlacing.toFixed(2)); // Return as a number
};

export const calculateCareerPercentage = (careerStat: string): string => {
  if (!careerStat) return "0";

  const [totalRaces, positionCounts] = careerStat.split(":");
  if (!positionCounts || isNaN(Number(totalRaces)) || totalRaces === "0")
    return "0";
  const positions = positionCounts.split(".").map(Number);
  const totalPositions = positions.reduce(
    (acc, pos) => acc + (isNaN(pos) ? 0 : pos),
    0,
  );

  const percentage = totalRaces
    ? (totalPositions / Number(totalRaces)) * 100
    : 0;
  if (percentage === 0 || isNaN(percentage)) {
    return "0";
  }

  return percentage.toFixed(2);
};
export const getBarrierPoints = (barrier: any): number => {
  const barrierNumber = Number(barrier);

  if (barrierNumber === 1 || barrierNumber === 2 || barrierNumber === 3) {
    return 4;
  } else if (
    barrierNumber === 4 ||
    barrierNumber === 5 ||
    barrierNumber === 6
  ) {
    return 2;
  }

  return 0;
};
export const checkLastForm = (form: string, firstUp: string): number => {
  let lastCharacter = form;
  let points = 0;
  lastCharacter = lastCharacter.substring(lastCharacter.length - 1);
  if (lastCharacter.includes("-") || lastCharacter.includes("/")) {
    let pieces = splitSTAT(firstUp);
    if (
      Number(pieces[0]) > 0 ||
      Number(pieces[1]) > 0 ||
      Number(pieces[2]) > 0
    ) {
      points = 3;
      return points;
    }

    return points;
  }
  return points;
};
export const secondUpPoints = (form: string, firstUp: string): number => {
  let lastCharacter = form;
  let points = 0;
  lastCharacter = lastCharacter.substring(form.length - 2, form.length - 1);

  if (lastCharacter.includes("-") || lastCharacter.includes("/")) {
    let pieces = splitSTAT(firstUp);
    if (
      Number(pieces[0]) > 0 ||
      Number(pieces[1]) > 0 ||
      Number(pieces[2]) > 0
    ) {
      points = 3;
      return points;
    }

    return points;
  }
  return points;
};
export const weightPoints = (weightDifferenceKg: number): number => {
  let diff = 0;

  if (weightDifferenceKg >= 2.5) {
    diff = -3;
  } else if (weightDifferenceKg >= 0.5 && weightDifferenceKg <= 2) {
    diff = -1;
  } else if (weightDifferenceKg <= -2) {
    diff = 3;
  } else if (weightDifferenceKg <= -0.5 && weightDifferenceKg >= -1.5) {
    diff = 1;
  }

  return diff;
};
export const horseAge = (age: string): number => {
  const numericAge = Number(age);

  if ([3, 4, 5].includes(numericAge)) {
    return 3;
  } else if ([6, 7, 8].includes(numericAge)) {
    return 1;
  }

  return 0;
};
export const prevRacePosPoints = (place: string): number => {
  let value = 0;
  if (place === "1" || place === "2" || place === "3") {
    value = 3;
  }
  return value;
};

export const winningRecord = (careerStat: string): number => {
  if (!careerStat || typeof careerStat !== "string") {
    return 0;
  }
  const pieces = careerStat.split(":");
  if (pieces.length < 2) {
    return 0;
  }

  const totalStarts = Number(pieces[0]);
  if (isNaN(totalStarts) || totalStarts === 0) {
    return 0;
  }
  const STATPieces = pieces[1]?.split(".") || [];
  const wins = Number(STATPieces[0]);
  if (isNaN(wins)) {
    return 0;
  }
  let percentage = (wins / totalStarts) * 100;
  if (isNaN(percentage)) {
    percentage = 0;
  }
  return percentage < 17.5 ? -3 : 0;
};

export const winningPlaceRecord = (careerStat: string): number => {
  if (!careerStat || typeof careerStat !== "string") {
    return 0;
  }

  const pieces = careerStat.split(":");
  if (pieces.length < 2) {
    return 0;
  }
  const totalStarts = Number(pieces[0]);
  if (isNaN(totalStarts) || totalStarts === 0) {
    return 0;
  }
  const STATPieces = pieces[1]?.split(".") || [];
  if (STATPieces.length < 3) {
    return 0;
  }
  const placing =
    Number(STATPieces[0]) + Number(STATPieces[1]) + Number(STATPieces[2]);
  if (isNaN(placing)) {
    return 0;
  }
  let percentage = (placing / totalStarts) * 100;
  if (isNaN(percentage)) {
    percentage = 0;
  }
  return percentage < 45 ? -2 : 0;
};

export const calculateJockeyPoints = (
  horseHistory: HorseHistoryItem[],
  jockeyName: string,
  horseId: string,
): number => {
  const filteredRaces = horseHistory.filter((race) =>
    race.runners.$values.some((runnerItem) => runnerItem.horseId === horseId),
  );
  const sortedRaces = filteredRaces.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );
  const lastTenRaces = sortedRaces.slice(0, 10);

  const hasRidden = lastTenRaces.some((race) =>
    race.runners.$values.some(
      (runnerItem) =>
        runnerItem.horseId === horseId && runnerItem.jockey === jockeyName,
    ),
  );
  return hasRidden ? 3 : 0;
};

export const winRecordsDistance = (careerStat: string | undefined): number => {
  if (!careerStat) {
    return 0; // Return a number
  }

  let pieces = careerStat.split(":");

  if (pieces.length < 2) {
    return 0; // Return a number
  }

  let STATPieces = pieces[1].split(".");
  let percentage = Number(STATPieces[0]) / Number(pieces[0]);
  percentage = percentage * 100;

  if (isNaN(percentage)) {
    return 0; // Return a number
  }

  return percentage < 17.5 ? -3 : 0; // Return a number
};

export const lwinPlaceRecordsDistance = (careerStat: string | undefined): number => {
  if (!careerStat) {
    return 0;
  }

  const pieces = careerStat.split(":");
  if (pieces.length < 2) {
    return 0;
  }

  const STATPieces = pieces[1].split(".");
  const placing =
    Number(STATPieces[0] || 0) + Number(STATPieces[1] || 0) + Number(STATPieces[2] || 0);

  const totalStarts = Number(pieces[0]);
  if (isNaN(totalStarts) || totalStarts === 0) {
    return 0; // Avoid division by zero
  }

  const percentage = (placing / totalStarts) * 100;

  if (isNaN(percentage)) {
    return 0;
  }

  return percentage < 45 ? -2 : 0;
};

export const hasJockeyRiddenHorseBefore = (
  horseHistory: HorseHistoryItem[],
  jockeyName: string,
  horseId: string,
): boolean => {
  return horseHistory.some((race) =>
    race.runners.$values.some(
      (runnerItem) =>
        runnerItem.horseId === horseId && runnerItem.jockey === jockeyName,
    ),
  );
};
export const checkHeadgearChange = (
  headgear: string,
  lastRaceHeadgear: string | undefined,
): number => {
  if (headgear === lastRaceHeadgear) {
    return 0;
  } else {
    return 2;
  }
};
export const getJockeyPositionStatus = (
        lastRace: HorseHistoryItem,
        runner: Runner,
        hasSameJockeyLastRace: boolean
      ): number => {
        const lastRacePosition = lastRace?.runners.$values.find((item) => item.horseId === runner.horseId)?.position || "N/A";
        if (hasSameJockeyLastRace && lastRacePosition === "1") {
          return 2;
        } else {
          return 0;
        }
      };
     

     export const calculatePoints = (
        runner: Runner,
        horseStats: { [key: string]: { careerStat: string } },
        selectedRaceRunners: Runner[]
      ): number => {
        if (!runner || !horseStats[runner.horseId]) return 0;
      
        const careerPercentage = parseFloat(
          calculateCareerPercentage(horseStats[runner.horseId]?.careerStat || "0:0.0")
        );
      
        if (careerPercentage === 0) return 0;
      
        // Sort runners based on career percentage
        const sortedCareer = selectedRaceRunners
          .map((r) => ({
            ...r,
            careerPercentage: parseFloat(
              calculateCareerPercentage(horseStats[r.horseId]?.careerStat || "0:0.0")
            ),
          }))
          .filter((r) => !isNaN(r.careerPercentage))
          .sort((a, b) => b.careerPercentage - a.careerPercentage);
      
        const top3Career = sortedCareer.slice(0, 3);
        const next3Career = sortedCareer.slice(3, 6);
      
        return top3Career.some((horse) => horse.horseId === runner.horseId)
          ? 6
          : next3Career.some((horse) => horse.horseId === runner.horseId)
          ? 4
          : 0;
      };
      
      // **Usage inside calculateTotalPoints:**
    
      export function calculateTrackPoints(runner: Runner, horseStats: Record<string, any>, selectedRaceRunners: Runner[]): number {
      
        const allConditions = selectedRaceRunners.flatMap((r) =>
          Object.entries(horseStats[r.horseId]?.trackConditionStats || {}).map(
            ([cond, stat]) => ({
              horseId: r.horseId,
              condition: cond,
              percentage: calculatePlacingPercentage(stat as string), // Explicitly cast stat as number
            })
          )
        );
      
        const top3Conditions = allConditions.sort((a, b) => b.percentage - a.percentage).slice(0, 3);
      
        return Object.entries(horseStats[runner.horseId]?.trackConditionStats || {})
          .map(([condition, stat]) => {
            const percentage = calculatePlacingPercentage(stat as string); // Explicitly cast stat as number
      
            return percentage === 0
              ? 0
              : top3Conditions.some(
                  (item) => item.horseId === runner.horseId && item.condition === condition
                )
                ? 6
                : allConditions.slice(3, 6).some(
                    (item) => item.horseId === runner.horseId && item.condition === condition
                  )
                ? 4
                : allConditions.slice(6, 9).some(
                    (item) => item.horseId === runner.horseId && item.condition === condition
                  )
                ? 2
                : 0;
          })
          .reduce<number>((acc, val) => acc + val, 0);
      }
      
      
      export const calculateTotalPoints = (
        runner: Runner,
        horseHistory: HorseHistoryItem[],
        horseStats: {
          [key: string]: {
            distanceStat: string;
            trackStat: string;
            trackAndDistanceStat: string;
            trackConditionStats: { [condition: string]: string };
            careerStat: string;
            hasSameJockeyBefore: boolean;
            headgear: number;
            totalPrizeFirstRace: number;
            lastRaceHorsePosition: string;
            ageOfHorse: string;
            winPerc: number;
            winPlcPerc: number;
            lastWeight: string;
            barrier: number;
            gapCountMessage: string;
            secondUpCount: string;
            jockeyPositionStatus: number;
          };
        },
        selectedRaceRunners: Runner[]
      ): number => {
        if (!runner || !horseStats[runner.horseId]) {
          return 0; // Return 0 if runner or horse stats are missing
        }
        const points = calculatePoints(runner, horseStats, selectedRaceRunners);       
        const trackPoints= calculateTrackPoints(runner, horseStats, selectedRaceRunners)     
        const gearNumber = horseStats[runner.horseId]?.headgear || 0;
        const jockeyPosition = horseStats[runner.horseId]?.jockeyPositionStatus || 0;
       
        // Calculate total points
        const total: number =
          50 +
          points +
          checkDistance(horseStats[runner.horseId]?.distanceStat) +
          checkDistance(horseStats[runner.horseId]?.trackStat) +
          checkDistance(horseStats[runner.horseId]?.trackAndDistanceStat) +
          trackPoints +
          checkLastForm(runner.form, horseStats[runner.horseId]?.gapCountMessage) +
          secondUpPoints(runner.form, horseStats[runner.horseId]?.secondUpCount) +
          (horseStats[runner.horseId]?.totalPrizeFirstRace || 0) +
          gearNumber +
          calculateJockeyPoints(horseHistory, runner.jockey, runner.horseId) +
          getBarrierPoints(horseStats[runner.horseId]?.barrier) +
          weightPoints(parseFloat(horseStats[runner.horseId]?.lastWeight) || 0) +
          prevRacePosPoints(horseStats[runner.horseId]?.lastRaceHorsePosition) +
          jockeyPosition +
          horseAge(runner.age) +
          winRecordsDistance(horseStats[runner.horseId]?.careerStat) +
          winningPlaceRecord(horseStats[runner.horseId]?.careerStat) +
          winRecordsDistance(horseStats[runner.horseId]?.careerStat)+
          lwinPlaceRecordsDistance(horseStats[runner.horseId]?.careerStat);
        return total;
      };
      
      
