import { createSlice } from "@reduxjs/toolkit";
import { GetUsersThunk } from "../createAsyncThunk";

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
    error: null,
    loading: false,
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetUsersThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(GetUsersThunk.fulfilled, (state, action) => {
        state.users = action.payload;
        state.error = null;
        state.loading = false;
      })
      .addCase(GetUsersThunk.rejected, (state, action) => {
        state.users = [];
        state.error = action.payload as null;
        state.loading = false;
      });
  },
  reducers: {},
});

export default usersSlice.reducer;
