export interface RaceCard {
  raceId: string;
  course: string;
  date: string;
  raceName: string;
  distanceF: number;
  going: string;
  region: string;
  prize: string;
  ageBand: string;
  raceClass: string;
  offTime: string;
  weather:string;
  runners: { $values: Runner[] };
}

export interface Runner {
  horseId: string;
  horse: string;
  age: string;
  trainer: string;
  jockey: string;
  form: string;
  jockeyId: string;
  lbs: string;
  region: string;
  colour: string;
  sex: string;
  sire: string;
  dam: string;
  damsire: string;
  owner: string;
  trainerLocation: string;
  prize: number;
  draw: number;
  weight: number;
  number: number;
  headgear: string;
  dob : Date;
}

export interface HorseHistoryItem {
  dist: string;
  distF: string;
  distM: string;
  distY: string;
  date: string;
  course: string;
  going: string;
  raceName: string;
  off: string;
  ageBand: string;
  sexRest: string;
  runners: Runners;
  horseId: string;
  raceId: string;
  class: string;
  region: string;
  comment: string;
}
export interface Runners {
  $values: RunnerItem[];
}
export interface RunnerItem {
  position: string;
  horseId: string;
  jockey: string;
  headgear: string;
  prize: number;
  weightLbs: string;
  draw: number;
  time: number;
  horse: string;
  comment: string;
}
export interface HorseHistories {
  [horseId: string]: HorseHistoryItem[];
}

export const trackConditionAbbreviations: { [key: string]: string } = {
  Firm: "F",
  Good: "G",
  Soft: "S",
  Heavy: "H",
  "Good To Firm": "GF",
  "Good To Soft": "GS",
  Standard: "St",
  "Standard To Slow": "SS",
  Yielding: "Y",
  "Good To Yielding": "GY",
  "Very Soft": "VS",
  "Yielding To Soft": "YS",
  "Soft To Heavy": "SH",
  Synthetic:"SY"
};

export interface horseStatsType {
  [key: string]: {
    distanceStat: string;
    trackStat: string;
    trackAndDistanceStat: string;
    trackConditionStats: { [condition: string]: string };
    careerStat: string;
    hasSameJockeyBefore: boolean;
    headgear: string;
    totalPrizeFirstRace: string;
    gapFromTomorrow: string;
    gapCountMessage: string;
    secondUpCount: string;
    thirdUpCount: string;
    gapFromLastTwoRaces: string;
    lastRacePosition: string;
    winPerc: number;
    winPlcPerc: number;
    lastWeight: string;
    weightDiff: string;
    horseWeight: number;
    totalPrize: number;
    lastSixPositions: Array<string>;
  };
}
