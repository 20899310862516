
function decodeJWT(token: any) {
    try {
        token = String(token);
    } catch (error) {
        // throw new Error("Token cannot be converted to a string");
    }
    const parts = token.split(".");
    if (parts?.length !== 3) {
        // throw new Error("Invalid JWT: The token should consist of three parts.");
    }

    const base64UrlToBase64 = (base64Url: any) => {
        if (!base64Url) {
            // throw new Error("Invalid JWT: Payload is missing.");
        }
        return base64Url.replace(/-/g, "+").replace(/_/g, "/");
    };

    try {
        const base64 = base64UrlToBase64(parts[1]);
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join(""),
        );

        return JSON.parse(jsonPayload);
    } catch (e) {
        // Handle errors from invalid base64 strings or malformed JSON
        // throw new Error("Invalid JWT: Failed to decode payload.");
    }
}

export { decodeJWT };

