export enum raceResultsTypes {
  WON = "WON",
  SECOND = "2ND",
  THIRD = "3RD",
  LOST = "LOST",
  // SCRATCHED = "Scratched",
}

export const raceResults = [
  raceResultsTypes.WON,
  raceResultsTypes.SECOND,
  raceResultsTypes.THIRD,
  raceResultsTypes.LOST,
];
