import { RacingAPIService } from "Services/RacingAPIService";
import { RaceCard, Runner } from "../Forming/FormingTypes";
import { calculateTotalPoints } from "./GeneratePointsUtils";

interface HorseData {
  course: string;
  race: RaceCard;
  horseStats: { [key: string]: any }; 
  horseHistory: Runner[];

}
const cleanHorseData = (horseData: HorseData[]) => {
  console.log("Before Cleaning:", horseData);

  let cleanedData: any = [];

  horseData.forEach((data) => {
    cleanedData.push(
      {
        course: data.course,
        race: {
          raceId: data.race.raceId,
          course: data.race.course,
          date: data.race.date,
          raceName: data.race.raceName,
          distanceF: parseFloat(data.race.distanceF.toString()),
          going: data.race.going,
          region: data.race.region,
          prize: data.race.prize,
          ageBand: data.race.ageBand,
          raceClass: data.race.raceClass,
          offTime: data.race.offTime,
          weather: data.race.weather,
          runners: data.race.runners.$values
            ? data.race.runners.$values.map((runner) => ({
              horseId: runner.horseId,
              horse: runner.horse,
              age: runner.age,
              trainer: runner.trainer,
              jockey: runner.jockey,
              form: runner.form,
              jockeyId: runner.jockeyId,
              lbs: (parseInt(runner.lbs) * 0.453592).toFixed(1),
              region: runner.region,
              colour: runner.colour,
              sex: runner.sex,
              sire: runner.sire,
              dam: runner.dam,
              damsire: runner.damsire,
              owner: runner.owner,
              trainerLocation: runner.trainerLocation,
              prize: typeof runner.prize === "number"
                ? runner.prize
                : parseFloat(String(runner.prize).replace(/[^\d.-]/g, "")) || 0,
              draw: parseInt(String(runner.draw)) || 0,
              weight: parseInt(String(runner.weight)) || 0,
              number: parseInt(String(runner.number)) || 0,
              headgear: runner.headgear,
              dob: runner.dob,
              horseStats: data.horseStats || {},
              horseHistory: assignHistory(runner.horseId, horseData)
            }))
            : [],
        },
      }
    );
  })

  return cleanedData;
};

const assignHistory = (horseId: string, horseData: any) => {
  let history: Runner[] = [];

  horseData.forEach((hData: any) => {
    if (!hData.horseHistory) return; 

    hData.horseHistory.forEach((runner: any) => {
      if (runner.Forms && Array.isArray(runner.Forms)) { 
        runner.Forms.forEach((form: any) => {
          if (form.horseId === horseId) {
            history.push(runner);
          }
        });
      }
    });
  });

  return history;
};



export const generateHorseJson = async (
  course: string,
  race: RaceCard,
  horseStats: { [key: string]: any },
  runner: Runner,
): Promise<HorseData> => {
  try {
    const response = await RacingAPIService.getHorseResults(runner.horseId);
    const allHorseHistory = response?.data?.results?.$values || [];
   console.log(allHorseHistory,"all horse history")


  const horseHistory = allHorseHistory.map((race: any) => {
    if (!race.runners) return null;

    const runnersList = race.runners.$values || race.runners;
    const specificRunner = runnersList.find(
      (r: any) => r.horseId === runner.horseId
    );
  

    if (!specificRunner) return null; 
      return {
            Forms:[ {
              date:race.date, 
              course: race.course || "",
              horseId: specificRunner.horseId || "",
              horse: specificRunner.horse || "",
              age: specificRunner.age || "",
              jockey: specificRunner.jockey || "Unknown",
              form: specificRunner.form || "N/A",
              jockeyId: specificRunner.jockeyId,
              lbs: specificRunner.lbs || "N/A",
              region: specificRunner.region ||"",
              colour: specificRunner.colour ||"",
              sex: specificRunner.sex ||"",
              sire: specificRunner.sire ||"",
              dam: specificRunner.dam ||"",
              damsire: specificRunner.damsire ||"",
              owner: specificRunner.owner ||"",
              trainerLocation: specificRunner.trainerLocation ||"",
              prize: specificRunner.prize ||"",
              draw: specificRunner.draw ||"",
              weight: ((specificRunner.weightLbs)*0.453592).toFixed(1),
              number: (specificRunner.number) || 0,
              headgear: specificRunner.headgear || "None",
              position: specificRunner.position || "N/A",
              dob: specificRunner.dob ||"",
              going:race.going || "",
              racename:race.raceName || "",
              raceDate:race.offDt? new Date(race.offDt) :null,
              distance:race.distM,
              class:race.class,
              classAge:race.ageBand,
              raceTime:specificRunner.time,
              starters: race.runners?.$values?.length || 0
            }]
         
        
      };
    }).filter(Boolean); // Remove null entries

    return {
      course: course,
      race: race,
      horseStats: horseStats || {}, 
      horseHistory:  [...horseHistory] , 
    };
  } catch (error) {
    console.error(`Failed to fetch horse history for ${runner.horseId}:`, error);
    return {
      course: course,
      race: race,
      horseStats: {}, 
      horseHistory: [],
    };
  }
};

export const saveHorseDataToDatabase = async (
  selectedRaceRunners: Runner[],
  selectedCourse: string,
  horseStats: { [key: string]: any },
  selectedRace: RaceCard
) => {
  try {
    const horseData: HorseData[] = await Promise.all(
      selectedRaceRunners.map(async (runner) => {
        const totalPoints = calculateTotalPoints(runner,[],horseStats,selectedRaceRunners);
        if (horseStats[runner.horseId]) {horseStats[runner.horseId].totalPoints = totalPoints;}
        console.log(totalPoints,"Total points")
        return await generateHorseJson(selectedCourse, selectedRace, horseStats, runner);

      })
    );
  //  console.log("Horse data before cleaning ", horseData)
    let cleanedData = cleanHorseData(horseData);
  //  console.log("Horse data after cleaning ", cleanedData)
    cleanedData = cleanHorseStat(cleanedData);
    //console.log("Clenead adta ------------------",cleanedData)
    const response = await RacingAPIService.saveHorseData(cleanedData);
    console.log("Save Response:", response?.data);
  } catch (error: any) {
    console.error("Failed to save horse data", error.response?.data || error.message);
  }
};

const cleanHorseStat = (cleanedData: any[]) => {
  for (let i = 0; i < cleanedData.length; i++) {
    for (let j = 0; j < cleanedData[i].race.runners.length; j++) {
      let runner = cleanedData[i].race.runners[j];
      let hrsId = runner.horseId;
           if (runner.horseStats && runner.horseStats[hrsId]) {
        runner.horseStats = runner.horseStats[hrsId]; 
      } else {
        runner.horseStats = {}; 
      }
    }
  }
  return cleanedData;
};
