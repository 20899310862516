import { Card, CardContent, Grid, Input, Typography } from "@mui/material";
import { venuesFromPostgresAdapter } from "Helpers/Venues.adapter";
import { UserService } from "Services/UserService";
import DynamicButton from "components/dynamic-button/DynamicButton";
import SelectDropdown from "components/select/Select";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { today } from "../../Helpers/Constants";

function EditVenueCard() {
  const [dateValue, setDateValue] = useState(today);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [venues, setVenues] = useState<any>([]);
  const [venueNames, setVenueNames] = useState<any>([]);
  const [venue, setVenue] = useState<any>(null);
  const navigate = useNavigate();

  const [isEditDisabled, setIsEditDisabled] = useState(true);

  const fetchVenues = useCallback(async () => {
    let venues = await UserService.getVenuesByDate(dateValue);
    if (venues?.data.statusCode === 201) {
      setVenues(venues.data.data);
      return setVenueNames(
        venues.data.data.map((venue: any) => {
          return venue.name?.charAt(0)?.toUpperCase() + venue.name?.slice(1);
        }),
      );
    }
  }, [dateValue]);

  const fetchVenuesFromPostgres = useCallback(async () => {
    let venues = await UserService.getVenuesByPostGrass(dateValue, dateValue);
    if (venues?.status === 200) {
      const races = venues.races?.$values || [];
      const uniqueVenueNames = races.filter(
        (race: any, index: any, self: any) =>
          index ===
          self.findIndex(
            (r: any) =>
              r.venueName?.toLowerCase() === race.venueName?.toLowerCase(),
          ),
      );
      const uniqueVenueNamesWithRaces: any[] = [];
      uniqueVenueNames.forEach((venue: any) => {
        uniqueVenueNamesWithRaces.push({
          ...venue,
          races: races.filter(
            (race: any) =>
              race.venueName?.toLowerCase() === venue.venueName?.toLowerCase(),
          ),
        });
      });
      const adaptedVenues = venuesFromPostgresAdapter(
        uniqueVenueNamesWithRaces,
      );
      setVenues(adaptedVenues);

      return setVenueNames(
        adaptedVenues.map((venue: any) => {
          return venue.name?.charAt(0)?.toUpperCase() + venue.name?.slice(1);
        }),
      );
    } else {
      console.error("Failed to fetch venues from Postgres", venues);
      setVenues([]);
      setVenueNames([]);
    }
  }, [dateValue]);

  const goToVenue = () => {
    if (!venue?._id) return;
    return navigate(`/controller/venues/edit/${venue._id}/${dateValue}`, {
      state: venue,
    });
  };

  useEffect(() => {
    setIsEditDisabled(!dateValue || !selectedVenue);
  }, [dateValue, selectedVenue]);

  useEffect(() => {
    if (selectedVenue) {
      let selectedVenueLower = selectedVenue?.toLowerCase();
      let foundVenue = venues.find(
        (venue: any) => venue.name?.toLowerCase() === selectedVenueLower,
      );
      if (foundVenue) {
        setVenue(foundVenue);
      } else {
      }
    }
  }, [selectedVenue, venues]);

  useEffect(() => {
    if (dateValue < today) {
      fetchVenuesFromPostgres();
    } else {
      fetchVenues();
    }
  }, [fetchVenues, fetchVenuesFromPostgres, dateValue]);

  return (
    <Card sx={{ border: "1px solid darkcyan", width: "100%" }}>
      <CardContent>
        <Typography sx={{ color: "darkcyan", width: "100%" }} variant="h6">
          Edit Venue
        </Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} lg={12}>
            <Input
              id="date"
              type="date"
              datatype="date"
              fullWidth
              sx={{
                "&.MuiInput-underline:after": {
                  borderBottom: "2px solid darkcyan",
                },
                mt: 2,
              }}
              value={dateValue}
              onChange={(e: any) => {
                setDateValue(e.target.value);
                setSelectedVenue("");
              }}
            />
          </Grid>
          <Grid item xs={12} lg={12}>
            <SelectDropdown
              label="Select Venue"
              items={venueNames.sort()}
              value={selectedVenue}
              onChange={(e) => setSelectedVenue(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <DynamicButton
              onClick={goToVenue}
              backgroundColor="darkcyan"
              color="white"
              label="View"
              disabled={isEditDisabled}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default EditVenueCard;
