import { IDBPDatabase, openDB } from "idb";
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

type DataStore = { [key: string]: any };

interface GeneralContextType {
  setData: (key: string, value: any) => void;
  getData: <T>(key: string) => Promise<T | undefined>;
  dataStore: DataStore;
}

const GeneralContext = createContext<GeneralContextType | undefined>(undefined);

export const useGeneralContext = () => {
  const context = useContext(GeneralContext);
  if (!context) {
    throw new Error("useGeneralContext must be used within a GeneralProvider");
  }
  return context;
};

interface GeneralProviderProps {
  children: ReactNode;
}

let dbPromise: Promise<IDBPDatabase> | null = null;

const getDB = () => {
  if (!dbPromise) {
    dbPromise = openDB("AppDB", 2, {
      upgrade(db) {
        if (!db.objectStoreNames.contains("dataStore")) {
          db.createObjectStore("dataStore");
        }
      },
    });
  }
  return dbPromise;
};

const clearIndexedDBCache = async () => {
  const db = await getDB();
  const tx = db.transaction("dataStore", "readwrite");
  const store = tx.objectStore("dataStore");
  await store.clear();
  await tx.done;
  console.log("IndexedDB cache cleared.");
};

export const GeneralProvider: React.FC<GeneralProviderProps> = ({
  children,
}) => {
  const [dataStore, setDataStore] = useState<DataStore>({});

  useEffect(() => {
    const initDB = async () => {
      const db = await getDB();
      const allKeys = await db.getAllKeys("dataStore");
      const storedData: DataStore = {};
      for (const key of allKeys) {
        if (typeof key === "string") {
          storedData[key] = await db.get("dataStore", key);
        }
      }
      setDataStore(storedData);
    };

    const handleInitialization = async () => {
      const isFirstLoad = !sessionStorage.getItem("isSessionActive");
      if (isFirstLoad) {
        console.log("First load: fetching fresh data.");
        sessionStorage.setItem("isSessionActive", "true");
        // Simulate fetching fresh data or perform actual API fetch here
        await initDB();
      } else {
        console.log("Reload detected: using cached data.");
        await initDB();
      }
    };

    handleInitialization();

    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      // Check if the tab is being closed and not refreshed
      if (!document.hidden) {
        clearIndexedDBCache(); // Clear IndexedDB when the tab is closed
        sessionStorage.removeItem("isSessionActive"); // Remove session flag
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const setData = useCallback(async (key: string, value: any) => {
    const db = await getDB();
    await db.put("dataStore", value, key);
    setDataStore((prevData) => ({ ...prevData, [key]: value }));
    window.dispatchEvent(new Event("dataStoreUpdated"));
  }, []);

  const getData = useCallback(
    async <T,>(key: string): Promise<T | undefined> => {
      if (dataStore[key] !== undefined) {
        return dataStore[key] as T;
      }
      const db = await getDB();
      return (await db.get("dataStore", key)) as T;
    },
    [dataStore],
  );

  const contextValue = useMemo(
    () => ({ setData, getData, dataStore }),
    [setData, getData, dataStore],
  );

  return (
    <GeneralContext.Provider value={contextValue}>
      {children}
    </GeneralContext.Provider>
  );
};
