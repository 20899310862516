import React from "react";

interface CourseDropdownProps {
  selectedCourse: string;
  handleCourseChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  distinctCourses: string[];
  loading: boolean;
}

const CourseDropdown: React.FC<CourseDropdownProps> = ({
  selectedCourse,
  handleCourseChange,
  distinctCourses,
  loading,
}) => {
  return (
    <select
      id="courseDropdown"
      value={selectedCourse}
      onChange={handleCourseChange}
      style={{ padding: "8px", boxSizing: "border-box" }}
      disabled={loading}
    >
      <option value="">{loading ? "Loading..." : "Select a venue"}</option>
      {!loading &&
        distinctCourses.map((course, index) => (
          <option key={index} value={course}>
            {course}
          </option>
        ))}
    </select>
  );
};

export default CourseDropdown;
