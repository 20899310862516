import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function InfoCard({
  title,
  link,
  info,
}: {
  title: string;
  link?: string;
  info?: any;
}) {
  return (
    <Card
      sx={{
        backgroundColor: "darkcyan",
        minWidth: "17rem",
        overflowY: "visible",
        maxHeight: "30rem",
      }}
    >
      <CardContent>
        <Box sx={{ color: "white" }}>
          <Typography component="h4" variant="h6">
            {title}
          </Typography>
          <Typography color="white" component="div">
            {info}
          </Typography>
        </Box>
        {link && (
          <div style={{ textAlign: "right" }}>
            <Link
              style={{
                color: "darkcyan",
                backgroundColor: "white",
                padding: 10,
                textDecoration: "none",
                borderRadius: 3,
              }}
              to={link}
            >
              View more
            </Link>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default InfoCard;
