import { Box, Modal, Typography } from "@mui/material";
import DynamicButton from "components/dynamic-button/DynamicButton";
import React from "react";

function ModalVeriffication({
  message,
  optionalMessage,
  open,
  handleModalClose,
  submitFunction,
  values,
}: {
  message: string;
  optionalMessage?: string;
  open: boolean;
  handleModalClose: (values?: any) => void;
  submitFunction: (values?: any) => void;
  values?: any;
}) {
  return (
    <Modal open={open} onClose={handleModalClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxWidth: "420px",
          height: "250px",
          padding: "32px",
          backgroundColor: "white",
          boxShadow: "0 0 32px rgba(0, 0, 0, 0.5)",
          width: "100%",
          borderRadius: "8px",
        }}
      >
        <Typography sx={{ fontSize: "18px" }}>{message}</Typography>
        {optionalMessage && (
          <Box
            sx={{
              display: "flex",
              justifyItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Typography sx={{ color: "red" }}>{optionalMessage}</Typography>
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <DynamicButton
            label="Yes"
            color="white"
            backgroundColor="red"
            onHoverBg="white"
            onHoverColor="red"
            width={"20%"}
            onClick={() => submitFunction(values)}
          />
          <DynamicButton
            label="No"
            width={"20%"}
            backgroundColor="darkcyan"
            color="white"
            onHoverBg="white"
            onHoverColor="darkcyan"
            onClick={handleModalClose}
          />
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalVeriffication;
