import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useRoles } from "useRoles";

function CollapsibleListItem({
  icon,
  title,
  items,
  requiredRoles,
}: {
  icon?: any;
  title: string;
  items: any;
  requiredRoles?: string[];
}) {
  const [open, setOpen] = useState(false);

  const { hasRole } = useRoles();
  const isAuthorized = !requiredRoles || hasRole(requiredRoles);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    isAuthorized && (
      <>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={title} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items.map(
              (item: any, index: number) =>
                (!item.requiredRoles || hasRole(item.requiredRoles)) && (
                  <ListItemButton
                    key={index}
                    sx={{ pl: 4 }}
                    component={Link}
                    to={item.link}
                  >
                    <ListItemText
                      primary={
                        <ul>
                          <li>{item.name}</li>
                        </ul>
                      }
                    />
                  </ListItemButton>
                ),
            )}
          </List>
        </Collapse>
      </>
    )
  );
}

export default CollapsibleListItem;
