import { Box } from "@mui/material";
import { RacingAPIService } from "Services/RacingAPIService";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

interface Runner {
  horseId: string;
  horse: string;
  age: string;
  trainer: string;
  jockey: string;
  form: string;
  jockeyId: string;
  lbs: string;
  region: string;
  colour: string;
  sex: string;
  sire: string;
  dam: string;
  damsire: string;
  owner: string;
  trainerLocation: string;
}

interface RaceCard {
  raceId: string;
  course: string;
  date: string;
  raceName: string;
  distanceF: number;
  going: string;
  region: string;
  prize: string;
  ageBand: string;
  raceClass: string;
  offTime: string;

  runners: { $values: Runner[] };
}

interface HorseHistoryItem {
  uuid: string;
  dist: string;
  distF: string;
  distM: string;
  distY: string;
  date: string;
  course: string;
  going: string;
  prize: string;
  runners: { $values: Runners[] };
}
interface Runners {
  horse: string;
  horseId: string;
  time: string;
  weightLbs: string;
  position: string;
  jockey: string;
  prize: string;
  headgear: string;
}

interface CourseItem {
  course: string;
  courseId: string;
  region: string;
  rides: number;
  first: number;
  second: number;
  third: number;
  fourth: number;
  ae: number;
  winPerc: number;
  pl: number;
}
interface JockeyHistoryItem {
  id: string;
  jockey: string;
  total_rides: number;
  courses: { $values: CourseItem[] };
}

const RacingApi: React.FC = () => {
  const [results, setResults] = useState<RaceCard[]>([]);
  const [expandedRaceId, setExpandedRaceId] = useState<string | null>(null);
  const [horseHistory, setHorseHistory] = useState<HorseHistoryItem[]>([]);
  const [times, setTimes] = useState<Runners[]>([]);
  const [expandedHorseId, setExpandedHorseId] = useState<string | null>(null);
  const [jockeyHistory, setJockeyHistory] = useState<JockeyHistoryItem>();
  const [expandedJockeyId, setExpandedJockeyId] = useState<string | null>(null);
  const [isToggledCourses, setIsToggledCourses] = useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);

  useEffect(() => {
    fetchTomorrowRaceCards();
  }, []);

  const getHorseResults = async (horseId: string) => {
    try {
      const response = await RacingAPIService.getHorseResults(horseId);
      console.log("HorseResults", response);

      let horseValuesFromAPI: HorseHistoryItem[] =
        response?.data?.results?.$values ?? [];
      let horseHistories: HorseHistoryItem[] = [];

      for (const horseHistory of horseValuesFromAPI) {
        const newHorseHistory = { ...horseHistory, uuid: uuidv4() };
        horseHistories.push(newHorseHistory);
      }

      setHorseHistory(horseHistories);
      setTimes([]);
    } catch (error) {
      console.error("Error fetching horse results", error);
    }
  };

  const getJockeyHistory = async (id: string) => {
    try {
      const response = await RacingAPIService.getJockeyHistory(id);
      console.log("JockeyButton", response);
      setJockeyHistory(response?.data);
    } catch (error) {
      console.error("Error fetching horse history:", error);
    }
  };

  const handleClick = (horseId: string) => {
    if (expandedJockeyId) {
      setExpandedJockeyId(null);
      setIsToggledCourses(false);
    }
    getHorseResults(horseId);
    setExpandedHorseId(expandedHorseId === horseId ? null : horseId);
  };

  const handleJockeyClick = (id: string) => {
    if (expandedHorseId) {
      setExpandedHorseId(null);
      setTimes([]);
    }
    getJockeyHistory(id);
    setExpandedJockeyId(expandedJockeyId === id ? null : id);
  };

  const handleClickTimes = (times: Runners[], uuid: string) => {
    if (selectedUuid === uuid) {
      setSelectedUuid(null);
      setTimes([]);
    } else {
      setSelectedUuid(uuid);
      setTimes(times);
    }
  };

  const fetchTomorrowRaceCards = async () => {
    try {
      const response = await RacingAPIService.getTomorrowRaceCards();
      setResults(response?.data.$values as RaceCard[]);
    } catch (error) {
      console.error("Error fetching results:", error);
    }
  };

  const toggleRunners = (raceId: string) => {
    setExpandedJockeyId(null);
    setIsToggledCourses(false);

    if (expandedRaceId === raceId) {
      setExpandedRaceId(null);
      setExpandedHorseId(null);
      return setTimes([]);
    }

    setExpandedRaceId(raceId);
    setExpandedHorseId(null);
    return setTimes([]);
  };
  const convertLbToKg = (lbs: any): string => {
    const kg = lbs * 0.453592;
    return kg.toFixed(2); // returning the value rounded to 2 decimal places
  };

  
  const sortedHorseHistory = [...horseHistory].sort((a, b) => {
    return (
      new Date(b.date as string).getTime() -
      new Date(a.date as string).getTime()
    );
  });

  const furlongsToMeters = (furlongs: number): number => {
    return furlongs * 201.168;
  };

  const runnersTable = (runners: Runners[]) => {
    return (
      <div>
        <h2 style={{ marginLeft: "32px" }}>Runners</h2>
        <table
          style={{
            backgroundColor: "#E0E0E0",
            border: "1px solid black",
            borderRadius: "4px",
            width: "100%",
            fontSize: "11px",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              <th style={{ padding: "4px", width: "12%" }}>Horse</th>
              <th
                style={{
                  borderLeft: "1px solid black",
                  padding: "4px",
                  width: "10%",
                }}
              >
                Horse Id
              </th>
              <th
                style={{
                  borderLeft: "1px solid black",
                  padding: "4px",
                  width: "10%",
                }}
              >
                Time
              </th>

              <th
                style={{
                  borderLeft: "1px solid black",
                  padding: "4px",
                  width: "8%",
                }}
              >
                Weight Lbs
              </th>
              <th
                style={{
                  borderLeft: "1px solid black",
                  padding: "4px",
                  width: "8%",
                }}
              >
                Position
              </th>
              <th
                style={{
                  borderLeft: "1px solid black",
                  padding: "4px",
                  width: "8%",
                }}
              >
                gear
              </th>
              <th
                style={{
                  borderLeft: "1px solid black",
                  padding: "4px",
                  width: "12%",
                }}
              >
                Jockey
              </th>
              <th
                style={{
                  borderLeft: "1px solid black",
                  padding: "4px",
                  width: "12%",
                }}
              >
                Prize
              </th>
            </tr>
          </thead>
          <tbody style={{ border: "1px solid black" }}>
            {runners.map((data, index) => (
              <tr key={index}>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    borderTop: "1px solid black",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.horse}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    borderTop: "1px solid black",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.horseId}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    borderTop: "1px solid black",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.time}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    borderTop: "1px solid black",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.weightLbs}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    borderTop: "1px solid black",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.position}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    borderTop: "1px solid black",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.headgear}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    borderTop: "1px solid black",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.jockey}
                </td>
                <td
                  style={{
                    borderLeft: "1px solid black",
                    borderTop: "1px solid black",
                    padding: "4px",
                    textAlign: "center",
                  }}
                >
                  {data.prize}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <h1>Racing API</h1>
      </div>

      {results.length > 0 ? (
        <table
          style={{
            backgroundColor: "#E5E5E5",
            border: "1px solid black",
            borderCollapse: "collapse",
            width: "100%",
            tableLayout: "fixed",
            display: "block",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  width: "6%",
                }}
              >
                Race ID
              </th>
              <th
                style={{
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  width: "6%",
                }}
              >
                TRACKNAME
              </th>
              <th
                style={{
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  width: "7%",
                }}
              >
                MEETDATE
              </th>
              <th
                style={{
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  width: "15%",
                }}
              >
                EVENTNAME{" "}
              </th>
              <th
                style={{
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  width: "5%",
                }}
              >
                DISTANCE
              </th>
              <th
                style={{
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  width: "8%",
                }}
              >
                Going
              </th>
              <th
                style={{
                  borderBottom: "1px solid black",
                  borderRight: "1px solid black",
                  width: "5%",
                }}
              >
                Region
              </th>
              <th style={{ borderBottom: "1px solid black", width: "10%" }}>
                Runners
              </th>
            </tr>
          </thead>
          <tbody>
            {results.map((racecard) => (
              <React.Fragment key={racecard.raceId}>
                <tr style={{ borderTop: "1px solid black" }}>
                  <td
                    style={{
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {racecard.raceId}
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {racecard.course}
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {new Date(racecard.date).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      textAlign: "center",
                      fontSize: "10px",
                      fontWeight: "bold",
                    }}
                  >
                    {racecard.raceName}
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {furlongsToMeters(racecard.distanceF).toFixed(0)}
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {racecard.going}
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid black",
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {racecard.region}
                  </td>
                  <td
                    style={{
                      borderBottom: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    <button
                      style={{ cursor: "pointer" }}
                      onClick={() => toggleRunners(racecard.raceId)}
                    >
                      Show Runners
                    </button>
                  </td>
                </tr>
                {expandedRaceId === racecard.raceId && (
                  <>
                    <tr>
                      <td colSpan={8} style={{ padding: 0 }}>
                        <div style={{ textAlign: "center" }}>
                          <table
                            style={{
                              backgroundColor: "#CDCDCD",
                              borderCollapse: "collapse",
                              width: "90%",
                              tableLayout: "fixed",
                              marginBottom: "20px",
                              marginTop: "20px",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            <thead>
                              <tr
                                style={{
                                  borderTop: "1px solid black",
                                  borderBottom: "1px solid black",
                                }}
                              >
                                <th
                                  style={{
                                    borderLeft: "1px solid black",
                                    borderRight: "1px solid black",
                                    width: "25%",
                                  }}
                                >
                                  HORSENAME
                                </th>
                                <th
                                  style={{
                                    borderRight: "1px solid black",
                                    width: "20%",
                                  }}
                                >
                                  Horse Id
                                </th>
                                <th
                                  style={{
                                    borderRight: "1px solid black",
                                    width: "10%",
                                  }}
                                >
                                  AGE
                                </th>
                                <th
                                  style={{
                                    borderRight: "1px solid black",
                                    width: "20%",
                                  }}
                                >
                                  TRAINER
                                </th>
                                <th
                                  style={{
                                    borderRight: "1px solid black",
                                    width: "20%",
                                  }}
                                >
                                  JOCKEY
                                </th>
                                <th
                                  style={{
                                    borderRight: "1px solid black",
                                    width: "16.6%",
                                  }}
                                >
                                  JockeyId
                                </th>
                                <th
                                  style={{
                                    borderRight: "1px solid black",
                                    width: "15%",
                                  }}
                                >
                                  Weight
                                </th>
                                <th
                                  style={{
                                    borderRight: "1px solid black",
                                    width: "16.6%",
                                  }}
                                >
                                  Form
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {racecard.runners.$values.map((runner) => (
                                <React.Fragment key={runner.horseId}>
                                  <tr
                                    style={{
                                      borderTop: "1px solid black",
                                      borderBottom: "1px solid black",
                                    }}
                                  >
                                    <td
                                      style={{
                                        borderRight: "1px solid black",
                                        borderLeft: "1px solid black",
                                        textAlign: "center",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {runner.horse}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      <button
                                        style={{
                                          backgroundColor: "white",
                                          border: "1px solid black",
                                          borderRadius: "3px",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() =>
                                          handleClick(runner.horseId)
                                        }
                                      >
                                        {runner.horseId}
                                      </button>
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px solid black",
                                        textAlign: "center",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {runner.age}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px solid black",
                                        textAlign: "center",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {runner.trainer}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px solid black",
                                        textAlign: "center",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {runner.jockey}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px solid black",
                                        textAlign: "center",
                                      }}
                                    >
                                      <button
                                        style={{
                                          backgroundColor: "white",
                                          border: "1px solid black",
                                          borderRadius: "3px",
                                          cursor: "pointer",
                                          fontSize: "12px",
                                        }}
                                        onClick={() =>
                                          handleJockeyClick(runner.jockeyId)
                                        }
                                      >
                                        {runner.jockeyId}
                                      </button>
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px solid black",
                                        textAlign: "center",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {convertLbToKg(runner.lbs)}kg{" "}
                                    </td>
                                    <td
                                      style={{
                                        borderRight: "1px solid black",
                                        textAlign: "center",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {runner.form}
                                    </td>
                                  </tr>
                                  {expandedHorseId === runner.horseId && (
                                    <tr>
                                      <td colSpan={8} style={{ padding: 0 }}>
                                        <div
                                          style={{
                                            textAlign: "center",
                                            padding: "10px",
                                          }}
                                        >
                                          <table
                                            style={{
                                              backgroundColor: "#DCDCDC",
                                              borderCollapse: "collapse",
                                              width: "100%",
                                              tableLayout: "fixed",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  DISTANCE
                                                </th>

                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  MEETDATE
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  TRACKNAME
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  GOING
                                                </th>

                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  Runners
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {sortedHorseHistory.map(
                                                (history, index) => (
                                                  <>
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {history.distM}
                                                      </td>

                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {new Date(
                                                          history.date,
                                                        ).toLocaleDateString(
                                                          "en-GB",
                                                          {
                                                            day: "2-digit",
                                                            month: "2-digit",
                                                            year: "numeric",
                                                          },
                                                        )}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {history.course}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {history.going}
                                                      </td>

                                                      <td
                                                        style={{
                                                          borderRight:
                                                            "1px solid black",
                                                          borderTop:
                                                            "1px solid black",
                                                          padding: "4px",
                                                          textAlign: "center",
                                                          borderBottom:
                                                            "1px solid black",
                                                        }}
                                                      >
                                                        <button
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                          onClick={() =>
                                                            handleClickTimes(
                                                              history.runners
                                                                .$values,
                                                              history.uuid,
                                                            )
                                                          }
                                                        >
                                                          View Runners
                                                        </button>
                                                      </td>
                                                    </tr>
                                                    <td></td>
                                                    <td></td>

                                                    <td>
                                                      {" "}
                                                      <Box
                                                        style={{
                                                          width: "100%",
                                                          display: "flex",
                                                          justifyContent:
                                                            "center",
                                                        }}
                                                      >
                                                        {" "}
                                                        {times.length > 0 &&
                                                          selectedUuid ===
                                                            history.uuid &&
                                                          runnersTable(times)}
                                                      </Box>
                                                    </td>
                                                  </>
                                                ),
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                  {expandedJockeyId === runner.jockeyId && (
                                    <tr>
                                      <td colSpan={8} style={{ padding: 0 }}>
                                        <div
                                          style={{
                                            textAlign: "center",
                                            padding: "10px",
                                          }}
                                        >
                                          <table
                                            style={{
                                              backgroundColor: "#DCDCDC",
                                              borderCollapse: "collapse",
                                              width: "100%",
                                              tableLayout: "fixed",
                                              marginTop: "10px",
                                            }}
                                          >
                                            <thead>
                                              <tr>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  ID
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  Jockey
                                                </th>
                                                <th
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                  }}
                                                >
                                                  Total Rides
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr key={1}>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {jockeyHistory?.id}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {jockeyHistory?.jockey}
                                                </td>
                                                <td
                                                  style={{
                                                    border: "1px solid black",
                                                    padding: "8px",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  <button
                                                    onClick={() =>
                                                      setIsToggledCourses(
                                                        !isToggledCourses,
                                                      )
                                                    }
                                                  >
                                                    {jockeyHistory?.total_rides}
                                                  </button>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>

                                          {isToggledCourses ? (
                                            <table
                                              style={{
                                                backgroundColor: "#DCDCDC",
                                                borderCollapse: "collapse",
                                                width: "100%",
                                                // tableLayout: "fixed",
                                                marginTop: "10px",
                                              }}
                                            >
                                              <thead>
                                                <tr>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    AE
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Course
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Course Id
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    First
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Second
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Third
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Fourth
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    PL
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Region
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Rides
                                                  </th>
                                                  <th
                                                    style={{
                                                      border: "1px solid black",
                                                      padding: "8px",
                                                    }}
                                                  >
                                                    Win Perc
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {jockeyHistory?.courses?.$values?.map(
                                                  (jockey, index) => (
                                                    <tr key={index}>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {jockey?.ae}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {jockey?.course}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {jockey?.courseId}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {jockey?.first}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {jockey?.second}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {jockey?.third}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {jockey?.fourth}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {jockey?.pl}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {jockey?.region}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {jockey?.rides}
                                                      </td>
                                                      <td
                                                        style={{
                                                          border:
                                                            "1px solid black",
                                                          padding: "8px",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        {jockey?.winPerc}
                                                      </td>
                                                    </tr>
                                                  ),
                                                )}
                                              </tbody>
                                            </table>
                                          ) : null}
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default RacingApi;
