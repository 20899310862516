import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import isAuthenticated from "../../utils/isAuthenticated";

function ProtectedRouteAuthenticated({ children }: { children: any }) {
  const location = useLocation();
  if (isAuthenticated()) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}

export default ProtectedRouteAuthenticated;
