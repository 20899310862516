import {
  Card,
  CardContent,
  FormControl,
  Grid,
  Input,
  InputLabel,
  Typography,
} from "@mui/material";
import { UserService } from "Services/UserService";
import { ShortErrorToast, ShortSuccessToast } from "components/Toast/Toastify";
import DynamicButton from "components/dynamic-button/DynamicButton";
import React, { useState } from "react";

function AddVenueCard({ fetchVenuesInfo }: { fetchVenuesInfo: any }) {
  const [formValues, setFormValues] = useState({
    venueName: "",
    venueCode: "",
    venueCountry: "",
    venueState: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const [errors, setErrors] = useState({
    venueName: "",
    venueCode: "",
    venueCountry: "",
    venueState: "",
  });

  const validate = () => {
    let tempErrors: any = {};
    tempErrors.venueName =
      formValues.venueName.length < 3
        ? "This field should have 3 Characters or more"
        : formValues.venueName
          ? ""
          : "This field is required";
    tempErrors.venueCode =
      formValues.venueCode.length !== 6
        ? "This field should be exactly 6 Characters long"
        : formValues.venueCode
          ? ""
          : "This field is required";
    tempErrors.venueCountry =
      formValues.venueCountry.length < 3
        ? "This field should have 3 Characters or more"
        : formValues.venueCountry
          ? ""
          : "This field is required";
    tempErrors.venueState =
      formValues.venueState.length < 2
        ? "This field should have 2 Characters or more"
        : formValues.venueState
          ? ""
          : "This field is required";
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async () => {
    if (validate()) {
      try {
        const res = await UserService.addVenue(formValues);
        if (res?.data.statusCode === 201) {
          fetchVenuesInfo();
          return ShortSuccessToast({ message: res?.data.message });
        }
        return ShortErrorToast({ message: "Failed to add venue" });
      } catch (error) {
        ShortErrorToast({ message: "Failed to add venue" });
      } finally {
        setFormValues({
          venueName: "",
          venueCode: "",
          venueCountry: "",
          venueState: "",
        });
      }
    }
  };
  return (
    <Card sx={{ border: "1px solid darkcyan", width: "100%" }}>
      <CardContent>
        <Typography sx={{ color: "darkcyan", width: "100%" }} variant="h6">
          Add Venue
        </Typography>
        <Grid container spacing={2} mt={2}>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel
                htmlFor="venue-name"
                sx={{
                  color: "text.primary",
                  "&.Mui-focused": {
                    color: "darkcyan",
                  },
                }}
              >
                Venue Name
              </InputLabel>
              <Input
                value={formValues.venueName}
                name="venueName"
                id="venue-name"
                type="text"
                fullWidth
                sx={{
                  "&.MuiInput-underline:after": {
                    borderBottom: "2px solid darkcyan",
                  },
                }}
                onChange={handleChange}
              />
              {errors.venueName && (
                <Typography color="error" variant="caption">
                  {errors.venueName}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel
                htmlFor="venue-code"
                sx={{
                  color: "text.primary",
                  "&.Mui-focused": {
                    color: "darkcyan",
                  },
                }}
              >
                Venue Code
              </InputLabel>
              <Input
                value={formValues.venueCode}
                id="venue-code"
                name="venueCode"
                onChange={handleChange}
                type="text"
                fullWidth
                sx={{
                  "&.MuiInput-underline:after": {
                    borderBottom: "2px solid darkcyan",
                  },
                }}
              />
              {errors.venueCode && (
                <Typography color="error" variant="caption">
                  {errors.venueCode}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel
                htmlFor="venue-country"
                sx={{
                  color: "text.primary",
                  "&.Mui-focused": {
                    color: "darkcyan",
                  },
                }}
              >
                Venue Country
              </InputLabel>
              <Input
                value={formValues.venueCountry}
                id="venue-country"
                name="venueCountry"
                onChange={handleChange}
                type="text"
                fullWidth
                sx={{
                  "&.MuiInput-underline:after": {
                    borderBottom: "2px solid darkcyan",
                  },
                }}
              />
              {errors.venueCountry && (
                <Typography color="error" variant="caption">
                  {errors.venueCountry}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel
                htmlFor="venue-state"
                sx={{
                  color: "text.primary",
                  "&.Mui-focused": {
                    color: "darkcyan",
                  },
                }}
              >
                Venue State
              </InputLabel>
              <Input
                value={formValues.venueState}
                id="venue-state"
                name="venueState"
                onChange={handleChange}
                type="text"
                fullWidth
                sx={{
                  "&.MuiInput-underline:after": {
                    borderBottom: "2px solid darkcyan",
                  },
                }}
              />
              {errors.venueState && (
                <Typography color="error" variant="caption">
                  {errors.venueState}
                </Typography>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} lg={3} mt={1}>
            <DynamicButton
              backgroundColor="darkcyan"
              color="white"
              label="Submit"
              onClick={handleSubmit}
              disabled={
                !formValues.venueName ||
                !formValues.venueCode ||
                !formValues.venueCountry ||
                !formValues.venueState
              }
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AddVenueCard;
