import {
  Box,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import React from "react";
import { Utilities } from "utils/Utilities";
import {
  HorseHistoryItem,
  RaceCard,
  Runner,
  horseStatsType,
  trackConditionAbbreviations,
} from "./FormingTypes";
import {
  calculateHistoryTotalPrize,
  convertTimeToSeconds,
  formatDate,
  formatHorseWeight,
  formatWeight,
  getOrdinal,
  getRaceTimesForPositionOne,
  getTomorrowDate,
  showPrizeCurrencySymbol,
} from "./FormingUtils";

interface HorseDetailsProps {
  runner: Runner;
  selectedCourse: string;
  selectedRaceId: string;
  results: RaceCard[];
  horseStats: horseStatsType;
  horseHistories: { [horseId: string]: HorseHistoryItem[] };
}

const HorseDetails: React.FC<HorseDetailsProps> = ({
  runner,
  selectedCourse,
  selectedRaceId,
  results,
  horseStats,
  horseHistories,
}) => {
  const isLoading =
    !horseStats[runner.horseId] || !results || !horseHistories[runner.horseId];
  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress style={{ marginTop: "4px" }} size={20} />
      </Box>
    );
  }
  return (
    <>
      <TableRow
        style={{ padding: 0 }}
        key={`${runner.horseId}-${Math.floor(Math.random() * 1000)}`}
      >
        <TableCell>{getTomorrowDate()}</TableCell>
        <TableCell>{selectedCourse}</TableCell>
        <TableCell>
          {results
            .filter((race: RaceCard) => race.raceId === selectedRaceId)
            .map((race: RaceCard) => (
              <div key={race.raceId}>
                {" "}
                {Math.floor(Utilities.convertFurlongsToMeters(race.distanceF))}m
              </div>
            ))}
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          {`R.${
            (results
              .find((race: RaceCard) => race.raceId === selectedRaceId)
              ?.runners.$values.findIndex(
                (horse) => horse.horseId === runner.horseId,
              ) ?? -1) + 1 || ""
          }`}
        </TableCell>
        <TableCell>
          {" "}
          {results
            .filter((race: RaceCard) => race.raceId === selectedRaceId)
            .map((race: RaceCard) => (
              <div key={race.raceId}>{race.offTime}</div>
            ))}
        </TableCell>
        <TableCell
          style={{
            maxWidth: "200px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {results
            .filter((race: RaceCard) => race.raceId === selectedRaceId)
            .map((race: RaceCard) => (
              <Tooltip key={race.raceId} title={race.raceName}>
                <span style={{ cursor: "pointer" }}>{race.raceName}</span>
              </Tooltip>
            ))}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}>Form:</span>{" "}
          {`${runner.form.replace(/[-/]/g, "X")}`}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}>Age: </span> {`${runner.age}`}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}>Win%</span>
          {`${(horseStats[runner.horseId]?.winPerc * 100).toFixed(2)}`}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}>Place%:</span>
          {`${(horseStats[runner.horseId]?.winPlcPerc * 100).toFixed(2)}`}{" "}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}>Career: </span>
          {`${horseStats[runner.horseId]?.careerStat}`}
        </TableCell>

        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}>CareerPrz: </span>
          {`${horseStats[runner.horseId]?.totalPrize?.toFixed(0)}`}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}>Color: </span>
          {`${runner.colour}`}
        </TableCell>
        <TableCell>
          <span style={{ fontWeight: "bold", whiteSpace: "nowrap" }}>
            Sex:{" "}
          </span>
          {`${runner.sex}`}
        </TableCell>
      </TableRow>
      <TableRow key={`${runner.horseId}-${Math.floor(Math.random() * 1000)}`}>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <b>{`${runner.number}-${runner.horse}`}</b>
        </TableCell>
        <TableCell>
          <span style={{ fontWeight: "bold" }}>Barr.</span>
          {`${runner.draw}`}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}>J:</span> {`${runner.jockey}`}
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}>T:</span> {`${runner.trainer}`}
        </TableCell>
        <TableCell>
          {formatHorseWeight(horseStats[runner.horseId]?.horseWeight)}{" "}
          {horseStats[runner.horseId]?.weightDiff}
        </TableCell>

        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}> Prize Money: </span>
          {`${results
            .filter((race: RaceCard) => race.raceId === selectedRaceId)
            .map((race: RaceCard) => race.prize)
            .join(", ")}`}{" "}
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow key={`${runner.horseId}-${Math.floor(Math.random() * 1000)}`}>
        <TableCell
          colSpan={6}
          style={{
            backgroundColor: "#00b3b3",
            color: "white",
            whiteSpace: "nowrap",
          }}
        >
          <div style={{ display: "flex", gap: "16px", flexDirection: "row" }}>
            {horseStats[runner.horseId]?.trackConditionStats
              ? Object.entries(
                  horseStats[runner.horseId].trackConditionStats,
                ).map(([condition, stat]) => (
                  <div key={condition}>
                    <b>{trackConditionAbbreviations[condition] || condition}</b>
                    :{stat}
                  </div>
                ))
              : "Loading..."}
          </div>
        </TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}>Sire: </span>
          {`${runner.sire}`}
        </TableCell>
      </TableRow>

      <TableRow key={`${runner.horseId}-${Math.floor(Math.random() * 1000)}`}>
        <>
          <TableCell>
            <span style={{ fontWeight: "bold" }}>Track.</span>
            {`${horseStats[runner.horseId].trackStat}`}
          </TableCell>
          <TableCell>
            <span style={{ fontWeight: "bold" }}>Dist.</span>
            {`${horseStats[runner.horseId].distanceStat}`}
          </TableCell>
          <TableCell>
            <span style={{ fontWeight: "bold" }}>T/D.</span>
            {`${horseStats[runner.horseId].trackAndDistanceStat}`}
          </TableCell>
          <TableCell style={{ whiteSpace: "nowrap" }}>
            <span style={{ fontWeight: "bold" }}>1st Up: </span>
            {`${horseStats[runner.horseId].gapCountMessage}`}
          </TableCell>
          <TableCell style={{ whiteSpace: "nowrap" }}>
            <span style={{ fontWeight: "bold" }}>2nd Up: </span>
            {`${horseStats[runner.horseId].secondUpCount}`}
          </TableCell>

          <TableCell style={{ whiteSpace: "nowrap" }}>
            <span style={{ fontWeight: "bold" }}>3rd Up: </span>
            {`${horseStats[runner.horseId].thirdUpCount}`}
          </TableCell>

          <TableCell style={{ whiteSpace: "nowrap" }}>
            <span style={{ fontWeight: "bold" }}>Dam:</span>
            {`${runner.dam}`}
          </TableCell>
        </>
      </TableRow>

      <TableRow key={`${runner.horseId}-${Math.floor(Math.random() * 1000)}`}>
        <TableCell colSpan={2} style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}>Last 6 pos:</span>
          {`${horseStats[runner.horseId]?.lastSixPositions}`}
        </TableCell>
        <TableCell colSpan={4}></TableCell>
        <TableCell style={{ whiteSpace: "nowrap" }}>
          <span style={{ fontWeight: "bold" }}> Sire Of Dam:</span>
          {`${runner.damsire}`}
        </TableCell>
        <TableCell></TableCell>
      </TableRow>

      {horseHistories[runner.horseId] &&
        horseHistories[runner.horseId].length > 0 && (
          <TableRow>
            <TableCell colSpan={12}>
              <Table style={{ backgroundColor: "#00827F" }}>
                <TableBody>
                  {horseHistories[runner.horseId]
                    .sort(
                      (a, b) =>
                        new Date(b.date).getTime() - new Date(a.date).getTime(),
                    )
                    .map((historyItem, index) => {
                      return (
                        <React.Fragment key={index}>
                          {historyItem.runners.$values
                            .filter(
                              (runnerItem) =>
                                runnerItem.horseId === runner.horseId,
                            )
                            .map((runnerItem) => (
                              <>
                                <TableRow key={runnerItem.horseId}>
                                  <TableCell
                                    style={{
                                      color: "white",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {" "}
                                    {formatDate(historyItem.date)}{" "}
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    {historyItem.course}
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    {getOrdinal(runnerItem.position)}/
                                    {historyItem.runners.$values.length}
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    {historyItem.going}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      maxWidth: "200px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <Tooltip
                                      key={historyItem.raceId}
                                      title={historyItem.raceName}
                                    >
                                      <span
                                        style={{
                                          cursor: "pointer",
                                          color: "white",
                                        }}
                                      >
                                        {historyItem.raceName}
                                      </span>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    {formatWeight(
                                      String(
                                        parseFloat(runnerItem.weightLbs) *
                                          0.453592,
                                      ),
                                    )}
                                    kg
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    <span style={{ fontWeight: "bold" }}>
                                      Barr:
                                    </span>
                                    {runnerItem.draw}
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    {runnerItem.prize !== null &&
                                    runnerItem.prize !== undefined &&
                                    runnerItem.prize > 0
                                      ? `${runnerItem.prize}£`
                                      : runnerItem.prize === 0
                                        ? "0£"
                                        : `${runnerItem.prize}`}
                                  </TableCell>

                                  <TableCell style={{ color: "white" }}>
                                    {getRaceTimesForPositionOne(
                                      horseHistories[runner.horseId],
                                    )[index] || "N/A"}
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      color: "white",
                                      padding: 0,
                                      paddingRight: "8px",
                                    }}
                                  >
                                    <Box
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: "bolder",
                                        }}
                                      >
                                        PTP
                                      </span>
                                      {convertTimeToSeconds(
                                        String(runnerItem.time),
                                      ).toFixed(2)}
                                      sec
                                    </Box>
                                  </TableCell>
                                </TableRow>

                                <TableRow>
                                  <TableCell style={{ color: "white" }}>
                                    {`${Math.floor(Utilities.convertFurlongsToMeters(parseFloat(historyItem.distF)))}(m)`}
                                  </TableCell>
                                  <TableCell style={{ color: "white" }}>
                                    {historyItem.class}
                                  </TableCell>

                                  <TableCell style={{ color: "white" }}>
                                    {showPrizeCurrencySymbol(
                                      historyItem.region,
                                    )}
                                    {calculateHistoryTotalPrize(
                                      historyItem.runners.$values,
                                    )}
                                  </TableCell>

                                  {historyItem.runners.$values.map(
                                    (runners) => {
                                      if (runnerItem.position === "1") {
                                        if (runners.position === "2")
                                          return (
                                            <>
                                              <TableCell
                                                style={{
                                                  color: "white",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >{`2-${runners.horse}`}</TableCell>
                                              <TableCell
                                                style={{
                                                  color: "white",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {runners.time}
                                              </TableCell>
                                            </>
                                          );
                                      } else {
                                        if (runners.position === "1") {
                                          return (
                                            <TableCell
                                              style={{
                                                color: "white",
                                                whiteSpace: "nowrap",
                                              }}
                                            >{`1-${runners.horse}`}</TableCell>
                                          );
                                        }
                                      }
                                      return "";
                                    },
                                  )}
                                  <TableCell
                                    style={{ color: "white" }}
                                  >{`J-${runnerItem.jockey}`}</TableCell>
                                  <TableCell colSpan={6}></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    style={{ color: "white" }}
                                    colSpan={24}
                                  >
                                    {runnerItem.comment}
                                  </TableCell>
                                </TableRow>
                              </>
                            ))}

                          <TableRow
                            key={`${runner.horseId}-divider`}
                            style={{ padding: 0 }}
                          >
                            <TableCell colSpan={24} style={{ padding: 0 }}>
                              <Divider
                                sx={{
                                  backgroundColor: "whitesmoke",
                                  height: 8,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </TableCell>
          </TableRow>
        )}

      <TableRow key={`${runner.horseId}-divider`} style={{ padding: 0 }}>
        <TableCell colSpan={12} sx={{ padding: 0 }}>
          <Divider sx={{ backgroundColor: "darkcyan", height: 16 }} />
        </TableCell>
      </TableRow>
    </>
  );
};

export default HorseDetails;
