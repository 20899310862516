import { Box } from "@mui/material";
import React from "react";

export const VenueViewColumns = [
  {
    field: "name",
    headerName: "Name",
    width: 150,
    editable: true,
    renderCell: (row: any) => {
      return (
        <Box>
          {row.row.name.slice(0, 1).toUpperCase() + row.row.name.slice(1)}
        </Box>
      );
    },
  },
  {
    field: "trackCode",
    headerName: "Short Name",
    width: 150,
    editable: true,
    renderCell: (row: any) => {
      return <Box>{row.row.trackCode?.toUpperCase()}</Box>;
    },
  },
  {
    field: "venueState",
    headerName: "State",
    width: 150,
    editable: true,
    renderCell: (row: any) => {
      return <Box>{row.row.venueState?.toUpperCase()}</Box>;
    },
  },
  {
    field: "venueKeyUniBet",
    headerName: "Unibet Key",
    editable: true,
    width: 300,
  },
];
