import { Box, CircularProgress } from '@mui/material';
import { RacingAPIService } from "Services/RacingAPIService";
import React, { useEffect, useState } from "react";

import { Utilities } from "utils/Utilities";
import {
  HorseHistoryItem,
  RaceCard,
  Runner,
  trackConditionAbbreviations,
} from "../Forming/FormingTypes";
import {
  calculateTotalPrizeForFirstRace,
  checkTmrwWeight,
  countGapsBetweenRaces,
  secondUp
} from "../Forming/FormingUtils";
import "./GeneratePoints.css";
import {
  calculateCareerPercentage,
  calculateJockeyPoints,
  calculatePlacingPercentage,
  calculatePoints,
  calculateTotalPoints,
  calculateTrackPoints,
  checkDistance,
  checkLastForm,
  getBarrierPoints,
  hasJockeyRiddenHorseBefore,
  horseAge,
  lwinPlaceRecordsDistance,
  prevRacePosPoints,
  secondUpPoints,
  weightPoints,
  winRecordsDistance,
  winningPlaceRecord,
  winningRecord
} from "./GeneratePointsUtils";
import { saveHorseDataToDatabase } from './SavePoints';
const GeneratePoints = () => {
  const [results, setResults] = useState<RaceCard[]>([]);
  const [distinctCourses, setDistinctCourses] = useState<string[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<string>("");
  const [filteredRaceIds, setFilteredRaceIds] = useState<string[]>([]);
  const [selectedRaceId, setSelectedRaceId] = useState<string>("");
  const [selectedRaceRunners, setSelectedRaceRunners] = useState<Runner[]>([]);
  const [horseHistory, setHorseHistory] = useState<HorseHistoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [horseStats, setHorseStats] = useState<{
    [key: string]: {
      distanceStat: string;
      trackStat: string;
      trackAndDistanceStat: string;
      trackConditionStats: { [condition: string]: string };
      careerStat: string;
      hasSameJockeyBefore: boolean;
      headgear: number;
      totalPrizeFirstRace: number;
      lastRaceHorsePosition: string;
      ageOfHorse: string;
      winPerc: number;
      winPlcPerc: number;
      lastWeight: string;
      barrier: number;
      gapCountMessage: string;
      secondUpCount: string;
      jockeyPositionStatus: number;
      //selection: number;
    };
  }>({});
  const [saveStatus, setSaveStatus] = useState<string>("Save to Database");
  const [savedRaces, setSavedRaces] = useState<Set<string>>(new Set());

  const handleSave = async () => {
    if (savedRaces.has(selectedRaceId)) {
      setSaveStatus("Already Saved ✅");
      return;
    }
  
    setSaveStatus("Saving...");
  
    const selectedRace = results.find((race) => race.raceId === selectedRaceId);
    if (!selectedRace) {
      console.error("No race selected");
      setSaveStatus("Save to Database");
      return;
    }
  
    try {
      await saveHorseDataToDatabase(selectedRaceRunners, selectedCourse, horseStats, selectedRace);
  
      setSavedRaces((prev) => new Set(prev).add(selectedRaceId)); // Mark race as saved
      setSaveStatus("Saved ✅"); // Show "Saved ✅" immediately after saving
  
    } catch (error) {
      console.error("Failed to save horse data", error);
      setSaveStatus("Save Failed ❌");
  
      setTimeout(() => {
        setSaveStatus("Save to Database");
      }, 3000);
    }
  };
  


  const fetchTomorrowRaceCards = async () => {
    setLoading(true); // Start loading when the API call begins
    try {
      const response = await RacingAPIService.getTomorrowRaceCards();
      console.log(response,"response" )
      const raceCards = response?.data.$values as RaceCard[];
      setResults(raceCards);
      setDistinctCourses(Array.from(new Set(raceCards.map((race) => race.course))));
    } catch (error) {
      console.error("Error fetching results:", error);
    } finally {
      setLoading(false); // Stop loading once the API call is complete
    }
  };

  const handleCourseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCourse = event.target.value;
    setSelectedCourse(selectedCourse);

    const filteredRaceIds = results
      .filter((race) => race.course === selectedCourse)
      .map((race) => race.raceId);
    setFilteredRaceIds(filteredRaceIds);
    setSelectedRaceId("");
    setSelectedRaceRunners([]);
  };
  useEffect(() => {
    fetchTomorrowRaceCards();
  }, []);

  const handleRaceIdChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newRaceId = event.target.value;
    setSelectedRaceId(newRaceId);
    setLoading(true);
  
    // If the user re-selects a saved race later, show "Already Saved ✅"
    if (savedRaces.has(newRaceId)) {
      setSaveStatus("Already Saved ✅");
    } else {
      setSaveStatus("Save to Database");
    }
  
    const selectedRace = results.find((race) => race.raceId === newRaceId);
    if (selectedRace) {
      setSelectedRaceRunners(selectedRace.runners.$values);
      try {
        await fetchHorseStats(selectedRace.runners.$values, selectedRace.distanceF, selectedRace.course, selectedRace.prize, selectedRace.going);
      } catch (error) {
        console.error("Error fetching horse stats:", error);
      } finally {
        setLoading(false);
      }
    } else {
      setSelectedRaceRunners([]);
      setLoading(false);
    }
  };
  


  const getHorseHistory = async (horseId: string) => {
    const response = await RacingAPIService.getHorseResults(horseId);
    const horseValuesFromAPI: HorseHistoryItem[] =
      response?.data?.results?.$values ?? [];
    setHorseHistory(horseValuesFromAPI);
    return horseValuesFromAPI;
  };

  const fetchHorseStats = async (
    runners: Runner[],
    raceDistanceF: number,
    selectedCourse: string,
    racePrize: string,
    going: string,
  ) => {
    const newHorseStats: {
      [key: string]: {
        distanceStat: string;
        trackStat: string;
        trackAndDistanceStat: string;
        trackConditionStats: { [condition: string]: string };
        careerStat: string;
        hasSameJockeyBefore: boolean;
        headgear: number;
        totalPrizeFirstRace: number;
        lastRaceHorsePosition: string;
        ageOfHorse: string;
        winPerc: number;
        winPlcPerc: number;
        lastWeight: string;
        barrier: number;
        gapCountMessage: string;
        secondUpCount: string;
        jockeyPositionStatus: number;
       // selection: number;
      };
    } = {};
    for (const runner of runners) {
      const horseHistory = await getHorseHistory(runner.horseId);
      const lastRace = horseHistory[0];
      const lastRacePosition = lastRace?.runners.$values.find((item) => item.horseId === runner.horseId)?.position || "N/A";
      const lastRaceHeadGear = lastRace?.runners.$values.find((item) => (item.horseId === runner.horseId))?.headgear;
      const lastRaceWeight = lastRace?.runners.$values.find((item) => item.horseId === runner.horseId)?.weightLbs || "N/A";
      const lbs = runner.lbs;
      const barrier = runner.draw;
      const horseAge = runner.age;
      const hasSameJockeyBefore = hasJockeyRiddenHorseBefore(horseHistory, runner.jockey, runner.horseId);
      const hasSameJockeyLastRace = hasJockeyRiddenHorseBefore(horseHistory.slice(0, 1), runner.jockey, runner.horseId)
      const careerHistory = horseHistory.filter((history) => {
        const historyCareer = parseFloat(history.distF);
        return historyCareer;
      });

      const totalRacesAtCareer = careerHistory.length;
      const stats = careerHistory.reduce(
        (acc, race) => {
          const position = race.runners.$values.find(
            (runnerItem) => runnerItem.horseId === runner.horseId,
          )?.position;

          if (position === "1") acc.wins++;
          if (position === "2") acc.seconds++;
          if (position === "3") acc.thirds++;
          return acc;
        },
        { wins: 0, seconds: 0, thirds: 0 },
      );

      const careerStat = `${totalRacesAtCareer}:${stats.wins}.${stats.seconds}.${stats.thirds}`;

      const winPerc =
        totalRacesAtCareer > 0 ? stats.wins / totalRacesAtCareer : 0;
      const winPlcPerc =
        totalRacesAtCareer > 0
          ? (stats.wins + stats.seconds + stats.thirds) /
          totalRacesAtCareer
          : 0;
      const filteredDistanceHistory = horseHistory.filter((history) => {
        const historyDistance = parseFloat(history.distM);
        let raceDistanceM = Utilities.convertFurlongsToMeters(raceDistanceF);
        if (historyDistance > 0 && historyDistance < 2500) {
          return Math.abs(historyDistance - raceDistanceM) <= 30;
        }
        if (historyDistance > 2550 && historyDistance < 4400) {
          return Math.abs(historyDistance - raceDistanceM) <= 90;
        }
        if (historyDistance > 4400 && historyDistance < 7000) {
          return Math.abs(historyDistance - raceDistanceM) <= 180;
        }
        return false;
      });

      const totalRacesAtDistance = filteredDistanceHistory.length;
      const winsAtDistance = filteredDistanceHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "1",
        ),
      ).length;

      const secondsAtDistance = filteredDistanceHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "2",
        ),
      ).length;

      const thirdsAtDistance = filteredDistanceHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "3",
        ),
      ).length;

      const distanceStat = `${totalRacesAtDistance}:${winsAtDistance}-${secondsAtDistance}-${thirdsAtDistance}`;

      const filteredTrackHistory = horseHistory.filter(
        (history) => history.course === selectedCourse,
      );

      const totalRacesAtTrack = filteredTrackHistory.length;
      const winsAtTrack = filteredTrackHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "1",
        ),
      ).length;
      const secondsAtTrack = filteredTrackHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "2",
        ),
      ).length;
      const thirdsAtTrack = filteredTrackHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "3",
        ),
      ).length;

      const trackStat = `${totalRacesAtTrack}:${winsAtTrack}-${secondsAtTrack}-${thirdsAtTrack}`;

      const filteredTrackAndDistanceHistory = horseHistory.filter((history) => {
        const historyDistance = parseFloat(history.distM);
        let raceDistanceM = Utilities.convertFurlongsToMeters(raceDistanceF);
        return (
          history.course === selectedCourse &&
          Math.abs(historyDistance - raceDistanceM) <= 30
        );
      });

      const totalRacesAtTrackAndDistance = filteredTrackAndDistanceHistory.length;
      const winsAtTrackAndDistance = filteredTrackAndDistanceHistory.filter(
        (race) =>
          race.runners.$values.some(
            (runnerItem) =>
              runnerItem.horseId === runner.horseId &&
              runnerItem.position === "1",
          ),
      ).length;

      const secondsAtTrackAndDistance = filteredTrackAndDistanceHistory.filter(
        (race) =>
          race.runners.$values.some(
            (runnerItem) =>
              runnerItem.horseId === runner.horseId &&
              runnerItem.position === "2",
          ),
      ).length;

      const thirdsAtTrackAndDistance = filteredTrackAndDistanceHistory.filter(
        (race) =>
          race.runners.$values.some(
            (runnerItem) =>
              runnerItem.horseId === runner.horseId &&
              runnerItem.position === "3",
          ),
      ).length;
      const trackAndDistanceStat = `${totalRacesAtTrackAndDistance}:${winsAtTrackAndDistance}-${secondsAtTrackAndDistance}-${thirdsAtTrackAndDistance}`;

      const groupedConditions = [
        ["Firm"],
        ["Good", "Good To Firm", "Yielding"],
        ["Soft", "Good To Soft", "Good To Yielding", "Yielding To Soft"],
        ["Heavy", "Very Soft", "Soft To Heavy"],
        ["Synthetic", "Standard", "Standard To Slow"]
      ];

      const trackConditionStats: { [condition: string]: string } = {};

      for (const group of groupedConditions) {
        if (group.includes(going)) {
          const filteredConditionHistory = horseHistory.filter((history) =>
            group.includes(history.going)
          );

          const totalRacesAtCondition = filteredConditionHistory.length;
          const winsAtCondition = filteredConditionHistory.filter((race) =>
            race.runners.$values.some(
              (runnerItem) =>
                runnerItem.horseId === runner.horseId && runnerItem.position === "1"
            )
          ).length;

          const secondsAtCondition = filteredConditionHistory.filter((race) =>
            race.runners.$values.some(
              (runnerItem) =>
                runnerItem.horseId === runner.horseId && runnerItem.position === "2"
            )
          ).length;

          const thirdsAtCondition = filteredConditionHistory.filter((race) =>
            race.runners.$values.some(
              (runnerItem) =>
                runnerItem.horseId === runner.horseId && runnerItem.position === "3"
            )
          ).length;

          trackConditionStats[group[0]] =
            `${totalRacesAtCondition}:${winsAtCondition}-${secondsAtCondition}-${thirdsAtCondition}`;
        }
      }

      const checkHeadgearChange = (
        headgear: string,
        lastRaceHeadgear: string | undefined,
      ): number => {
        if (runner.headgear === lastRaceHeadgear) {
          return 0;
        } else {
          return 2;
        }
      };
      const headgear = checkHeadgearChange(runner.headgear, lastRaceHeadGear);
      const getRacePrice = () => {
        const cleanRacePrize = Number(
          racePrize.replace(/£|€|\$|,/g, "").trim(),
        );
        const horsePrize = calculateTotalPrizeForFirstRace(horseHistory);
        const cleanHorsePrize = Number(
          horsePrize.replace(/£|€|\$|,/g, "").trim(),
        );
        const remainingPrize = cleanRacePrize - cleanHorsePrize;
        return remainingPrize > 0 ? 0 : 3;
      };

      const checkLastRacePosition = (lastRacePosition: string): string => {
        return lastRacePosition;
      };
      const lastWeight = checkTmrwWeight(lbs, lastRaceWeight);

      const lastRaceHorsePosition = checkLastRacePosition(lastRacePosition);
      const checkHorseAge = (horseAge: string): string => {
        return horseAge;
      };
      const ageOfHorse = checkHorseAge(horseAge);
      const gapCountMessage = countGapsBetweenRaces(
        horseHistory,
        runner.horseId,
      );
      const secondUpCount = secondUp(horseHistory, runner.horseId);
      const getJockeyPositionStatus = (
        lastRace: HorseHistoryItem,
        runner: Runner,
        hasSameJockeyLastRace: boolean
      ): number => {
        const lastRacePosition = lastRace?.runners.$values.find((item) => item.horseId === runner.horseId)?.position || "N/A";
        if (hasSameJockeyLastRace && lastRacePosition === "1") {
          return 2;
        } else {
          return 0;
        }
      };
      const jockeyPositionStatus = getJockeyPositionStatus(lastRace, runner, hasSameJockeyLastRace);
      

      newHorseStats[runner.horseId] = {
        distanceStat,
        trackStat,
        trackAndDistanceStat,
        trackConditionStats,
        careerStat,
        hasSameJockeyBefore,
        headgear,
        totalPrizeFirstRace: getRacePrice(),
        lastRaceHorsePosition,
        ageOfHorse,
        winPerc,
        winPlcPerc,
        lastWeight,
        barrier,
        gapCountMessage,
        secondUpCount,
        jockeyPositionStatus,
        //selection:calculateTotalPoints(runner, horseHistory, horseStats, selectedRaceRunners),
      };


    }
    setHorseStats(newHorseStats);
  };

  return (
    <div className="generate-points-container">
      <h1 className="title">Generate Points</h1>
      <div className="dropdown-container">
        <label htmlFor="courseDropdown" className="dropdown-label">
          Select Course:
        </label>
        <select
          id="courseDropdown"
          value={selectedCourse}
          onChange={handleCourseChange}
          className="dropdown-select"
        >
          <option value="">-- Select a Course --</option>
          {distinctCourses.map((course, index) => (
            <option key={index} value={course}>
              {course}
            </option>
          ))}
        </select>
      </div>

      {selectedCourse && (
        <div className="dropdown-container">
          <label htmlFor="raceIdDropdown" className="dropdown-label">
            Select Race ID:
          </label>
          <select
            id="raceIdDropdown"
            value={selectedRaceId}
            onChange={handleRaceIdChange}
            className="dropdown-select"
          >
            <option value="">-- Select a Race ID --</option>
            {filteredRaceIds.map((raceId, index) => (
              <option key={index} value={raceId}>
                {raceId}
              </option>
            ))}
          </select>
        </div>

      )}
      <Box>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: "flex-start", height: '50vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          selectedRaceRunners.length > 0 && (
            <><div className="table-container">


                <table className="runners-table">
                  <thead>
                    <tr>
                      <th>Horse Name</th>
                      <th style={{ textAlign: "center" }}>A</th>
                      <th style={{ textAlign: "center" }}>B</th>
                      <th style={{ textAlign: "center" }}>C</th>
                      <th style={{ textAlign: "center" }}>D</th>
                      <th style={{ textAlign: "center" }}>E</th>
                      <th style={{ textAlign: "center" }}>F</th>
                      <th style={{ textAlign: "center" }}>G</th>
                      <th style={{ textAlign: "center" }}>H</th>
                      <th style={{ textAlign: "center" }}>I</th>
                      <th style={{ textAlign: "center" }}>L</th>
                      <th style={{ textAlign: "center" }}>M</th>
                      <th style={{ textAlign: "center" }}>N</th>
                      <th style={{ textAlign: "center" }}>P</th>
                      <th style={{ textAlign: "center" }}>W</th>
                      <th style={{ textAlign: "center" }}>X</th>
                      <th style={{ textAlign: "center" }}>Y</th>
                      <th style={{ textAlign: "center" }}>Z</th>
                      <th style={{ textAlign: "center" }}>03</th>
                      <th style={{ textAlign: "center" }}>04</th>
                      <th style={{ textAlign: "center" }}>Points</th>

                    </tr>
                  </thead>
                  <tbody>
                    {selectedRaceRunners
                      .map((runner) => {



                        return (
                          <tr key={runner.horseId}>
                            <td>{runner.horse}</td>
                            <td>
                              <div>
                                {horseStats[runner.horseId]?.distanceStat}
                              </div>
                              <div style={{ whiteSpace: "nowrap" }}>
                                <b>Points:</b>{" "}
                                {checkDistance(
                                  horseStats[runner.horseId]?.distanceStat
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                {horseStats[runner.horseId]?.trackStat}
                              </div>
                              <div style={{ whiteSpace: "nowrap" }}>
                                <b>Points:</b>
                                {checkDistance(horseStats[runner.horseId]?.trackStat)}
                              </div>
                            </td>
                            <td>
                              <div>
                                {horseStats[runner.horseId]?.trackAndDistanceStat}
                              </div>
                              <div style={{ whiteSpace: "nowrap" }}>
                                <b>Points:</b>{" "}
                                {checkDistance(
                                  horseStats[runner.horseId]?.trackAndDistanceStat
                                )}
                              </div>
                            </td>
                            <td style={{ whiteSpace: "nowrap", padding: "4px" }}>
                              {Object.entries(horseStats[runner.horseId]?.trackConditionStats || {}).map(
                                ([condition, stat]) => {
                                  const percentage = calculatePlacingPercentage(stat);

                                  return (
                                    <div key={condition}>
                                      <b>{trackConditionAbbreviations[condition] || condition}</b>: {stat}   <b>%:</b>  {percentage}
                                      <div>
                                        <b>Points:</b> {calculateTrackPoints(runner, horseStats, selectedRaceRunners)}
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </td>

                            <td style={{ whiteSpace: "nowrap" }}>
                              <div>
                                {horseStats[runner.horseId]?.careerStat}
                                <b>%</b>
                                {calculateCareerPercentage(
                                  horseStats[runner.horseId]?.careerStat
                                )}
                              </div>
                              <div>
                                <b>Points:</b>
                                {calculatePoints(runner, horseStats, selectedRaceRunners)}
                              </div>
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {horseStats[runner.horseId]?.gapCountMessage}
                              <div>
                                <b>Points:</b>
                                {checkLastForm(
                                  runner.form,
                                  horseStats[runner.horseId]?.gapCountMessage
                                )}
                              </div>
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {horseStats[runner.horseId]?.secondUpCount}
                              <div>
                                <b>Points:</b>
                                {secondUpPoints(
                                  runner.form,
                                  horseStats[runner.horseId]?.secondUpCount
                                )}
                              </div>
                            </td>
                            <td>
                              <b>Points:</b>
                              {horseStats[runner.horseId]?.totalPrizeFirstRace || "0"}
                            </td>
                            <td style={{ whiteSpace: "nowrap" }}>
                              {horseStats[runner.horseId]?.hasSameJockeyBefore !== undefined && (
                                <span>
                                  {horseStats[runner.horseId]?.hasSameJockeyBefore ? "Ridden before" : "New jockey"}
                                </span>
                              )}

                              <div>
                                <b>Points:</b>
                                {calculateJockeyPoints(
                                  horseHistory,
                                  runner.jockey,
                                  runner.horseId
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                <b>Points:</b>
                                {horseStats[runner.horseId]?.headgear}
                              </div>
                            </td>
                            <td>
                              {horseStats[runner.horseId]?.barrier && (
                                <>
                                  {horseStats[runner.horseId]?.barrier}
                                  <div style={{ whiteSpace: "nowrap" }}>
                                    <b>Points:</b> {getBarrierPoints(horseStats[runner.horseId]?.barrier)}
                                  </div>
                                </>
                              )}
                            </td>
                            <td>
                              <div style={{ textAlign: "center" }}> {horseStats[runner.horseId]?.lastWeight}</div>
                              <div style={{ whiteSpace: "nowrap" }}>
                                <b>Points:</b>
                                {horseStats[runner.horseId]?.lastWeight !== undefined && (
                                  weightPoints(parseFloat(horseStats[runner.horseId].lastWeight) || 0)
                                )}

                              </div>
                            </td>
                            <td>
                              {horseStats[runner.horseId]?.lastRaceHorsePosition !== undefined && (
                                `${horseStats[runner.horseId]?.lastRaceHorsePosition}`
                              )}

                              <div>
                                <b>Points:</b>
                                {prevRacePosPoints(
                                  horseStats[runner.horseId]?.lastRaceHorsePosition
                                )}
                              </div>
                            </td>
                            <td>
                              <div style={{ whiteSpace: "nowrap" }}>
                                <b>Points:</b>
                                {horseStats[runner.horseId]?.jockeyPositionStatus}
                              </div>
                            </td>
                            <td>
                              {runner.age !== undefined && (
                                <>
                                  <div>Age: {runner.age}</div>
                                  <div style={{ whiteSpace: "nowrap" }}>
                                    <b>Points: </b>
                                    {horseAge(runner.age)}
                                  </div>
                                </>
                              )}
                            </td>


                            <td>
                              <div>
                                <b>Points:</b>
                                {winningRecord(
                                  horseStats[runner.horseId]?.careerStat
                                )}
                              </div>
                            </td>
                            <td>
                              <div>
                                <b>Points:</b>
                                {winningPlaceRecord(
                                  horseStats[runner.horseId]?.careerStat
                                )}
                              </div>
                            </td>
                            <td><div>
                              <b>Points:</b>
                              {winRecordsDistance(
                                horseStats[runner.horseId]?.careerStat
                              )}
                            </div></td>
                            <td><div>
                              <b>Points:</b>
                              {lwinPlaceRecordsDistance(
                                horseStats[runner.horseId]?.careerStat
                              )}
                            </div></td>
                            <td style={{ color: "red", textAlign: "center" }}><b> {calculateTotalPoints(runner, horseHistory, horseStats, selectedRaceRunners)}</b></td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {/* <button 
                onClick={() => {
                  const selectedRace = results.find((race) => race.raceId === selectedRaceId);
                  if (selectedRace) {
                    saveHorseDataToDatabase(
                      selectedRaceRunners,
                      selectedCourse,
                      horseStats,                     
                      selectedRace,
                      
                    );
                  } else {
                    console.error("No race selected");
                  }
                } }
                
              >
                  Save to Database
                </button> */}
                <button onClick={handleSave}>
                  {saveStatus}
                </button>

                </>
          )
        )}
      </Box>
    </div>
  );
};

export default GeneratePoints;