import { Typography } from "@mui/material";
import { Messages } from "Helpers/messages.enum";
import { socket } from "Socket";
import Table from "components/Table/Table";
import { ShortErrorToast } from "components/Toast/Toastify";
import Markee from "components/markee/markee";
import { useGeneralContext } from "pages/context/Context";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { GetAdminsThunk } from "redux/createAsyncThunk";
import { AdminViewColumns } from "./AdminColumns";

function ViewAdmins() {
  const dispatch = useDispatch<any>();
  const { getData, setData } = useGeneralContext();

  const [userData, setUserData] = useState({ users: [], error: false });
  const [loading, setLoading] = useState(false);

  const isInitialized = useRef(false); // Prevent multiple initializations

  // Fetch data only once during initial load
  useEffect(() => {
    if (isInitialized.current) return; // Prevent re-initialization
    isInitialized.current = true;

    const initializeData = async () => {
      const cachedAdminData = await getData<{ users: any[]; error: boolean }>(
        "adminData",
      );

      if (cachedAdminData?.users && cachedAdminData?.users?.length > 0) {
        setUserData(cachedAdminData as any);
        setLoading(false);
      } else {
        fetchAdmins();
      }
    };

    const fetchAdmins = async () => {
      try {
        setLoading(true);
        const response: any = await dispatch(GetAdminsThunk());
        if (response.payload?.data?.length > 0) {
          const data = { users: response.payload.data, error: false };
          setUserData(data);
          await setData("adminData", data);
        } else {
          handleError();
        }
      } catch (error) {
        console.error(error);
        handleError();
      } finally {
        setLoading(false);
      }
    };

    const handleError = async () => {
      const errorData = { users: [], error: true };
      setUserData(errorData);
      await setData("adminData", errorData);
    };

    initializeData();
  }, [dispatch, getData, setData]);

  // Handle real-time updates from the socket
  useEffect(() => {
    const startAdminWatching = () => {
      socket.on("userChangeData", (data) => {
        const { operationType, fullDocument, documentKey, updateDescription } =
          data;
        const updatedAdminId = documentKey?._id;

        setUserData((prevUserData: any) => {
          let updatedUsers = [...prevUserData.users];

          if (operationType === "insert" && fullDocument) {
            updatedUsers.push(fullDocument);
          } else if (
            operationType === "update" &&
            updateDescription?.updatedFields
          ) {
            updatedUsers = updatedUsers.map((user: any) =>
              user._id === updatedAdminId
                ? { ...user, ...updateDescription.updatedFields }
                : user,
            );
          } else if (operationType === "delete") {
            updatedUsers = updatedUsers.filter(
              (user: any) => user._id !== updatedAdminId,
            );
          }

          const newUserData = { ...prevUserData, users: updatedUsers };
          setData("adminData", newUserData); // Sync with cache
          return newUserData;
        });
      });
    };

    startAdminWatching();

    return () => {
      socket.off("userChangeData");
    };
  }, [setData]);

  const memoizedColumns = useMemo(() => AdminViewColumns, []);

  return (
    <>
      <Typography variant={"h4"}>
        <Markee text="All Users" />
      </Typography>
      <Table
        loading={loading}
        disableRowSelectionOnClick
        searchable
        columns={memoizedColumns}
        rows={userData.users}
      />
      {userData.error && <ShortErrorToast message={Messages.ERROR} />}
    </>
  );
}

export default React.memo(ViewAdmins);
