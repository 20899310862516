import axios from "axios";
const getResults = () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_BASE_COLD_SOTRAGE}RacingApi/GetResults/results`,
    );
  } catch (error) {
    console.log("  failed to get results", error);
  }
};
const getHorseResults = (horseId: string) => {
  try {
    return axios.get(
      `${process.env.REACT_APP_BASE_COLD_SOTRAGE}RacingApi/GetHorseResult/${horseId}`,
    );
  } catch (error) {
    console.log("  failed to get results", error);
  }
};
const getHorseHistory = (horseId: string) => {
  try {
    return axios.get(
      `${process.env.REACT_APP_BASE_COLD_SOTRAGE}RacingApi/GetHorseHistory/${horseId}`,
    );
  } catch (error) {
    console.log(" failed to get horse history", error);
  }
};
const getTomorrowRaceCards = () => {
  try {
    return axios.get(
      `${process.env.REACT_APP_BASE_COLD_SOTRAGE}RacingApi/GetRaceCardsPro/tomorrow`,
    );
  } catch (error) {
    console.log("failed to get results ", error);
  }
};

const getJockeyHistory = async (jockeyId: string) => {
  try {
    return axios.get(
      `${process.env.REACT_APP_BASE_COLD_SOTRAGE}RacingApi/GetJockeyHistory/${jockeyId}`,
    );
  } catch (error) {
    console.log("Failed to get results", error);
  }
};

const saveHorseData = async (horseDataList: any) =>{
  try{
    let url = `${process.env.REACT_APP_BASE_COLD_SOTRAGE}RacingApi/SaveHorseData`;
    console.log("Sending request to:", url);
    console.log("Payload:", JSON.stringify(horseDataList, null, 2));
    
    return axios.post(url, horseDataList);
  }catch(error){
    console.log("failed to save horse data", error);
  }
}

export const RacingAPIService = {
  getResults,
  getHorseResults,
  getHorseHistory,
  getTomorrowRaceCards,
  getJockeyHistory,
  saveHorseData,
};
