import { DevicesOtherOutlined, ExitToApp, Person } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import {
  CssBaseline,
  Drawer,
  Grid,
  List,
  ListItemIcon,
  styled,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Mobile } from "Helpers/Constants";
import { roleTypes } from "Helpers/role-types.enum";
import ListItemButtonAuth from "components/Collapse/ListItemButtonAuth";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Outlet } from "react-router-dom";
import { logout } from "redux/reducers/authReducer";
import { io } from "socket.io-client";
import CollapsibleListItem from "../Collapse/CollapsibleListItem";

const drawerWidth = 240;

const AdminLayout = () => {
  const [open, setOpen] = useState(true);
  const [activeUsers, setActiveUsers] = useState(0);

  const handleDrawerToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setOpen(!open);
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BASE_URL_API);
    socket.on("activeUsers", (count) => {
      setActiveUsers(count);
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  const dispatch = useDispatch();

  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
  );

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  const handleLogout = () => {
    dispatch(logout());
    window.location.href = "/";
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position={"fixed"}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "darkcyan" }}
      >
        <Toolbar>
          <Grid container sx={{ alignItems: "center" }}>
            <Grid item xs={6} lg={4}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs={6} lg={4} sx={{ textAlign: "center" }}>
              <Button href="/" LinkComponent={Link} color="inherit">
                Admin ({activeUsers})
              </Button>
            </Grid>
            <Grid
              item
              lg={4}
              sx={{
                textAlign: "end",
                display: () => (Mobile() ? "none" : "block"),
              }}
            >
              <Button sx={{ justifyContent: "center" }}>
                <ListItemIcon>
                  {/* <ExitToApp sx={{ color: "white" }} /> */}
                  <Person sx={{ color: "white" }} />
                </ListItemIcon>
              </Button>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
      >
        <Toolbar />
        {/* NAVIGATION ITEMS */}
        <Box sx={{ overflow: "auto", flexGrow: 1 }}>
          <List>
            <ListItemButtonAuth link="/" title="Home" icon={<HomeIcon />} />
            <CollapsibleListItem
              icon={<Person />}
              title="Users"
              requiredRoles={[roleTypes.ADMIN_PERMISSION]}
              items={[
                {
                  name: "All",
                  link: "/users/view",
                  requiredRoles: [roleTypes.ADMIN_PERMISSION],
                },
                {
                  name: "Admins",
                  link: "/users/admin/view",
                  requiredRoles: [roleTypes.ADMIN_PERMISSION],
                },
                {
                  name: "Emails",
                  link: "/users/emails",
                  requiredRoles: [roleTypes.ADMIN_PERMISSION],
                },
                // {
                //   name: "NAB",
                //   link: "/users/nab",
                //   requiredRoles: [roleTypes.ADMIN],
                // }
              ]}
            />
            <CollapsibleListItem
              title="Controller"
              icon={<DevicesOtherOutlined />}
              items={[
                {
                  name: "Venues",
                  link: "/controller/venues",
                  requiredRoles: [roleTypes.ADMIN_PERMISSION],
                },
                { name: "Bookies", link: "/controller/bookies" },
                { name: "Upload AAP", link: "/controller/upload-aap" },
                { name: "Upload AAP XML", link: "/controller/upload-aap-xml" },
                { name: "Racing API", link: "/controller/racing-api" },
                {
                  name: "Generate Points",
                  link: "/controller/generate-points",
                },
                { name: "Forming", link: "/controller/forming" },
              ]}
            />
            {/* <ListItemButtonAuth onClick={handleLogout} title="Logout" icon={<ExitToApp />} /> */}
          </List>
        </Box>
        <Box sx={{ flexGrow: 0, borderTop: 1, borderColor: "divider" }}>
          <List>
            <ListItemButtonAuth
              onClick={handleLogout}
              title="Logout"
              icon={<ExitToApp />}
            />
          </List>
        </Box>
      </Drawer>
      <Main>
        <DrawerHeader />
        <Outlet />
      </Main>
    </Box>
  );
};

export default AdminLayout;
