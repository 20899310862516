import React from "react";
import { RaceCard } from "./FormingTypes";

interface RaceDropdownProps {
  selectedCourse: string;
  selectedRaceId: string;
  handleRaceIdChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  results: RaceCard[];
}

const RaceDropdown: React.FC<RaceDropdownProps> = ({
  selectedCourse,
  selectedRaceId,
  handleRaceIdChange,
  results,
}) => {
  return (
    <select
      id="raceIdDropdown"
      value={selectedRaceId}
      onChange={handleRaceIdChange}
      style={{ padding: "8px", boxSizing: "border-box" }}
    >
      <option value="">Select a Race</option>
      {results
        .filter((race) => race.course === selectedCourse)
        .sort(
          (a, b) =>
            new Date(a.offTime).getTime() - new Date(b.offTime).getTime(),
        )
        .map((race, index) => (
          <option key={race.raceId} value={race.raceId}>
            {`Race ${index + 1}`}{" "}
          </option>
        ))}
    </select>
  );
};

export default RaceDropdown;
