import { GeneralProvider } from "pages/context/Context";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import store from "./redux/store";

const rootElement = document.getElementById("root");
if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <GeneralProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </GeneralProvider>,
  );
}
