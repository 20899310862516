import { createAsyncThunk } from "@reduxjs/toolkit";
import { UserService } from "../Services/UserService";

export const LoginThunk = createAsyncThunk(
  "auth/login",
  async (
    payload: { email: string; password: string; isAdminLogin?: boolean },
    thunkAPI,
  ) => {
    try {
      const response = await UserService.userLogin(payload);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const GetUsersThunk = createAsyncThunk(
  "admin/find-all-users",
  async (payload, thunkAPI) => {
    try {
      const response = await UserService.getUsers();
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const GetAdminsThunk = createAsyncThunk(
  "admin/find-all-admins",
  async (payload, thunkAPI) => {
    try {
      const response = await UserService.getAdmins();
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const getNabPlayersThunk = createAsyncThunk(
  "admin/find-nab-players",
  async (payload, thunkAPI) => {
    try {
      const response = await UserService.getNabPlayers();
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);