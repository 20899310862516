import { ToastPosition, toast } from "react-toastify";

type ShortToastProps = {
  message: string;
  position?: ToastPosition;
  autoClose?: number;
  hideProgressBar?: boolean;
  closeOnClick?: boolean;
  pauseOnHover?: boolean;
  draggable?: boolean;
  progress?: string | number | undefined;
};

export function ShortErrorToast({
  message,
  position,
  autoClose,
  hideProgressBar,
  closeOnClick,
  pauseOnHover,
  draggable,
  progress,
}: ShortToastProps) {
  return (
    message &&
    toast.error(message, {
      position: position || "top-right",
      autoClose: autoClose || 3000,
      hideProgressBar: hideProgressBar || false,
      closeOnClick: closeOnClick || true,
      pauseOnHover: pauseOnHover || false,
      draggable: draggable || true,
      progress: progress || undefined,
    })
  );
}

export function ShortSuccessToast({
  message,
  position,
  autoClose,
  hideProgressBar,
  closeOnClick,
  pauseOnHover,
  draggable,
  progress,
}: ShortToastProps) {
  return (
    message &&
    toast.success(message, {
      position: position || "top-right",
      autoClose: autoClose || 1000,
      hideProgressBar: hideProgressBar || false,
      closeOnClick: closeOnClick || true,
      pauseOnHover: pauseOnHover || false,
      draggable: draggable || true,
      progress: progress || undefined,
    })
  );
}
