import { useMediaQuery } from "@mui/material";
import { decodeJWT } from "./decode-jwt";

export const Mobile = () => {
  const MOBILE = "(max-width:1100px)";
  return useMediaQuery(MOBILE);
};

export const today = new Date().toISOString().split("T")[0];
export const light_cyan = "#99c4c4";

export const DATE_YEAR_MONTH_DAY = "YYYY-MM-DD";

export const getDecodedRoles = () => {
  try {
    const jwt= localStorage.getItem("PTPtokenFromStorageToAuthenticateUserOnAdmin");
    if (!jwt) {
      return [];
    }
    const decodedToken = decodeJWT(jwt)
    return decodedToken.roles;
  } catch (error) {
    console.error("Error decoding roles:", error);
    return [];
  }
};
