export enum RaceStatusAvailabilityTypes {
  AVAILABLE = "available",
  NOTAVAILABLE = "notavailable",
}

export const raceStatusAvailabilityMapping = [
  { value: RaceStatusAvailabilityTypes.AVAILABLE, label: "Available" },
  { value: RaceStatusAvailabilityTypes.NOTAVAILABLE, label: "Not Available" },
];

export const getRaceAvailabilityLabelFromValue = (value: any) => {
  const processValue = (val: any) => {
    const status = raceStatusAvailabilityMapping.find(
      (status) => status.value === val,
    );
    return status ? status.label : "";
  };

  if (Array.isArray(value)) {
    return processValue(value[0]);
  } else {
    return processValue(value);
  }
};
