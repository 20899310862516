import { Box, Grid, TextField, Typography } from "@mui/material";
import { DATE_YEAR_MONTH_DAY } from "Helpers/Constants";
import { userRoles } from "Helpers/role-types.enum";
import { UserService } from "Services/UserService";
import { ShortErrorToast, ShortSuccessToast } from "components/Toast/Toastify";
import DynamicButton from "components/dynamic-button/DynamicButton";
import SelectDropdown from "components/select/Select";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import isValidEmail from "utils/validate-email";

const UserInfoForm = () => {
  const [edit, setEdit] = useState(false);
  const [user, setUser] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    country: string;
    createdAt: string;
    isAdmin: boolean;
    isVerified: boolean;
    region: string;
    isChecked18: boolean;
    roles: any[]
  }>({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    createdAt: "",
    isAdmin: false,
    isVerified: false,
    region: "",
    isChecked18: false,
    roles: [],
  });


  const [isFormValid, setIsFormValid] = useState(false);
  const { id } = useParams<{ id: string }>();

  const validateForm = useCallback(() => {
    const { firstName, lastName, email, country, region, roles } = user;
    setIsFormValid(
      !!(
        firstName &&
        lastName &&
        email &&
        country &&
        region &&
        roles.length &&
        isValidEmail(email)
      ),
    );
  }, [user]);

  const getUserByParam = useCallback(async () => {
    try {
      const response = await UserService.getUser(id || "");
      if (response && response.status === 201) {
        setUser({ ...response.data.data, roles: response.data.data.roles.map((r: any) => r.name) });
      }
    } catch (error) {
      const errorMessage =
        (error as any)?.response?.data?.message || "Failed to fetch user";
      ShortErrorToast({ message: errorMessage });
    }
  }, [id]);

  useEffect(() => {
    getUserByParam();
  }, [getUserByParam]);

  useEffect(() => {
    validateForm();
  }, [validateForm, user]);

  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  }, []);

  const editUser = useCallback(async () => {
    try {
      const userUpdate = await UserService.editUser(id || "", user);
      if (userUpdate && userUpdate.status === 201) {
        ShortSuccessToast({ message: "User updated successfully" });
        getUserByParam();
      }
    } catch (error) {
      const errorMessage =
        (error as any)?.response?.data?.message || "Failed to update user";
      ShortErrorToast({ message: errorMessage });
    } finally {
      setEdit(false);
    }
  }, [id, user, getUserByParam]);

  const style = {
    TextField: {
      "& .Mui-disabled": {
        WebkitTextFillColor: "darkcyan !important",
        color: "darkcyan !important",
      },
      WebkitTextFillColor: "darkslategray !important",
      color: "darkslategray !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "black",
        },
        "&:hover fieldset": {
          borderColor: "black",
        },
        "&.Mui-focused fieldset": {
          borderColor: "black",
        },
      },

      ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "1px solid darkcyan",
      },
      "&:not(.Mui-disabled) .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "1px solid darkcyan",
      },
    },
  };

  return (
    <>
      <Box
        width={"100%"}
        textAlign={"center"}
        alignContent={"center"}
        alignItems={"center"}
        mb={2}
        display={"flex"}
        flexDirection={"row"}
        justifyItems={"space-between"}
        justifyContent={"space-between"}
      >
        <Typography variant="h4" gutterBottom>
          User Information
        </Typography>
        <Box display={"flex"} flexDirection={"row"} gap={1}>
          {edit && (
            <DynamicButton
              onClick={editUser}
              label={"Save"}
              disabled={!isFormValid}
            />
          )}
          {!edit && (
            <DynamicButton onClick={() => setEdit(true)} label={"Edit"} />
          )}
          {edit && (
            <DynamicButton
              color="red"
              onClick={() => setEdit(false)}
              label={"Cancel"}
            />
          )}
        </Box>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <TextField
            sx={style.TextField}
            required
            id="firstName"
            name="firstName"
            fullWidth
            autoComplete="given-name"
            label="First name"
            variant="outlined"
            disabled={!edit}
            value={user.firstName}
            InputLabelProps={{
              shrink: true,
            }}
            onInput={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            sx={style.TextField}
            disabled={!edit}
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="outlined"
            value={user.lastName}
            InputLabelProps={{
              shrink: true,
            }}
            onInput={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            sx={style.TextField}
            disabled={!edit}
            required
            id="region"
            name="region"
            label="Region"
            fullWidth
            autoComplete="Region"
            variant="outlined"
            value={user.region}
            InputLabelProps={{
              shrink: true,
            }}
            onInput={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            sx={style.TextField}
            disabled={!edit}
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="Country"
            variant="outlined"
            value={user.country}
            InputLabelProps={{
              shrink: true,
            }}
            onInput={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {!edit && (
            <TextField
              sx={style.TextField}
              disabled={true}
              required
              id="isChecked18"
              name="isChecked18"
              label="18+"
              fullWidth
              autoComplete="is admin"
              variant="outlined"
              value={user.isChecked18 ? "Yes" : "No"}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          {edit && (
            <SelectDropdown
              label="18+"
              value={user.isChecked18 ? "Yes" : "No"}
              onChange={(e) =>
                setUser((prevState: any) => ({
                  ...prevState,
                  isChecked18: e.target.value === "Yes" ? true : false,
                }))
              }
              items={["Yes", "No"]}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            sx={style.TextField}
            disabled={true}
            required
            id="registeredAt"
            name="registeredAt"
            label="Registration date"
            fullWidth
            autoComplete="Registration date"
            variant="outlined"
            value={dayjs(user.createdAt).format(DATE_YEAR_MONTH_DAY)}
            InputLabelProps={{
              shrink: true,
            }}
            onInput={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            sx={style.TextField}
            disabled={!edit}
            required
            id="email"
            name="email"
            label="Email"
            fullWidth
            autoComplete="email"
            variant="outlined"
            value={user.email}
            InputLabelProps={{
              shrink: true,
            }}
            onInput={onChange}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {!edit && (
            <TextField
              sx={style.TextField}
              disabled={true}
              required
              id="isAdmin"
              name="isAdmin"
              label="Admin"
              fullWidth
              autoComplete="is admin"
              variant="outlined"
              value={user.isAdmin ? "Yes" : "No"}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          {edit && (
            <SelectDropdown
              label="Admin"
              value={user.isAdmin ? "Yes" : "No"}
              onChange={(e) =>
                setUser((prevState: any) => ({
                  ...prevState,
                  isAdmin: e.target.value === "Yes" ? true : false,
                }))
              }
              items={["Yes", "No"]}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {!edit && (
            <TextField
              sx={style.TextField}
              disabled={true}
              required
              id="isVerified"
              name="isVerified"
              label="Verified"
              fullWidth
              autoComplete="is verified"
              variant="outlined"
              value={user.isVerified ? "Yes" : "No"}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          {edit && (
            <SelectDropdown
              label="Verified"
              value={user.isVerified ? "Yes" : "No"}
              onChange={(e) =>
                setUser((prevState: any) => ({
                  ...prevState,
                  isVerified: e.target.value === "Yes" ? true : false,
                }))
              }
              items={["Yes", "No"]}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={4}>
          {!edit && (
            <TextField
              sx={style.TextField}
              disabled={true}
              required
              id="roles"
              name="roles"
              label="Roles"
              fullWidth
              autoComplete="roles"
              variant="outlined"
              value={user?.roles?.map((role: any) => role).join(", ") || ""}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}

          {edit && (
            <SelectDropdown
              label="Roles"
              multiple
              value={user.roles}
              onChange={(e) =>
                setUser((prevState: any) => ({
                  ...prevState,
                  roles: e.target.value,
                }))
              }
              items={userRoles}
            />
          )}
        </Grid>

      </Grid>
    </>
  );
};

export default UserInfoForm;
