import { createSlice } from "@reduxjs/toolkit";
import { openDB } from "idb";
import { LoginThunk } from "../createAsyncThunk";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    isLoggedIn: false,
    user: null,
    error: null,
    loading: false,
  },
  reducers: {
    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.error = null;
      state.loading = false;
      localStorage.removeItem("PTPtokenFromStorageToAuthenticateUserOnAdmin");
      localStorage.removeItem("name");
      localStorage.removeItem("lastName");
      localStorage.removeItem("roles");
      localStorage.removeItem("permissions")
      const clearIndexedDB = async () => {
        const db = await openDB("AppDB", 2);
        const tx = db.transaction("dataStore", "readwrite");
        await tx.objectStore("dataStore").clear();
        await tx.done;
        db.close();
      };
      clearIndexedDB();
      window.dispatchEvent(new Event("clearContext"));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginThunk.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user = action?.payload?.data;
        state.error = null;
        state.loading = false;
        localStorage.setItem(
          "PTPtokenFromStorageToAuthenticateUserOnAdmin",
          action?.payload?.data.data
            .PTPtokenFromStorageToAuthenticateUserOnAdmin,
        );
        localStorage.setItem(
          "name",
          JSON.stringify(action?.payload?.data.data.name),
        );
        localStorage.setItem(
          "lastName",
          JSON.stringify(action?.payload?.data.data.lastName),
        );
        state.loading = false;
      })
      .addCase(LoginThunk.rejected, (state, action) => {
        state.isLoggedIn = false;
        state.user = null;
        state.error = action.payload as null;
        state.loading = false;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
