import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { UserService } from "Services/UserService";
import axios from "axios";
import { ShortErrorToast, ShortSuccessToast } from "components/Toast/Toastify";
import Papa from "papaparse";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

function UploadAap() {
  const [files, setFiles] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  const [jsonData, setJsonData] = useState<Record<string, any[]>>({});
  const [loading, setLoading] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(
    axios.CancelToken.source(),
  );

  const handleFileRead = (fileType: string, fileContent: string) => {
    Papa.parse(fileContent, {
      complete: (results) => {
        setJsonData((prev) => ({
          ...prev,
          [fileType]: [...(prev[fileType] || []), ...results.data],
        }));
      },
      header: true,
      skipEmptyLines: true,
      error: (error: any) => {
        console.error("Error parsing CSV:", error);
      },
    });
  };

  const sanitizeFileType = (fileName: string) => {
    const nameWithoutExtension = fileName.split(".")[0];
    let lastChar;

    if (nameWithoutExtension.includes("(")) {
      lastChar = nameWithoutExtension.split(" ")[0].slice(-1);
    } else {
      lastChar = nameWithoutExtension.slice(-1);
    }

    switch (lastChar) {
      case "F":
        return "F";
      case "R":
        return "R";
      case "H":
        return "H";
      default:
        return "U";
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();
        const fileType = sanitizeFileType(file.name);

        if (!files.includes(file.name)) {
          reader.onload = () =>
            handleFileRead(fileType, reader.result as string);
          reader.readAsText(file);
          setFiles((prev) => [...prev, file.name]);
        } else {
          console.log("File already uploaded:", file.name);
        }
      });
    },
    [files],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadData = useCallback(
    async (uploadFn: any, fileType: string) => {
      try {
        const upload = await uploadFn();
        console.log("STATUS UPLOAD", upload?.status, upload)
        if (upload?.status === 200) {
          setUploadedFiles((prev: any) => (
            [
              ...prev,
              files.filter((file) => sanitizeFileType(file) === fileType),
            ]
          ));
          setFiles((prev) =>
            prev.filter((file) => sanitizeFileType(file) !== fileType),
          );
          ShortSuccessToast({
            message: `Upload ${fileType === "R" ? "Race" : fileType === "H" ? "Horse" : "Forming"} succeeded`,
          });
        } else {
          ShortErrorToast({
            message: `Upload ${fileType} failed: ${upload?.data.message}`,
          });
        }
      } catch (error: any) {
        if (axios.isCancel(error)) {
          ShortErrorToast({ message: `Upload ${fileType} cancelled` });
        } else {
          ShortErrorToast({
            message: `Upload ${fileType} failed: ${error.message}`,
          });
        }
      }
    },
    [files],
  );

  const UploadRaceAap = useCallback(() => {
    return uploadData(
      () =>
        UserService.uploadAapRace(jsonData.R, {
          cancelToken: cancelTokenSource.token,
        }),
      "R",
    );
  }, [jsonData.R, uploadData, cancelTokenSource]);
  const UploadHorseAap = useCallback(() => {
    return uploadData(
      () =>
        UserService.uploadAapHorse(jsonData.H, {
          cancelToken: cancelTokenSource.token,
        }),
      "H",
    );
  }, [jsonData.H, uploadData, cancelTokenSource]);
  const UploadFormAap = useCallback(() => {
    return uploadData(
      () =>
        UserService.uploadAapForm(jsonData.F, {
          cancelToken: cancelTokenSource.token,
        }),
      "F",
    );
  }, [jsonData.F, uploadData, cancelTokenSource]);
  useEffect(() => {
    console.log(jsonData);
  }, [jsonData]);
  const handleRemoveFile = (event: React.MouseEvent, fileName: string) => {
    event.stopPropagation();
    const fileType = sanitizeFileType(fileName);
    setFiles(files.filter((file) => file !== fileName));
    setJsonData((prev) => {
      const updatedData = { ...prev };
      updatedData[fileType] =
        prev[fileType]?.filter((_, index) => files[index] !== fileName) || [];
      return updatedData;
    });
  };
  const handleUpload = async () => {
    try {
      setLoading(true);
      if (jsonData.R) await UploadRaceAap();
      if (jsonData.H) await UploadHorseAap();
      if (jsonData.F) await UploadFormAap();
      setJsonData({});
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    cancelTokenSource.cancel("Upload cancelled by user");
    setLoading(false);
    setFiles([]);
    setUploadedFiles([]);
    setJsonData({});
    setCancelTokenSource(axios.CancelToken.source());
  };

  return (
    <>
      <Typography variant="h4">Controller - Upload AAP Files</Typography>
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} lg={10}>
          <Button
            variant="contained"
            disabled={files.length === 0 || loading}
            color="primary"
            onClick={handleUpload}
            sx={{ position: "relative", marginRight: "10px" }}
          >
            {loading ? (
              <>
                <LinearProgress
                  color="success"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "4px",
                  }}
                />
                Uploading...
              </>
            ) : (
              "Upload AAP"
            )}
          </Button>
          <Button
            disabled={!loading}
            onClick={handleCancel}
            sx={{
              border: loading ? "1px solid black" : "none",
              color: loading ? "black" : "inherit",
              backgroundColor: "inherit",
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item xs={12} lg={10}>
          <Box
            {...getRootProps({
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
                borderWidth: "2px",
                borderRadius: "20px",
                borderColor: "#eeeeee",
                borderStyle: "dashed",
                backgroundColor: "#fafafa",
                color: "#bdbdbd",
                outline: "none",
                transition: "border .24s ease-in-out",
                cursor: "pointer",
                height: "300px",
                justifyContent: "center",
                overflowY: "auto",
              },
            })}
          >
            <input {...getInputProps()} disabled={loading} />
            {files.length === 0 ? (
              isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag and drop AAP files here, or click to select files</p>
              )
            ) : (
              <Box
                style={{ maxHeight: "100%", overflowY: "auto", width: "100%" }}
              >
                {files.map((file, index) => (
                  <Box
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography mr={2}>{file}</Typography>
                    {!loading && (
                      <Button
                        sx={{
                          fontSize: 12,
                          py: 0,
                          px: 0,
                          backgroundColor: "transparent",
                          ":hover": {
                            color: "red",
                            backgroundColor: "transparent",
                          },
                        }}
                        variant="outlined"
                        color="error"
                        onClick={(event: any) => handleRemoveFile(event, file)}
                      >
                        X
                      </Button>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={10}>
          {uploadedFiles.length > 0 && (
            <>
              <Typography variant="h6">Uploaded Files</Typography>
              {uploadedFiles.map((file, index) => (
                <Typography key={index}>{file}</Typography>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default UploadAap;
