import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useRoles } from "useRoles";

function ListItemButtonAuth({
  title,
  icon,
  requiredRoles,
  link,
  onClick,
  sx,
}: {
  title: string;
  icon?: any;
  requiredRoles?: [string];
  link?: string;
  onClick?: () => void;
  sx?: any;
}) {
  const { hasRole } = useRoles();
  const isAuthorized = !requiredRoles || hasRole(requiredRoles);
  return link ? (
    <>
      {isAuthorized && (
        <ListItemButton sx={sx} component={Link} to={link}>
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText primary={title} />
        </ListItemButton>
      )}
    </>
  ) : (
    <>
      {isAuthorized && (
        <ListItemButton sx={sx} onClick={onClick}>
          {icon && <ListItemIcon>{icon}</ListItemIcon>}
          <ListItemText primary={title} />
        </ListItemButton>
      )}
    </>
  );
}
export default ListItemButtonAuth;
