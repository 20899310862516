import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { RacingAPIService } from "Services/RacingAPIService";
import React, { useEffect, useState } from "react";
import { Utilities } from "utils/Utilities";
import CourseDropdown from "./CourseDropdown";
import {
  HorseHistoryItem,
  RaceCard,
  Runner,
  horseStatsType,
} from "./FormingTypes";
import {
  calculateTotalPrizeForFirstRace,
  calculateTotalPrizeForSpecificHorse,
  checkGapBetweenLastTwoRaces,
  checkGapBetweenTomorrowAndLastRace,
  checkTmrwWeight,
  checkWeightDiff,
  countGapsBetweenRaces,
  getLastSixPositions,
  getTomorrowDate,
  hasJockeyRiddenHorseBefore,
  secondUp,
  thirdUp,
} from "./FormingUtils";
import HorseDetails from "./HorseDetails";
import RaceDropdown from "./RaceDropdown";

const Forming = () => {
  const [results, setResults] = useState<RaceCard[]>([]);
  const [distinctCourses, setDistinctCourses] = useState<string[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<string>("");
  const [selectedRaceId, setSelectedRaceId] = useState<string>("");
  const [selectedRaceRunners, setSelectedRaceRunners] = useState<Runner[]>([]);
  const [horseStats, setHorseStats] = useState<horseStatsType>({});
  const [horseHistories, setHorseHistories] = useState<{
    [horseId: string]: HorseHistoryItem[];
  }>({});
  const [selectedHorseId, setSelectedHorseId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    selectedRaceRunners.forEach((runner) => {
      getHorseHistory(runner.horseId);
    });
  }, [selectedRaceRunners]);

  useEffect(() => {
    fetchTomorrowRaceCards();
  }, []);

  const fetchTomorrowRaceCards = async () => {
    setLoading(true);
    try {
      const response = await RacingAPIService.getTomorrowRaceCards();
      const raceCards = response?.data.$values as RaceCard[];
      setResults(raceCards);
      console.log(raceCards, "hello racecards");

      const courses = Array.from(new Set(raceCards.map((race) => race.course)));
      setDistinctCourses(courses);
    } catch (error) {
      console.error("Error fetching results:", error);
    } finally {
      setLoading(false);
    }
  };
  const getHorseHistory = async (horseId: string) => {
    const response = await RacingAPIService.getHorseResults(horseId);
    const horseValuesFromAPI: HorseHistoryItem[] =
      response?.data?.results?.$values ?? [];
    setHorseHistories((prev) => ({ ...prev, [horseId]: horseValuesFromAPI }));

    return horseValuesFromAPI;
  };

  const fetchHorseStats = async (
    runners: Runner[],
    raceDistanceF: number,
    selectedCourse: string,
    racePrize: string,
  ) => {
    const newHorseStats: horseStatsType = {};

    for (const runner of runners) {
      const horseHistory = await getHorseHistory(runner.horseId);
      const lastRace = horseHistory[0];
      const lastRacePosition =
        lastRace?.runners.$values.find(
          (item) => item.horseId === runner.horseId,
        )?.position || "N/A";

      const lastRaceWeight =
        lastRace?.runners.$values.find(
          (item) => item.horseId === runner.horseId,
        )?.weightLbs || "N/A";

      const lastTwoRace = horseHistory[1];
      const lastTwoRacePosition =
        lastTwoRace?.runners.$values.find(
          (item) => item.horseId === runner.horseId,
        )?.position || "N/A";

      const lbs = runner.lbs;
      const horseWeight = parseFloat(
        (parseFloat(runner.lbs) * 0.453592).toFixed(2),
      );

      const hasSameJockeyBefore = hasJockeyRiddenHorseBefore(
        horseHistory,
        runner.jockey,
        runner.horseId,
      );
      const careerHistory = horseHistory.filter((history) => {
        const historyCareer = parseFloat(history.distF);

        return historyCareer;
      });

      const totalRacesAtCareer = careerHistory.length;
      const winsAtCareer = careerHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "1",
        ),
      ).length;
      const secondsAtCareer = careerHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "2",
        ),
      ).length;
      const thirdsAtCareer = careerHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "3",
        ),
      ).length;
      const careerStat = `${totalRacesAtCareer}:${winsAtCareer}.${secondsAtCareer}.${thirdsAtCareer}`;

      const winPerc =
        totalRacesAtCareer > 0 ? winsAtCareer / totalRacesAtCareer : 0;
      const winPlcPerc =
        totalRacesAtCareer > 0
          ? (winsAtCareer + secondsAtCareer + thirdsAtCareer) /
            totalRacesAtCareer
          : 0;
      const filteredDistanceHistory = horseHistory.filter((history) => {
        const historyDistance = parseFloat(history.distM);
        let raceDistanceM = Utilities.convertFurlongsToMeters(raceDistanceF);

        if (historyDistance > 0 && historyDistance < 2500) {
          return Math.abs(historyDistance - raceDistanceM) <= 30;
        }

        if (historyDistance > 2550 && historyDistance < 4400) {
          return Math.abs(historyDistance - raceDistanceM) <= 90;
        }

        if (historyDistance > 4400 && historyDistance < 7000) {
          return Math.abs(historyDistance - raceDistanceM) <= 180;
        }

        return false;
      });

      const totalRacesAtDistance = filteredDistanceHistory.length;
      const winsAtDistance = filteredDistanceHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "1",
        ),
      ).length;

      const secondsAtDistance = filteredDistanceHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "2",
        ),
      ).length;

      const thirdsAtDistance = filteredDistanceHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "3",
        ),
      ).length;

      const distanceStat = `${totalRacesAtDistance}:${winsAtDistance}.${secondsAtDistance}.${thirdsAtDistance}`;

      const filteredTrackHistory = horseHistory.filter(
        (history) => history.course === selectedCourse,
      );

      const totalRacesAtTrack = filteredTrackHistory.length;
      const winsAtTrack = filteredTrackHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "1",
        ),
      ).length;
      const secondsAtTrack = filteredTrackHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "2",
        ),
      ).length;
      const thirdsAtTrack = filteredTrackHistory.filter((race) =>
        race.runners.$values.some(
          (runnerItem) =>
            runnerItem.horseId === runner.horseId &&
            runnerItem.position === "3",
        ),
      ).length;

      const trackStat = `${totalRacesAtTrack}:${winsAtTrack}.${secondsAtTrack}.${thirdsAtTrack}`;

      const filteredTrackAndDistanceHistory = horseHistory.filter((history) => {
        const historyDistance = parseFloat(history.distM);
        let raceDistanceM = Utilities.convertFurlongsToMeters(raceDistanceF);
        return (
          history.course === selectedCourse &&
          Math.abs(historyDistance - raceDistanceM) <= 30
        );
      });

      const totalRacesAtTrackAndDistance =
        filteredTrackAndDistanceHistory.length;
      const winsAtTrackAndDistance = filteredTrackAndDistanceHistory.filter(
        (race) =>
          race.runners.$values.some(
            (runnerItem) =>
              runnerItem.horseId === runner.horseId &&
              runnerItem.position === "1",
          ),
      ).length;

      const secondsAtTrackAndDistance = filteredTrackAndDistanceHistory.filter(
        (race) =>
          race.runners.$values.some(
            (runnerItem) =>
              runnerItem.horseId === runner.horseId &&
              runnerItem.position === "2",
          ),
      ).length;

      const thirdsAtTrackAndDistance = filteredTrackAndDistanceHistory.filter(
        (race) =>
          race.runners.$values.some(
            (runnerItem) =>
              runnerItem.horseId === runner.horseId &&
              runnerItem.position === "3",
          ),
      ).length;
      const trackAndDistanceStat = `${totalRacesAtTrackAndDistance}:${winsAtTrackAndDistance}.${secondsAtTrackAndDistance}.${thirdsAtTrackAndDistance}`;

      const trackConditions = [
        "Firm",
        "Good",
        "Soft",
        "Heavy",
        "Yielding",
        "Good To Firm",
        "Good To Soft",
        "Standard",
        "Standard To Slow",
        "Good To Yielding",
        "Yielding To Soft",
        "Very Soft",
        "Soft To Heavy",
      ];

      const trackConditionStats: { [condition: string]: string } = {};

      for (const condition of trackConditions) {
        const filteredConditionHistory = horseHistory.filter(
          (history) => history.going === condition,
        );

        const totalRacesAtCondition = filteredConditionHistory.length;
        const winsAtCondition = filteredConditionHistory.filter((race) =>
          race.runners.$values.some(
            (runnerItem) =>
              runnerItem.horseId === runner.horseId &&
              runnerItem.position === "1",
          ),
        ).length;

        const secondsAtCondition = filteredConditionHistory.filter((race) =>
          race.runners.$values.some(
            (runnerItem) =>
              runnerItem.horseId === runner.horseId &&
              runnerItem.position === "2",
          ),
        ).length;

        const thirdsAtCondition = filteredConditionHistory.filter((race) =>
          race.runners.$values.some(
            (runnerItem) =>
              runnerItem.horseId === runner.horseId &&
              runnerItem.position === "3",
          ),
        ).length;
        trackConditionStats[condition] =
          `${totalRacesAtCondition}:${winsAtCondition}.${secondsAtCondition}.${thirdsAtCondition}`;
      }
      const headgear = horseHistory
        .find((history) =>
          history.runners.$values.some(
            (item) => item.horseId === runner.horseId,
          ),
        )
        ?.runners.$values.find(
          (item) => item.horseId === runner.horseId,
        )?.headgear;

      const getRacePrice = () => {
        const cleanRacePrize = Number(
          racePrize.replace(/£|€|\$|,/g, "").trim(),
        );
        const horsePrize = calculateTotalPrizeForFirstRace(horseHistory);
        const cleanHorsePrize = Number(
          horsePrize.replace(/£|€|\$|,/g, "").trim(),
        );
        const remainingPrize = cleanRacePrize - cleanHorsePrize;
        return remainingPrize > 0 ? "+" : remainingPrize < 0 ? "-" : "0";
      };

      const gapFromTomorrow = checkGapBetweenTomorrowAndLastRace(
        horseHistory,
        lastRacePosition,
      );
      const gapCountMessage = countGapsBetweenRaces(
        horseHistory,
        runner.horseId,
      );
      const secondUpCount = secondUp(horseHistory, runner.horseId);
      const thirdUpCount = thirdUp(horseHistory, runner.horseId);
      const gapFromLastTwoRaces = checkGapBetweenLastTwoRaces(
        horseHistory,
        lastRacePosition,
        lastTwoRacePosition,
      );

      const weightDiff = checkWeightDiff(lbs, lastRaceWeight);
      const lastWeight = checkTmrwWeight(lbs, lastRaceWeight);

      const totalPrize = calculateTotalPrizeForSpecificHorse(
        runner.horseId,
        horseHistory,
      );
      const lastSixPositions = getLastSixPositions(
        horseHistory,
        runner.horseId,
      );

      newHorseStats[runner.horseId] = {
        distanceStat,
        trackStat,
        trackAndDistanceStat,
        trackConditionStats,
        careerStat,
        hasSameJockeyBefore,
        headgear: headgear ? "HC" : "None",
        totalPrizeFirstRace: getRacePrice(),
        gapFromTomorrow,
        secondUpCount,
        thirdUpCount,
        gapCountMessage,
        gapFromLastTwoRaces,
        lastRacePosition,
        winPerc,
        winPlcPerc,
        lastWeight,
        weightDiff,
        horseWeight,
        totalPrize,
        lastSixPositions,
      };
    }
    setHorseStats(newHorseStats);
  };

  const handleHorseClick = (horseId: string) => {
    setSelectedHorseId(horseId === selectedHorseId ? null : horseId);
  };

  const handleCourseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    const selectedCourse = event.target.value;
    setSelectedCourse(selectedCourse);
    setSelectedRaceId("");
    setSelectedRaceRunners([]);
  };

  const handleRaceIdChange = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    event.preventDefault();
    setSelectedRaceRunners([]);
    const selectedRaceId = event.target.value;
    setSelectedRaceId(selectedRaceId);

    const selectedRace = results.find((race) => race.raceId === selectedRaceId);
    if (selectedRace) {
      setSelectedRaceRunners(selectedRace.runners.$values);
      await fetchHorseStats(
        selectedRace.runners.$values,
        selectedRace.distanceF,
        selectedRace.course,
        selectedRace.prize,
      );
    } else {
      setSelectedRaceRunners([]);
    }
  };

  return (
    <Grid
      item
      xs={12}
      lg={12}
      sx={{ backgroundColor: "darkcyan", height: "100%" }}
    >
      <Grid item xs={12} lg={12}>
        <Typography variant="h4" sx={{ pt: 3, pl: 3 }}>
          Formings
        </Typography>
        <Grid item xs={12} lg={12} sx={{ pt: 3, pl: 6, pb: 3 }}>
          <Box display="flex" gap={2} sx={{ width: "100%" }}>
            <input
              type="text"
              value={getTomorrowDate()}
              readOnly
              style={{ padding: "8px", boxSizing: "border-box" }}
            />
            <CourseDropdown
              selectedCourse={selectedCourse}
              handleCourseChange={handleCourseChange}
              distinctCourses={distinctCourses}
              loading={loading}
            />
            {selectedCourse && (
              <RaceDropdown
                selectedCourse={selectedCourse}
                selectedRaceId={selectedRaceId}
                handleRaceIdChange={handleRaceIdChange}
                results={results}
              />
            )}
          </Box>
        </Grid>

        <Grid>
          {selectedRaceRunners.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ width: "100%", overflowX: "auto", padding: 2 }}>
                <Table
                  style={{
                    backgroundColor: "white",
                    border: "1px solid white",
                  }}
                >
                  <TableHead></TableHead>
                  <TableBody>
                    {selectedRaceRunners.map((runner) => (
                      <React.Fragment key={runner.horseId}>
                        <TableRow style={{ width: "100%" }}>
                          <TableCell
                            onClick={() => handleHorseClick(runner.horseId)}
                            style={{
                              cursor: "pointer",
                              fontWeight: "bold",
                              width: "100%",
                            }}
                            colSpan={100}
                          >
                            {`${runner.number}-${runner.horse}`}
                          </TableCell>
                        </TableRow>
                        {selectedHorseId === runner.horseId && (
                          <HorseDetails
                            runner={runner}
                            selectedCourse={selectedCourse}
                            selectedRaceId={selectedRaceId}
                            results={results}
                            horseStats={horseStats}
                            horseHistories={horseHistories}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Forming;
