import { Typography } from "@mui/material";
import { socket } from "Socket";
import Table from "components/Table/Table";
import Markee from "components/markee/markee";
import { useGeneralContext } from "pages/context/Context";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { getNabPlayersThunk } from "redux/createAsyncThunk";
import { UsersViewcolumns } from "./UsersColumns";

function ViewNabUsers() {
    const dispatch = useDispatch<any>();
    const { getData, setData } = useGeneralContext();
    const location = useLocation();

    const [userData, setUserData] = useState<{ users: any[]; error: boolean }>({
        users: [],
        error: false,
    });
    const [loading, setLoading] = useState(true);
    // const isDataInitialized = useRef(false); // Prevent multiple initial fetches

    // Fetch data once when the component mounts or when the route changes
    useEffect(() => {
        const initializeData = async () => {
            const cachedUserData = await getData<{ users: any[]; error: boolean }>(
                "userNABData",
            );

            if (cachedUserData?.users && cachedUserData?.users?.length > 0) {
                setUserData(cachedUserData);
                setLoading(false);
            } else {
                await fetchUsers();
            }
        };

        const fetchUsers = async () => {
            try {
                setLoading(true);
                const response: any = await dispatch(getNabPlayersThunk());

                if (response.payload?.data?.length > 0) {
                    const data = { users: response.payload.data, error: false };
                    setUserData(data);
                    await setData("userNABData", data);
                } else {
                    handleError();
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                handleError();
            } finally {
                setLoading(false);
            }
        };

        const handleError = async () => {
            const errorData = { users: [], error: true };
            setUserData(errorData);
            await setData("userData", errorData);
        };

        initializeData();
    }, [dispatch, getData, setData, location.pathname]); // Add location.pathname as a dependency

    // Handle real-time updates from the socket
    useEffect(() => {
        const handleSocketData = (data: any) => {
            const { operationType, fullDocument, documentKey, updateDescription } =
                data;
            const updatedUserId = documentKey?._id;

            setUserData((prevUserData) => {
                let updatedUsers = [...prevUserData.users];

                if (operationType === "insert" && fullDocument) {
                    updatedUsers.push(fullDocument);
                } else if (
                    operationType === "update" &&
                    updateDescription?.updatedFields
                ) {
                    updatedUsers = updatedUsers.map((user) =>
                        user._id === updatedUserId
                            ? { ...user, ...updateDescription.updatedFields }
                            : user,
                    );
                } else if (operationType === "delete") {
                    updatedUsers = updatedUsers.filter(
                        (user) => user._id !== updatedUserId,
                    );
                }

                const newUserData = { users: updatedUsers, error: false };
                setData("userNABData", newUserData);
                return newUserData;
            });
        };

        socket.on("userChangeData", handleSocketData);

        return () => {
            socket.off("userChangeData", handleSocketData);
        };
    }, [setData, getData, dispatch]);

    // Memoize columns to prevent unnecessary re-renders
    const memoizedColumns = useMemo(() => UsersViewcolumns, []);

    return (
        <>
            <Typography variant="h4">
                <Markee text="All Users" />
            </Typography>
            {userData.error ? (
                <Typography variant="body1" color="error">
                    Failed to load users. Please try again later.
                </Typography>
            ) : (
                <Table
                    loading={loading}
                    disableRowSelectionOnClick
                    searchable
                    columns={memoizedColumns}
                    rows={userData.users}
                />
            )}
        </>
    );
}

export default React.memo(ViewNabUsers);