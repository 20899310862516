import AdminLayout from "components/Layout/AdminLayout";
import ProtectedRoute from "components/protected-route/ProtectedRoute";
import ProtectedRouteAuthenticated from "components/protected-route/ProtectedRouteAuthenticated";
import ViewAdmins from "pages/Admins/ViewAdmins";
import Bookies from "pages/Controller/Bookies";
import Forming from "pages/Controller/RacingAPi/Forming/Forming";
import GeneratePoints from "pages/Controller/RacingAPi/GeneratePoints/GeneratePoints";
import RacingApi from "pages/Controller/RacingAPi/RacingApi";
import UploadAap from "pages/Controller/UploadAap";
import UploadAapXml from "pages/Controller/UploadAapXml";
import Venues from "pages/Controller/Venues";
import EmailForm from "pages/Emails/Emails";
import Homepage from "pages/Home/Homepage";
import ViewHorse from "pages/Horse/ViewHorse";
import LoginPage from "pages/Login/LoginPage";
import ViewRace from "pages/Race/ViewRace";
import ViewNabUsers from "pages/Users/ViewNabUsers";
import ViewUser from "pages/Users/ViewUser";
import ViewUsers from "pages/Users/ViewUsers";
import ViewVenue from "pages/venue/ViewVenue";
import React from "react";
import { RouterProvider, createHashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const router = createHashRouter([
  {
    path: "/",
    element: <AdminLayout />,
    children: [
      {
        index: true,
        path: "/",
        element: (
          <ProtectedRoute>
            <Homepage />
          </ProtectedRoute>
        ),
      },
      {
        path: "users/view",
        element: (
          <ProtectedRoute>
            <ViewUsers />
          </ProtectedRoute>
        ),
      },
      {
        path: "users/view/:id",
        element: (
          <ProtectedRoute>
            <ViewUser />
          </ProtectedRoute>
        ),
      },
      {
        path: "users/admin/view",
        element: (
          <ProtectedRoute>
            <ViewAdmins />
          </ProtectedRoute>
        ),
      },
      {
        path: "users/emails",
        element: (
          <ProtectedRoute>
            <EmailForm />
          </ProtectedRoute>
        ),
      },
      {
        path: "users/nab",
        element: (
          <ProtectedRoute>
            <ViewNabUsers />
          </ProtectedRoute>
        ),
      },
      {
        path: "controller/venues",
        element: (
          <ProtectedRoute>
            <Venues />
          </ProtectedRoute>
        ),
      },
      {
        path: "/controller/venues/edit/:venueId/:date",
        element: (
          <ProtectedRoute>
            <ViewVenue />
          </ProtectedRoute>
        ),
      },
      {
        path: "/controller/venues/edit/:venueId/:date/:raceId",
        element: (
          <ProtectedRoute>
            <ViewRace />
          </ProtectedRoute>
        ),
      },
      {
        path: "/controller/venues/edit/:venueId/:date/:raceId/:horseId",
        element: (
          <ProtectedRoute>
            <ViewHorse />
          </ProtectedRoute>
        ),
      },
      {
        path: "controller/bookies",
        element: (
          <ProtectedRoute>
            <Bookies />
          </ProtectedRoute>
        ),
      },
      {
        path: "controller/upload-aap",
        element: (
          <ProtectedRoute>
            <UploadAap />
          </ProtectedRoute>
        ),
      },
      {
        path: "controller/upload-aap-xml",
        element: (
          <ProtectedRoute>
            <UploadAapXml />
          </ProtectedRoute>
        ),
      },
      {
        path: "controller/racing-api",
        element: (
          <ProtectedRoute>
            <RacingApi />
          </ProtectedRoute>
        ),
      },
      {
        path: "controller/generate-points",
        element: (
          <ProtectedRoute>
            <GeneratePoints />
          </ProtectedRoute>
        ),
      },
      {
        path: "controller/forming",
        element: (
          <ProtectedRoute>
            <Forming />
          </ProtectedRoute>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: (
      <ProtectedRouteAuthenticated>
        <LoginPage />
      </ProtectedRouteAuthenticated>
    ),
  },
]);

const AppRoutes = () => {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
};

export default AppRoutes;
