import { HorseHistoryItem, RunnerItem } from "./FormingTypes";

export const checkWeightDiff = (
  lbs: string,
  lastRaceWeight: string,
): string => {
  const currentWeightLbs = parseInt(lbs, 10);
  const lastRaceWeightLbs = parseInt(lastRaceWeight, 10);

  if (isNaN(currentWeightLbs) || isNaN(lastRaceWeightLbs)) {
    return "";
  }

  const lbsToKgFactor = 0.453592;
  const currentWeightKg = currentWeightLbs * lbsToKgFactor;
  const lastRaceWeightKg = lastRaceWeightLbs * lbsToKgFactor;

  const weightDifferenceKg = currentWeightKg - lastRaceWeightKg;

  if (weightDifferenceKg > 0) {
    return `UP(${weightDifferenceKg.toFixed(1)}kg)`;
  } else if (weightDifferenceKg < 0) {
    return `DN(${Math.abs(weightDifferenceKg).toFixed(1)}kg)`;
  } else {
    return ``;
  }
};

export const checkTmrwWeight = (
  lbs: string,
  lastRaceWeight: string,
): string => {
  const currentWeightLbs = parseInt(lbs, 10);
  const lastRaceWeightLbs = parseInt(lastRaceWeight, 10);

  if (isNaN(currentWeightLbs) || isNaN(lastRaceWeightLbs)) {
    return " No data";
  }

  const lbsToKgFactor = 0.453592;
  const currentWeightKg = currentWeightLbs * lbsToKgFactor;
  const lastRaceWeightKg = lastRaceWeightLbs * lbsToKgFactor;

  const weightDifferenceKg = currentWeightKg - lastRaceWeightKg;

  if (weightDifferenceKg > 0) {
    return `+${weightDifferenceKg.toFixed(2)}kg`;
  } else if (weightDifferenceKg < 0) {
    return `-${Math.abs(weightDifferenceKg).toFixed(2)}kg`;
  } else {
    return "=";
  }
};

export const calculateTotalPrizeForSpecificHorse = (
  horseId: string,
  horseHistory: HorseHistoryItem[],
): number => {
  if (!horseHistory || horseHistory.length === 0) {
    return 0;
  }

  let totalPrize = 0;

  horseHistory.forEach((race) => {
    const horseRunner = race.runners.$values.find(
      (runner) => runner.horseId === horseId,
    );

    if (horseRunner && horseRunner.prize) {
      const prizeValue = parseFloat(
        horseRunner.prize
          .toString()
          .replace(/£|€|\$|,/g, "")
          .trim(),
      );

      if (!isNaN(prizeValue)) {
        totalPrize += prizeValue;
      }
    }
  });

  return totalPrize;
};

export const checkGapBetweenLastTwoRacesAndBefore = (
  horseHistory: HorseHistoryItem[],
  lastTwoRacePosition: string,
  lastThirdRacePosition: string,
): string => {
  const lastThreeDates = getLastThreeRaceDates(horseHistory);

  if (lastThreeDates.length >= 3) {
    const secondMostRecentRaceDate = new Date(lastThreeDates[1]);
    const thirdMostRecentRaceDate = new Date(lastThreeDates[2]);

    const diffBetweenTwoAndThreeDates = Math.abs(
      secondMostRecentRaceDate.getTime() - thirdMostRecentRaceDate.getTime(),
    );
    const diffDaysBetweenTwoAndThreeDates =
      diffBetweenTwoAndThreeDates / (1000 * 3600 * 24);

    const isNotNumber = (value: string) => isNaN(Number(value));

    let adjustedLastTwoRacePosition =
      isNotNumber(lastTwoRacePosition) || parseInt(lastTwoRacePosition, 10) > 9
        ? 0
        : parseInt(lastTwoRacePosition, 10);
    let adjustedThirdRacePosition =
      isNotNumber(lastThirdRacePosition) ||
      parseInt(lastThirdRacePosition, 10) > 9
        ? 0
        : parseInt(lastThirdRacePosition, 10);

    return diffDaysBetweenTwoAndThreeDates >= 60
      ? adjustedThirdRacePosition.toString() +
          "X" +
          adjustedLastTwoRacePosition.toString()
      : "-";
  }

  return "";
};

const getLastThreeRaceDates = (horseHistory: HorseHistoryItem[]): string[] => {
  const sortedHorseHistory = horseHistory.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );
  const lastThreeDates = sortedHorseHistory
    .slice(0, 3)
    .map((history) => history.date);
  return lastThreeDates;
};

export const checkGapBetweenTomorrowAndLastRace = (
  horseHistory: HorseHistoryItem[],
  lastRacePosition: string,
): string => {
  const tomorrowDate = new Date(getTomorrowDate());
  const lastTwoDates = getLastTwoRaceDates(horseHistory);

  if (lastTwoDates.length > 0) {
    const mostRecentRaceDate = new Date(lastTwoDates[0]);
    const diffFromTomorrow = Math.abs(
      tomorrowDate.getTime() - mostRecentRaceDate.getTime(),
    );
    const diffDaysFromTomorrow = diffFromTomorrow / (1000 * 3600 * 24);

    const isNotNumber = (value: string) => isNaN(Number(value));
    let adjustedRacePosition =
      isNotNumber(lastRacePosition) || parseInt(lastRacePosition, 10) > 9
        ? 0
        : parseInt(lastRacePosition, 10);

    return diffDaysFromTomorrow >= 60
      ? adjustedRacePosition.toString() + "X"
      : "-";
  }

  return "";
};

export const getTomorrowDate = (): string => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  return tomorrow.toISOString().split("T")[0];
};

export const getLastTwoRaceDates = (
  horseHistory: HorseHistoryItem[],
): string[] => {
  const sortedHorseHistory = horseHistory.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );
  const lastTwoDates = sortedHorseHistory
    .slice(0, 2)
    .map((history) => history.date);
  return lastTwoDates;
};

export const checkGapBetweenLastTwoRaces = (
  horseHistory: HorseHistoryItem[],
  lastRacePosition: string,
  lastTwoRacePosition: string,
): string => {
  const lastTwoDates = getLastTwoRaceDates(horseHistory);

  if (lastTwoDates.length === 2) {
    const mostRecentRaceDate = new Date(lastTwoDates[0]);
    const secondMostRecentRaceDate = new Date(lastTwoDates[1]);
    const diffBetweenTwoDates = Math.abs(
      mostRecentRaceDate.getTime() - secondMostRecentRaceDate.getTime(),
    );
    const diffDaysBetweenTwoDates = diffBetweenTwoDates / (1000 * 3600 * 24);
    const isNotNumber = (value: string) => isNaN(Number(value));
    let adjustedLastRacePosition =
      isNotNumber(lastRacePosition) || parseInt(lastRacePosition, 10) > 9
        ? 0
        : parseInt(lastRacePosition, 10);
    let adjustedLastTwoRacePosition =
      isNotNumber(lastTwoRacePosition) || parseInt(lastTwoRacePosition, 10) > 9
        ? 0
        : parseInt(lastTwoRacePosition, 10);

    return diffDaysBetweenTwoDates >= 60
      ? adjustedLastTwoRacePosition.toString() +
          "X" +
          adjustedLastRacePosition.toString()
      : "-";
  }
  return "";
};

export const hasJockeyRiddenHorseBefore = (
  horseHistory: HorseHistoryItem[],
  jockeyName: string,
  horseId: string,
): boolean => {
  return horseHistory.some((race) =>
    race.runners.$values.some(
      (runnerItem) =>
        runnerItem.horseId === horseId && runnerItem.jockey === jockeyName,
    ),
  );
};

export const calculateTotalPrizeForFirstRace = (
  horseHistory: HorseHistoryItem[],
): string => {
  if (horseHistory.length === 0) {
    return "0";
  }
  const sortedHorseHistory = horseHistory.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );
  const firstRace = sortedHorseHistory[0];
  let totalPrize = 0;
  for (const runnerItem of firstRace.runners.$values) {
    const prize = runnerItem.prize;
    totalPrize +=
      Number(
        prize
          .toString()
          .replace(/£|€|\$|,/g, "")
          .trim(),
      ) || 0;
  }

  return `${totalPrize}`;
};
export const getOrdinal = (num: string) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const number = parseInt(num, 10);
  const value = number % 100;

  return (
    number + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0])
  );
};

export const convertTimeToSeconds = (time: string): number => {
  const timeParts = time.split(":").map(Number);
  let seconds = 0;
  if (timeParts.length === 2) {
    seconds = timeParts[0] * 60 + timeParts[1];
  } else if (timeParts.length === 3) {
    seconds = timeParts[0] * 3600 + timeParts[1] * 60 + timeParts[2];
  }
  return seconds;
};

export const getRaceTimesForPositionOne = (
  horseHistory: HorseHistoryItem[],
): (string | null)[] => {
  return horseHistory.map((historyItem) => {
    const positionOneHorse = historyItem.runners.$values.find(
      (runnerItem) => runnerItem.position === "1",
    );
    return positionOneHorse ? positionOneHorse.time.toString() : null;
  });
};

export const showPrizeCurrencySymbol = (region: string) => {
  switch (region) {
    case "GB":
      return "£";

    case "IRE":
      return "€";
    default:
      return "$";
  }
};
export const getLastSixPositions = (
  horseHistory: HorseHistoryItem[],
  horseId: string,
): string[] => {
  const sortedHorseHistory = horseHistory.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );

  const horseRaces = sortedHorseHistory
    .filter((race) =>
      race.runners.$values.some((runnerItem) => runnerItem.horseId === horseId),
    )
    .map(
      (race) =>
        race.runners.$values.find(
          (runnerItem) => runnerItem.horseId === horseId,
        )?.position,
    )
    .filter((position): position is string => position !== undefined);
  return horseRaces.slice(0, 6).reverse();
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0"); // Ensure two digits
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export function formatWeight(weight: string) {
  const parsedWeight = parseFloat(weight);
  const integerPart = Math.floor(parsedWeight);
  const decimalPart = parsedWeight - integerPart;

  if (decimalPart >= 0 && decimalPart < 0.5) {
    return integerPart.toFixed(0);
  } else if (decimalPart >= 0.5 && decimalPart < 0.6) {
    return (integerPart + 0.5).toFixed(1);
  } else if (decimalPart >= 0.6 && decimalPart < 1) {
    return integerPart.toFixed(0);
  }
  return parsedWeight.toFixed(1);
}
export const formatHorseWeight = (weight: number | undefined): string => {
  if (weight === undefined || weight === null) return "";
  if (weight % 1 === 0.5) {
    return weight.toFixed(1);
  }
  return Math.floor(weight).toString();
};

export const calculateHistoryTotalPrize = (runners: RunnerItem[]) => {
  let historyTotalPrize = 0;
  runners.forEach((runner) => {
    const cleanedPrize = Number(String(runner.prize).replace(/[$,£,€]/g, ""));
    historyTotalPrize += cleanedPrize;
  });
  return historyTotalPrize.toFixed(2);
};

export const secondUp = (
  horseHistory: HorseHistoryItem[],
  horseId: string,
): string => {
  if (horseHistory.length < 2) {
    return "--";
  }

  let gapCount = 0;
  let gapRaces: HorseHistoryItem[] = [];
  let nextRacePositions: string[] = [];
  let nextRacePositionCounts = { first: 0, second: 0, third: 0 };
  let positionsCount = { first: 0, second: 0, third: 0 };
  const sortedHorseHistory = horseHistory.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );
  const firstRaceDate = new Date(sortedHorseHistory[0]?.date);
  const secondRaceDate = new Date(sortedHorseHistory[1]?.date);
  let addSecondUpRace = 0;

  const initialGapDiff =
    Math.abs(secondRaceDate.getTime() - firstRaceDate.getTime()) /
    (1000 * 3600 * 24);

  if (initialGapDiff < 60) {
    const secondRace = sortedHorseHistory[1];
    const secondRaceRunner = secondRace.runners.$values.find(
      (runner) => runner.horseId === horseId,
    );

    if (secondRaceRunner) {
      if (secondRaceRunner.position === "1") {
        positionsCount.first++;
      }
      if (secondRaceRunner.position === "2") {
        positionsCount.second++;
      }
      if (secondRaceRunner.position === "3") {
        positionsCount.third++;
      }
      addSecondUpRace = 1;
    }
  }

  for (let i = 1; i < sortedHorseHistory.length; i++) {
    const currentDate = new Date(horseHistory[i].date);
    const previousDate = new Date(horseHistory[i - 1].date);

    const diffFromPrevious = Math.abs(
      currentDate.getTime() - previousDate.getTime(),
    );
    const diffDays = diffFromPrevious / (1000 * 3600 * 24);

    if (diffDays >= 60) {
      gapCount++;
      gapRaces.push(horseHistory[i]);

      let validGap = true;
      for (let j = 1; j <= 1; j++) {
        if (i + j < horseHistory.length) {
          const nextRaceDate = new Date(horseHistory[i + j].date);
          const prevRaceDate = new Date(horseHistory[i + j - 1].date);

          const gapBetweenRaces =
            (nextRaceDate.getTime() - prevRaceDate.getTime()) /
            (1000 * 60 * 60 * 24);
          if (gapBetweenRaces >= 60) {
            validGap = false;
            break;
          }
        }
      }

      if (validGap && i + 1 < horseHistory.length) {
        const nextRace = horseHistory[i + 1];
        const nextRunner = nextRace.runners.$values.find(
          (runner) => runner.horseId === horseId,
        );

        if (nextRunner) {
          nextRacePositions.push(nextRunner.position);

          if (nextRunner.position === "1") {
            nextRacePositionCounts.first++;
          } else if (nextRunner.position === "2") {
            nextRacePositionCounts.second++;
          } else if (nextRunner.position === "3") {
            nextRacePositionCounts.third++;
          }
        }
      }
    }
  }

  const totalNextRacePositions = nextRacePositions.length + addSecondUpRace;
  return gapCount > 0
    ? `${totalNextRacePositions}:${nextRacePositionCounts.first + positionsCount.first}-${nextRacePositionCounts.second + positionsCount.second}-${nextRacePositionCounts.third + positionsCount.third}`
    : "--";
};

export const thirdUp = (
  horseHistory: HorseHistoryItem[],
  horseId: string,
): string => {
  let gapCount = 0;
  let nextRacePositions: string[] = [];
  let nextRacePositionCounts = { first: 0, second: 0, third: 0 };
  let positionsCount = { first: 0, second: 0, third: 0 };

  if (horseHistory.length < 2) {
    console.warn("Not enough race history to calculate gaps.");
    return "--";
  }

  const sortedHorseHistory = horseHistory.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );
  const firstRaceDate = new Date(sortedHorseHistory[0].date);
  const secondRaceDate = new Date(sortedHorseHistory[1].date);
  const thirdRaceDate = new Date(sortedHorseHistory[2]?.date); // Safe access to avoid error if undefined
  let addThirdUpRace = 0;
  const initialGapDiff =
    Math.abs(secondRaceDate.getTime() - firstRaceDate.getTime()) /
    (1000 * 3600 * 24);
  const secondGapDiff =
    Math.abs(thirdRaceDate?.getTime() - secondRaceDate.getTime()) /
    (1000 * 3600 * 24);

  if (initialGapDiff < 60 && secondGapDiff < 60 && sortedHorseHistory[2]) {
    const thirdRace = sortedHorseHistory[2];
    if (thirdRace && thirdRace.runners) {
      const thirdRaceRunner = thirdRace.runners.$values?.find(
        (runner) => runner.horseId === horseId,
      );
      if (thirdRaceRunner) {
        if (thirdRaceRunner.position === "1") {
          positionsCount.first++;
        } else if (thirdRaceRunner.position === "2") {
          positionsCount.second++;
        } else if (thirdRaceRunner.position === "3") {
          positionsCount.third++;
        }
        addThirdUpRace = 1;
      }
    }
  }

  for (let i = 1; i < sortedHorseHistory.length; i++) {
    const currentDate = new Date(sortedHorseHistory[i].date);
    const previousDate = new Date(sortedHorseHistory[i - 1].date);
    const diffFromPrevious = Math.abs(
      currentDate.getTime() - previousDate.getTime(),
    );
    let diffDays = diffFromPrevious / (1000 * 3600 * 24);

    if (diffDays >= 60) {
      gapCount++;
      let validGap = true;
      for (let j = 1; j <= 2; j++) {
        if (i + j < sortedHorseHistory.length) {
          const currentDate = new Date(sortedHorseHistory[i + j].date);
          const previousDate = new Date(sortedHorseHistory[i + j - 1].date);
          const gapBetweenRaces =
            (currentDate.getTime() - previousDate.getTime()) /
            (1000 * 60 * 60 * 24);
          if (gapBetweenRaces >= 60) {
            validGap = false;
            break;
          }
        }
      }

      if (validGap && i + 2 < sortedHorseHistory.length) {
        const nextRace = sortedHorseHistory[i + 2];
        if (nextRace && nextRace.runners) {
          const nextRunner = nextRace.runners.$values?.find(
            (runner) => runner.horseId === horseId,
          );
          if (nextRunner) {
            nextRacePositions.push(nextRunner.position);
            if (nextRunner.position === "1") {
              nextRacePositionCounts.first++;
            } else if (nextRunner.position === "2") {
              nextRacePositionCounts.second++;
            } else if (nextRunner.position === "3") {
              nextRacePositionCounts.third++;
            }
          }
        }
      }
    }
  }

  const totalNextRacePositions = nextRacePositions.length + addThirdUpRace;
  return gapCount > 0
    ? `${totalNextRacePositions}:${nextRacePositionCounts.first + positionsCount.first}-${nextRacePositionCounts.second + positionsCount.second}-${nextRacePositionCounts.third + positionsCount.third}`
    : "--";
};

export const countGapsBetweenRaces = (
  horseHistory: HorseHistoryItem[],
  horseId: string,
): string => {
  let gapCount = 1;
  let gapRaces = [];
  let positionCounts = { first: 0, second: 0, third: 0 };
  let firstRacePositionCounts = { first: 0, second: 0, third: 0 };
  horseHistory.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  if (horseHistory.length === 0) {
    console.warn("Not enough race history to calculate gaps.");
    return "--";
  }
  if (horseHistory.length > 0) {
    const firstRace = horseHistory[0];
    const firstRunner = firstRace.runners.$values.find(
      (runner) => runner.horseId === horseId,
    );

    if (firstRunner) {
      if (firstRunner.position === "1") {
        firstRacePositionCounts.first++;
      } else if (firstRunner.position === "2") {
        firstRacePositionCounts.second++;
      } else if (firstRunner.position === "3") {
        firstRacePositionCounts.third++;
      }
    }
  }

  for (let i = 1; i < horseHistory.length; i++) {
    const currentDate = new Date(horseHistory[i].date);
    const previousDate = new Date(horseHistory[i - 1].date);
    const diffFromPrevious = Math.abs(
      currentDate.getTime() - previousDate.getTime(),
    );
    const diffDays = diffFromPrevious / (1000 * 3600 * 24);
    if (diffDays >= 60) {
      gapCount++;
      gapRaces.push(horseHistory[i]);
    }
  }

  gapRaces.forEach((item) => {
    item.runners?.$values?.forEach((runner) => {
      if (runner.horseId === horseId) {
        const position = runner.position;
        if (position === "1") {
          positionCounts.first++;
        } else if (position === "2") {
          positionCounts.second++;
        } else if (position === "3") {
          positionCounts.third++;
        }
      }
    });
  });

  return gapCount > 0
    ? `${gapCount}:${positionCounts.first + firstRacePositionCounts.first}-${positionCounts.second + firstRacePositionCounts.second}-${positionCounts.third + firstRacePositionCounts.third}`
    : "--";
};
