import { Box } from "@mui/material";
import { roleTypes } from "Helpers/role-types.enum";
import DynamicButton from "components/dynamic-button/DynamicButton";
import dayjs from "dayjs";
import React from "react";

export const AdminViewColumns = [
  {
    field: "firstName",
    headerName: "First name",
    width: 150,
  },
  {
    field: "lastName",
    headerName: "Last name",
    width: 150,
  },
  {
    field: "email",
    headerName: "Email",
    width: 250,
  },
  {
    field: "userCreatedAt",
    headerName: "Creation date",
    width: 250,
    renderCell: (row: any) => {
      return dayjs(row.row.userCreatedAt).format("YYYY-MM-DD");
    },
  },
  {
    field: "country",
    headerName: "Country",
    width: 150,
  },
  {
    field: "region",
    headerName: "region",
    width: 150,
  },
  {
    field: "roles",
    headerName: "Current Roles",
    width: 250,
    renderCell: (row: any) => {
      return row.row?.roles?.map((role: any) => role.name).join(", ");
    },
  },
  {
    field: "actions",
    type: "actions",
    renderCell: (row: any) => {
      return (
        <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
          <DynamicButton
            state={{ user: row.row }}
            href={`/users/view/${row.row._id}`}
            label={`View`}
            requiredRoles={[roleTypes.ADMIN_PERMISSION]}
          />
        </Box>
      );
    },
  },
];
