import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DATE_YEAR_MONTH_DAY, Mobile } from "Helpers/Constants";
import { raceResults } from "Helpers/race-results-types.enum";
import {
  getRaceAvailabilityLabelFromValue,
  raceStatusAvailabilityMapping,
} from "Helpers/race-status-availability-types.enum";
import { raceStatusMapping } from "Helpers/race-status-types";
import { raceTrackConditionMapping } from "Helpers/race-track-condition-types";
import { UserService } from "Services/UserService";
import SkeletonLoading from "components/Skeleton/SkeletonLoading";
import { ShortErrorToast, ShortSuccessToast } from "components/Toast/Toastify";
import DynamicButton from "components/dynamic-button/DynamicButton";
import SelectDropdown from "components/select/Select";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

function ViewRace() {
  const location = useLocation();
  const initialRace = location.state || "";

  const [isEditable, setIsEditable] = useState(false);
  const [race, setRace] = useState(initialRace);
  const [raceHorses, setRaceHorses] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [openResultModal, setOpenResultModal] = useState(false);
  const [resultSelection, setResultSelection] = useState({
    first: "",
    second: "",
    third: "",
  });
  const params = useParams();
  const raceId = params.raceId;

  const navigate = useNavigate();

  const checkForDeleteHorseConfirmation = (horse: any) => {
    if (window.confirm("Are you sure you want to delete this horse?")) {
      deleteHorse(horse);
    }
  };

  const deleteHorse = async (horse: any) => {
    try {
      const res = await UserService.deleteHorse(horse._id);
      if (res?.data.statusCode === 201) {
        fetchHorses();
        return ShortSuccessToast({ message: res.data.message });
      }
      return ShortErrorToast({ message: "Failed to delete horse" });
    } catch (error) {
      console.log("Error while deleting horse", error);
    }
  };

  const fetchHorses = useCallback(async () => {
    setLoading(true);
    try {
      const res = await UserService.getHorsesForRace(raceId);
      if (res?.data.statusCode === 201) {
        return setRaceHorses(res?.data.data);
      }
      return ShortErrorToast({ message: "Failed to Fetch Horses" });
    } catch (error) {
      console.log("Error while fetching horses", error);
    } finally {
      setLoading(false);
    }
  }, [raceId]);

  useEffect(() => {
    fetchHorses();
  }, [fetchHorses]);

  const handleSave = async () => {
    try {
      const res = await UserService.editRaceInformation(race);
      console.log(res);
      if (res?.data.statusCode === 201) {
        setRace(res?.data.data);
        return ShortSuccessToast({ message: res.data.message });
      }
      return ShortErrorToast({
        message: "An error occurred, please try again later",
      });
    } catch (error) {
      console.log("Error while updating race", error);
    } finally {
      setIsEditable(false);
    }
  };

  const handleResultSave = async () => {
    try {
      // Convert position from string to integer
      const positionMap = { first: 1, second: 2, third: 3 };

      const formattedResults = Object.entries(resultSelection).map(([position, rideId]) => ({
        position: positionMap[position as keyof typeof positionMap],
        rideId,
      }));

      // Send the data to the backend
      const res = await UserService.saveRaceResults(formattedResults);

      if (res?.statusCode === 200 || res?.statusCode === 201) {
        ShortSuccessToast({ message: res.message });
        fetchHorses();
      } else {
        ShortErrorToast({ message: "Failed to save race results" });
      }
    } catch (error) {
      console.error("Error while saving race results", error);
      ShortErrorToast({ message: "An error occurred, please try again later" });
    }
  };

  const handleEdit = () => {
    setRace(initialRace);
    setIsEditable(!isEditable);
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | { name: string; value: unknown }
    >,
  ) => {
    const { name, value } = e.target as { name: string; value: unknown };
    console.log(name, value);
    if (name === "date") {
      setRace((prev: any) => ({
        ...prev,
        dateTimeUtc: dayjs(value as string).toISOString(),
      }));
    } else if (name === "time") {
      const newDateTime = new Date(race.dateTimeUtc);
      const [hours, minutes] = (value as string).split(":");
      newDateTime.setUTCHours(Number(hours), Number(minutes));
      setRace((prev: any) => ({
        ...prev,
        dateTimeUtc: newDateTime.toISOString(),
      }));
    } else if (name === "raceAvailability") {
      const selectedStatus = raceStatusAvailabilityMapping.find(
        (status) => status.value === value,
      );
      if (selectedStatus) {
        setRace((prev: any) => ({
          ...prev,
          [name]: selectedStatus.value,
        }));
      }
    } else {
      setRace((prev: any) => ({ ...prev, [name]: value }));
    }
  };

  const getDateValue = (dateString: string) => {
    const date = dayjs(dateString);
    return date.format("YYYY-MM-DD");
  };

  const getTimeValue = (dateString: string) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours().toString().padStart(2, "0");
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const style = {
    TextFieldRace: {
      "& .Mui-disabled": {
        WebkitTextFillColor: "darkcyan !important",
        color: "darkcyan !important",
        textAlign: "center",
      },
      WebkitTextFillColor: "darkslategray !important",
      color: "darkslategray !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "black",
        },
        "&:hover fieldset": {
          borderColor: "black",
        },
        "&.Mui-focused fieldset": {
          borderColor: "black",
        },
      },

      ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "1px solid darkcyan",
      },
      "&:not(.Mui-disabled) .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "1px solid darkcyan",
      },
    },

    TextField: {
      "& .Mui-disabled": {
        WebkitTextFillColor: "darkcyan !important",
        color: "darkcyan !important",
      },
      WebkitTextFillColor: "darkslategray !important",
      color: "darkslategray !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "black",
        },
        "&:hover fieldset": {
          borderColor: "black",
        },
        "&.Mui-focused fieldset": {
          borderColor: "black",
        },
      },

      ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "1px solid darkcyan",
      },
      "&:not(.Mui-disabled) .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "1px solid darkcyan",
      },
    },
  };

  const goToViewHorse = (horse: any) => {
    if (horse._id)
      return navigate(`${location.pathname}/${horse._id}`, { state: horse });
    return;
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        display="flex"
        sx={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography gutterBottom variant={Mobile() ? "h6" : "h4"} width="100%">
          Race Details
        </Typography>

        {isEditable ? (
          <>
            <DynamicButton
              label="Save"
              color="darkcyan"
              width={100}
              onClick={handleSave}
            />
            <DynamicButton
              label="Cancel"
              color="red"
              width={100}
              onClick={handleEdit}
            />
          </>
        ) : (
          <>
            <DynamicButton
              label="Edit"
              color="darkcyan"
              width={100}
              onClick={handleEdit}
            />
            <DynamicButton
              label="Back"
              color="black"
              width={100}
              onClick={() => window.history.back()}
            />
            <DynamicButton
              label="Result this Race"
              color="white"
              backgroundColor="darkcyan"
              width={180}
              onClick={() => setOpenResultModal(true)}
            />
          </>
        )}
      </Box>
      <Grid container alignItems={"center"} mt={4} spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Number"
            variant="outlined"
            name="sequence"
            value={race.sequence || ""}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {!isEditable ? (
            <TextField
              sx={style.TextField}
              fullWidth
              label="PTP Result"
              variant="outlined"
              name="ptpResult"
              value={race?.ptpResult || ""}
              disabled={!isEditable}
            />
          ) : (
            <SelectDropdown
              name="ptpResult"
              label="PTP Result"
              value={race?.ptpResult || ""}
              onChange={handleChange}
              items={raceResults}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="PTP Result Number"
            variant="outlined"
            name="ptpResultSequence"
            value={race?.ptpResultSequence || ""}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Name"
            variant="outlined"
            name="name"
            value={race?.name || ""}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Class"
            variant="outlined"
            name="classes"
            value={race?.classes?.join(", ") || ""}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {!isEditable ? (
            <TextField
              sx={style.TextField}
              disabled={true}
              id="date"
              name="date"
              label="Date"
              fullWidth
              autoComplete="date"
              variant="outlined"
              onChange={handleChange}
              value={dayjs(race.dateTimeUtc).format(DATE_YEAR_MONTH_DAY)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <TextField
              sx={style.TextField}
              fullWidth
              type="date"
              label="Date"
              variant="outlined"
              name="date"
              value={getDateValue(race.dateTimeUtc)}
              onChange={handleChange}
              disabled={false}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            type="time"
            label="Time"
            variant="outlined"
            name="time"
            value={getTimeValue(race.dateTimeUtc)}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Distance"
            variant="outlined"
            name="distance"
            value={race.distance || ""}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Result Status"
            variant="outlined"
            name="resultStatus"
            value={race.resultStatus || ""}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6} display="flex" alignItems={"center"} gap={2}>
          {!isEditable ? (
            <TextField
              sx={style.TextField}
              fullWidth
              label="Status"
              variant="outlined"
              name="status"
              value={race?.status || ""}
              disabled={!isEditable}
            />
          ) : (
            <SelectDropdown
              name="status"
              onChange={handleChange}
              value={race?.status || ""}
              label="Select race status"
              items={raceStatusMapping}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Race Key Unibet"
            variant="outlined"
            name="raceKeyUnibet"
            value={race.raceKeyUniBet || ""}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Race Key Ladbrokes"
            variant="outlined"
            name="raceKeyLadBrokes"
            value={race.raceKeyLadBrokes || ""}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6} display="flex" alignItems={"center"} gap={2}>
          {!isEditable ? (
            <TextField
              sx={style.TextField}
              fullWidth
              label="Track Condition"
              variant="outlined"
              name="trackCondition"
              value={race?.trackCondition?.toUpperCase() || ""}
              disabled={!isEditable}
            />
          ) : (
            <SelectDropdown
              name="trackCondition"
              onChange={handleChange}
              value={race?.trackCondition || ""}
              label="Select race track condition"
              items={raceTrackConditionMapping}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Track Weather"
            variant="outlined"
            name="trackWeather"
            value={race.trackWeather?.toUpperCase() || ""}
            onChange={handleChange}
            disabled={!isEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6} display="flex" alignItems={"center"} gap={2}>
          {!isEditable ? (
            <TextField
              sx={style.TextField}
              fullWidth
              label="Race Availability"
              variant="outlined"
              name="raceAvailability"
              value={
                getRaceAvailabilityLabelFromValue(race?.raceAvailability) || ""
              }
              disabled={!isEditable}
            />
          ) : (
            <SelectDropdown
              name="raceAvailability"
              disabled={!isEditable}
              onChange={(e) =>
                handleChange({
                  target: { name: "raceAvailability", value: e.target.value },
                } as React.ChangeEvent<{ name: string; value: unknown }>)
              }
              value={race.raceAvailability || ""}
              label="Select race availability status"
              items={raceStatusAvailabilityMapping}
            />
          )}
        </Grid>
      </Grid>
      {loading ? (
        <SkeletonLoading skeletonCardCount={3} size="big" />
      ) : (
        raceHorses.length > 0 &&
        raceHorses.map((horse, key) => (
          <Card key={key} sx={{ mt: 4 }}>
            <CardContent>
              <Grid container spacing={2} alignItems={"center"}>
                <Grid item xs={6} lg={2}>
                  <TextField
                    sx={style.TextFieldRace}
                    fullWidth
                    label=""
                    variant="outlined"
                    name="key"
                    value={key + 1 || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <TextField
                    sx={style.TextFieldRace}
                    fullWidth
                    label="Name"
                    variant="outlined"
                    name="name"
                    value={horse.name || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} lg={2}>
                  <TextField
                    sx={style.TextFieldRace}
                    fullWidth
                    label="Track status"
                    variant="outlined"
                    name="TRACKSTAT"
                    value={horse.TRACKSTAT || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} lg={1}>
                  <TextField
                    sx={style.TextFieldRace}
                    fullWidth
                    label="Sex"
                    variant="outlined"
                    name="SEX"
                    value={horse.SEX || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} lg={1}>
                  <TextField
                    sx={style.TextFieldRace}
                    fullWidth
                    label="Barrier"
                    variant="outlined"
                    name="BARRIER"
                    value={horse.BARRIER || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} lg={1}>
                  <DynamicButton
                    label="View"
                    backgroundColor="darkcyan"
                    color="white"
                    width={"100%"}
                    onClick={() => goToViewHorse(horse)}
                  />
                </Grid>
                <Grid item xs={12} lg={1}>
                  <DynamicButton
                    label="Delete"
                    backgroundColor="red"
                    color="white"
                    width={"100%"}
                    onClick={() => checkForDeleteHorseConfirmation(horse)}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))
      )}
      <Dialog fullWidth open={openResultModal} onClose={() => setOpenResultModal(false)}>
        <DialogTitle>Result this Race</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {(["first", "second", "third"] as const).map((pos: keyof typeof resultSelection) => (
              <Grid item xs={12} key={pos}>
                <Typography gutterBottom>{pos.charAt(0).toUpperCase() + pos.slice(1)} Place</Typography>
                <Select
                  fullWidth
                  value={resultSelection[pos]}
                  onChange={(e) =>
                    setResultSelection((prev) => ({ ...prev, [pos]: e.target.value }))
                  }
                >
                  {raceHorses
                    .filter((horse) => !Object.values(resultSelection).includes(horse._id) || resultSelection[pos] === horse._id)
                    .map((horse) => (
                      <MenuItem key={horse._id} value={horse._id}>
                        {horse.name}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenResultModal(false)}>Cancel</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleResultSave();
              setOpenResultModal(false);
            }}
          >
            Submit Results
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default ViewRace;
