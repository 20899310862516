export enum roleTypes {
  ADMIN_PERMISSION = "admin",
  SUPER_ADMIN_PERMISSION = "superadmin",
  CLIENT_PERMISSION = "client",
  TIPS_PLUS = "tipsplus",
  RATING_AND_FORM = "ratingandform",
  PRO_TIPS = "protips",
  PREMIER = "premier",
  TEST = "test"
}

export const userRoles = [
  roleTypes.ADMIN_PERMISSION,
  roleTypes.CLIENT_PERMISSION,
  roleTypes.SUPER_ADMIN_PERMISSION,
  roleTypes.PREMIER,
  roleTypes.PRO_TIPS,
  roleTypes.RATING_AND_FORM,
  roleTypes.TIPS_PLUS,
  roleTypes.TEST
];
