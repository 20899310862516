import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Mobile, today } from "Helpers/Constants";
import {
  raceTrackConditionMapping,
  raceTrackConditionTypes,
} from "Helpers/race-track-condition-types";
import { UserService } from "Services/UserService";
import ModalVeriffication from "components/Modal/ModalVeriffication";
import SkeletonLoading from "components/Skeleton/SkeletonLoading";
import { ShortErrorToast, ShortSuccessToast } from "components/Toast/Toastify";
import DynamicButton from "components/dynamic-button/DynamicButton";
import SelectDropdown from "components/select/Select";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import _debounce from "lodash/debounce";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

function ViewVenue() {
  const location = useLocation();
  const initialVenue = location.state || {};
  const [venue, setVenue] = useState(initialVenue);
  const [dropDownVenues, setDropDownVenues] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>(venue.name || "");
  const [selectedVenue, setSelectedVenue] = useState<any>(venue);
  const [isEditable, setIsEditable] = useState(false);
  const [isTrackConditionEditable, setIsTrackConditionEditable] =
    useState(false);
  const [races, setRaces] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTrackCondition, setSelectedTrackCondition] =
    useState<raceTrackConditionTypes>();
  const [editableRaces, setEditableRaces] = useState<{
    [key: string]: { time: string; isEditing: boolean };
  }>({});

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const params = useParams();
  const navigate = useNavigate();
  const date = params.date || today;

  const dateValue = date
    ? new Date(date).toISOString().split("T")[0]
    : "2024-06-01";

  const venueId: string | undefined = params.venueId;

  dayjs.extend(utc);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setVenue((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleTrackConditionChange = (event: any) => {
    const { value } = event.target;
    setSelectedTrackCondition(value);
  };

  const handleUpdateTrackCondition = async (
    trackCondition: raceTrackConditionTypes | undefined,
  ) => {
    try {
      const racesWithStatusOpen = races.find((race) => race.status === "Open");
      if (racesWithStatusOpen) {
        const res = await UserService.updateTrackConditionForAllRaces({
          trackCondition,
          venueId,
        });
        if (res?.data?.statusCode === 201) {
          setSelectedTrackCondition(undefined);
          fetchRaces();
          return ShortSuccessToast({
            message: "Track condition updated successfully",
          });
        }
      } else {
        setSelectedTrackCondition(undefined);
        return ShortErrorToast({ message: "All races are closed" });
      }
      return ShortErrorToast({ message: "Failed to update track condition" });
    } catch (e) {
      console.log(e);
    }
  };

  const handleTrackConditionEdit = () => {
    setIsTrackConditionEditable(!isTrackConditionEditable);
    if (isTrackConditionEditable) {
      setSelectedTrackCondition(selectedTrackCondition);
    }
  };

  const handleEdit = () => {
    setIsEditable(!isEditable);
    if (isEditable) {
      setVenue(initialVenue);
    }
  };

  const handleDeleteRaces = async (races: object[]) => {
    try {
      const res = await UserService.deleteRacesById(races);
      if (res?.data?.status === 200) {
        handleModalClose();
        navigate("/controller/venues");
        return ShortSuccessToast({ message: res?.data?.message });
      }
      return ShortErrorToast({ message: "Failed to delete races" });
    } catch (e) {
      console.log(e);
    }
  };

  const goToViewRace = (race: any) => {
    if (race._id)
      return navigate(`${location.pathname}/${race._id}`, { state: race });
    return;
  };

  const handleSave = async () => {
    try {
      if (!selectedVenue?._id)
        return ShortErrorToast({ message: "Venue not found" });
      const raceIds = races.map(({ _id }) => _id);
      const res = await UserService.updateRacesVenueId(
        selectedVenue._id,
        raceIds,
      );
      if (res?.data?.statusCode === 201) {
        setIsEditable(false);
        fetchRaces();
        return ShortSuccessToast({ message: "Venue updated successfully" });
      }
      return ShortErrorToast({ message: "Failed to update venue" });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isEditable && inputValue.length > 0) {
      const fetchDropDownVenues = _debounce(async (query: string) => {
        setIsSearching(true);
        try {
          const res = await UserService.fetchDropDownVenues(query);
          if (res?.data?.statusCode === 201) {
            setDropDownVenues(res.data.data);
          } else {
            ShortErrorToast({ message: "Failed to Fetch Venues " });
          }
        } catch (error) {
          console.log(error);
        } finally {
          setIsSearching(false);
        }
      }, 500);

      fetchDropDownVenues(inputValue);

      return () => {
        fetchDropDownVenues.cancel();
      };
    }
  }, [inputValue, isEditable]);

  const fetchRaces = useCallback(async () => {
    setLoading(true);
    try {
      if (dateValue >= today) {
        const res = await UserService.fetchVenueInformation(venueId, date);
        if (res?.data?.statusCode === 201) {
          setRaces(res.data.data.races);
        } else {
          ShortErrorToast({ message: "Failed to Fetch Races " });
        }
      } else {
        setRaces(venue?.races);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [date, venueId, setRaces, dateValue, venue?.races]);

  useEffect(() => {
    fetchRaces();
  }, [fetchRaces]);

  const style = {
    TextFieldRace: {
      "& .Mui-disabled": {
        WebkitTextFillColor: "darkcyan !important",
        color: "darkcyan !important",
        textAlign: "center",
      },
      WebkitTextFillColor: "darkslategray !important",
      color: "darkslategray !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "black",
        },
        "&:hover fieldset": {
          borderColor: "black",
        },
        "&.Mui-focused fieldset": {
          borderColor: "black",
        },
      },

      ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "1px solid darkcyan",
      },
      "&:not(.Mui-disabled) .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "1px solid darkcyan",
        },
    },

    TextField: {
      "& .Mui-disabled": {
        WebkitTextFillColor: "darkcyan !important",
        color: "darkcyan !important",
      },
      WebkitTextFillColor: "darkslategray !important",
      color: "darkslategray !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "black",
        },
        "&:hover fieldset": {
          borderColor: "black",
        },
        "&.Mui-focused fieldset": {
          borderColor: "black",
        },
      },

      ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "1px solid darkcyan",
      },
      "&:not(.Mui-disabled) .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "1px solid darkcyan",
        },
    },
  };

  const handleRaceTimeChange = (event: any, raceId: string) => {
    const { value } = event.target;
    setEditableRaces((prev) => ({
      ...prev,
      [raceId]: {
        ...prev[raceId],
        time: value,
      },
    }));
  };

  const toggleEditMode = (raceId: string) => {
    setEditableRaces((prev) => ({
      ...prev,
      [raceId]: {
        ...prev[raceId],
        isEditing: !prev[raceId]?.isEditing,
        time: prev[raceId]?.isEditing
          ? ""
          : new Date(races.find((race) => race._id === raceId)?.dateTimeUtc)
              .toISOString()
              .slice(11, 16) || "",
      },
    }));
  };

  const confirmRaceTimeChange = async (raceId: string) => {
    try {
      const updateRace = races.find((race) => race._id === raceId);
      if (!updateRace) return ShortErrorToast({ message: "Race not found" });

      const currentDate = new Date(updateRace.dateTimeUtc);
      const [hours, minutes] = editableRaces[raceId].time.split(":");
      currentDate.setHours(parseInt(hours, 10), parseInt(minutes, 10));

      const utcDate = new Date(
        currentDate.getTime() - currentDate.getTimezoneOffset() * 60000,
      );

      const updatedRace = { ...updateRace, dateTimeUtc: utcDate.toISOString() };

      const res = await UserService.editRaceInformation(updatedRace);
      if (res?.data?.statusCode === 201) {
        setEditableRaces((prev) => ({
          ...prev,
          [raceId]: { isEditing: false, time: "" },
        }));
        fetchRaces();
        return ShortSuccessToast({ message: "Race time updated successfully" });
      }
      return ShortErrorToast({ message: "Failed to update race time" });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        display="flex"
        sx={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography gutterBottom variant={Mobile() ? "h6" : "h4"} width="100%">
          Venue Details
        </Typography>

        <DynamicButton
          label="Delete"
          color="red"
          width={"10%"}
          onClick={handleModalOpen}
        />

        <DynamicButton
          label="Back"
          color="black"
          width={100}
          onClick={() => window.history.back()}
        />
      </Box>

      <ModalVeriffication
        submitFunction={handleDeleteRaces}
        values={races}
        handleModalClose={handleModalClose}
        open={isModalOpen}
        message={`Are you sure you want to delete ${venue?.name?.charAt(0)?.toUpperCase() + venue?.name?.slice(1) || "this Venue"}?`}
        optionalMessage="Deleting this venue will delete everything that is related to this venue"
      />

      <Grid container alignItems={"center"} mt={4} spacing={2}>
        <Grid item xs={12} lg={6}>
          {!isEditable ? (
            <TextField
              sx={style.TextField}
              fullWidth
              label="Name"
              variant="outlined"
              name="name"
              value={
                venue?.name?.charAt(0)?.toUpperCase() + venue?.name?.slice(1) ||
                ""
              }
              onChange={handleChange}
              disabled={true}
            />
          ) : (
            <Box sx={{ position: "relative" }}>
              <TextField
                sx={style.TextField}
                fullWidth
                label="Search Venue"
                variant="outlined"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              {/* add the edit button inside the text field at the right */}

              {isSearching && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    marginTop: "-12px",
                  }}
                />
              )}

              {dropDownVenues.length > 0 && (
                <Paper
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    width: "100%",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  <List>
                    {dropDownVenues.map((venue) => (
                      <ListItem
                        key={venue._id}
                        button
                        onClick={() => {
                          setSelectedVenue(venue);
                          setInputValue(venue.name);
                          setDropDownVenues([]);
                        }}
                      >
                        {venue.name}
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              )}
            </Box>
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          {isEditable ? (
            <>
              <DynamicButton
                label="Save"
                color="darkcyan"
                width={"50%"}
                onClick={handleSave}
              />
              <DynamicButton
                label="Cancel"
                color="red"
                width={"50%"}
                onClick={handleEdit}
              />
            </>
          ) : (
            <DynamicButton
              label="Edit"
              backgroundColor="darkcyan"
              color="white"
              width={"50%"}
              onClick={handleEdit}
            />
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <SelectDropdown
            name="trackCondition"
            onChange={handleTrackConditionChange}
            value={selectedTrackCondition || ""}
            label="Select race track condition"
            items={raceTrackConditionMapping}
            disabled={!isTrackConditionEditable}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          {selectedTrackCondition ? (
            <>
              <DynamicButton
                label="Save"
                color="darkcyan"
                width={"50%"}
                onClick={() => {
                  handleUpdateTrackCondition(selectedTrackCondition);
                }}
              />
              <DynamicButton
                label="Cancel"
                color="red"
                width={"50%"}
                onClick={() => {
                  setSelectedTrackCondition(undefined);
                  setIsTrackConditionEditable(false);
                }}
              />
            </>
          ) : (
            <DynamicButton
              label="Edit"
              backgroundColor="darkcyan"
              color="white"
              width={"50%"}
              onClick={() => {
                handleTrackConditionEdit();
              }}
            />
          )}
        </Grid>
        {/* <Grid item xs={12} lg={6}>
          <>
            <DynamicButton
              label="Confirm"
              color="darkcyan"
              width={"50%"}
              disabled={!selectedTrackCondition}
              onClick={() => {
                handleUpdateTrackCondition(selectedTrackCondition);
              }}
            />
            <DynamicButton
              label="Clear"
              color="red"
              width={"50%"}
              disabled={!selectedTrackCondition}
              onClick={() => {
                setSelectedTrackCondition(undefined);
              }}
            />
          </>
        </Grid> */}
      </Grid>
      {loading ? (
        <SkeletonLoading skeletonCardCount={3} size="big" />
      ) : (
        races?.length > 0 &&
        races?.map((race, key) => (
          <Card sx={{ mt: 4 }} key={key}>
            <CardContent>
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item xs={6} lg={1}>
                  <TextField
                    sx={style.TextFieldRace}
                    label="Number"
                    fullWidth
                    variant="outlined"
                    name="sequence"
                    value={race?.sequence || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} lg={1}>
                  <TextField
                    sx={style.TextFieldRace}
                    label="Result"
                    fullWidth
                    variant="outlined"
                    name="ptpResult"
                    value={race?.ptpResult || "No Result"}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} lg={1}>
                  <TextField
                    sx={style.TextFieldRace}
                    label="Track Condition"
                    fullWidth
                    variant="outlined"
                    name="trackCondition"
                    value={race?.trackCondition?.toUpperCase() || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} lg={1}>
                  <TextField
                    sx={style.TextFieldRace}
                    label="Status"
                    fullWidth
                    variant="outlined"
                    name="status"
                    value={race?.status || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} lg={1}>
                  <TextField
                    sx={style.TextFieldRace}
                    label="Distance"
                    fullWidth
                    variant="outlined"
                    name="distance"
                    value={race?.distance || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={6} lg={1}>
                  <TextField
                    sx={style.TextFieldRace}
                    label="Track Weather"
                    fullWidth
                    variant="outlined"
                    name="trackWeather"
                    value={race?.trackWeather || ""}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} lg={2}>
                  <TextField
                    sx={style.TextFieldRace}
                    fullWidth
                    type="time"
                    label="Time"
                    variant="outlined"
                    name="time"
                    value={
                      editableRaces[race._id]?.isEditing
                        ? editableRaces[race._id]?.time
                        : new Date(race?.dateTimeUtc)
                            .toISOString()
                            .slice(11, 16)
                    }
                    onChange={(e) => handleRaceTimeChange(e, race._id)}
                    disabled={!editableRaces[race._id]?.isEditing}
                  />
                </Grid>
                {editableRaces[race._id]?.isEditing ? (
                  <>
                    <Grid item xs={12} lg={1}>
                      <DynamicButton
                        label="Confirm"
                        backgroundColor="darkcyan"
                        color="white"
                        width={"100%"}
                        onClick={() => confirmRaceTimeChange(race._id)}
                      />
                    </Grid>
                    <Grid item xs={12} lg={1}>
                      <DynamicButton
                        label="Cancel"
                        backgroundColor="red"
                        color="white"
                        width={"100%"}
                        onClick={() => toggleEditMode(race._id)}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} lg={1}>
                    <DynamicButton
                      label="Edit"
                      backgroundColor="darkcyan"
                      color="white"
                      width={"100%"}
                      onClick={() => toggleEditMode(race._id)}
                    />
                  </Grid>
                )}
                {!editableRaces[race._id]?.isEditing && (
                  <Grid item xs={12} lg={1}>
                    <DynamicButton
                      label="View"
                      backgroundColor="darkcyan"
                      color="white"
                      width={"100%"}
                      onClick={() => {
                        goToViewRace(race);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        ))
      )}
    </Box>
  );
}

export default ViewVenue;
