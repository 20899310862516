import { Box, Button, Grid, LinearProgress, Typography } from "@mui/material";
import { UserService } from "Services/UserService";
import axios from "axios";
import { ShortErrorToast, ShortSuccessToast } from "components/Toast/Toastify";
// import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

function UploadAapXml() {
  const [files, setFiles] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<string[]>([]);
  // const [setFailedFiles] = useState<string[]>([]);
  const [xmlData, setXmlData] = useState<Record<string, string>>({});
  const [loading, setLoading] = useState(false);
  const [cancelTokenSource, setCancelTokenSource] = useState(
    axios.CancelToken.source(),
  );
  const [showAssignModal, setShowAssignModal] = useState(false);
  const [
    trackCode,
    //  setTrackCode
  ] = useState("");
  const [meetDate,
    //  setMeetDate
  ] = useState("");

  useEffect(() => {
    // Clear localStorage when the component mounts
    localStorage.removeItem("uploadedFiles");
  }, []);

  const handleFileRead = (fileType: string, fileContent: string) => {
    console.log(`File Content: ${fileContent}`);
    setXmlData((prev) => ({
      ...prev,
      [fileType]: fileContent,
    }));
  };

  const sanitizeFileType = (fileName: string) => {
    const nameWithoutExtension = fileName.split(".")[0];
    let lastChar;

    if (nameWithoutExtension.includes("(")) {
      lastChar = nameWithoutExtension.split(" ")[0].slice(-1);
    } else {
      lastChar = nameWithoutExtension.slice(-1);
    }

    switch (lastChar) {
      case "F":
        return "F";
      case "R":
        return "R";
      case "H":
        return "H";
      default:
        return "U";
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        const reader = new FileReader();
        const fileType = sanitizeFileType(file.name);

        if (!files.includes(file.name)) {
          reader.onload = () =>
            handleFileRead(fileType, reader.result as string);
          reader.readAsText(file);
          setFiles((prev) => [...prev, file.name]);
        } else {
          console.log("File already uploaded:", file.name);
        }
      });
    },
    [files],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadData = useCallback(
    async (uploadFn: any, fileType: string) => {
      try {
        const upload = await uploadFn();
        if (upload?.status === 200) {
          const newlyUploadedFiles = files.filter(
            (file) => sanitizeFileType(file) === fileType
          );
          // const { trackcode, meetdate } = upload.data;
          // const formattedMeetDate = meetdate ? moment(meetdate, 'DD/MM/YYYY').format('YYYY-MM-DD') : "";

          // setTrackCode(trackcode);
          // setMeetDate(formattedMeetDate);
          // setShowAssignModal(true);
          setUploadedFiles((prev) => [...newlyUploadedFiles, ...prev]);
          setFiles((prev) =>
            prev.filter((file) => sanitizeFileType(file) !== fileType)
          );
          ShortSuccessToast({
            message: `Upload ${fileType === "R" ? "Race" : fileType === "H" ? "Horse" : "Forming"} succeeded`,
          });
        }
      } catch (error: any) {
        if (axios.isCancel(error)) {
          ShortErrorToast({ message: `Upload ${fileType} cancelled` });
        } else {
          ShortErrorToast({
            message: `Upload ${fileType} failed: ${error.message}`,
          });
        }
      }
    },
    [files]
  );

  const UploadXmlAap = useCallback(() => {
    const xmlDataArray = Object.values(xmlData);
    return uploadData(
      () =>
        UserService.uploadAapXml(xmlDataArray, {
          cancelToken: cancelTokenSource.token,
        }),
      "XML",
    );
  }, [xmlData, uploadData, cancelTokenSource]);

  const handleRemoveFile = (event: React.MouseEvent, fileName: string) => {
    event.stopPropagation();
    const fileType = sanitizeFileType(fileName);
    setFiles(files.filter((file) => file !== fileName));
    setXmlData((prev) => {
      const updatedData = { ...prev };
      delete updatedData[fileType];
      return updatedData;
    });
  };

  const handleUpload = async () => {
    try {
      setLoading(true);
      await UploadXmlAap();
      setUploadedFiles(files);
      localStorage.setItem("uploadedFiles", JSON.stringify(files)); // Save to localStorage
      setXmlData({});
      setFiles([]); // Clear files after upload
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const assignPer = async (date: string, trackCode: string) => {
    try {
      setLoading(true);
      const response = await UserService.assignPer(trackCode, date);
      if (response?.status === 200) {
        ShortSuccessToast({ message: "Assignment successful" });
      } else {
        ShortErrorToast({ message: "Assignment failed" });
      }
    } catch (error: any) {
      ShortErrorToast({ message: `Error: ${error.message}` });
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    cancelTokenSource.cancel("Upload cancelled by user");
    setLoading(false);
    setFiles([]);
    setUploadedFiles([]);
    setXmlData({});
    localStorage.removeItem("uploadedFiles"); // Clear localStorage
    setCancelTokenSource(axios.CancelToken.source());
  };

  const handleClear = () => {
    setFiles([]);
    setXmlData({});
    localStorage.removeItem("uploadedFiles"); // Clear localStorage
  };

  return (
    <>
      {showAssignModal && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "white",
              borderRadius: 2,
              p: 4,
              width: 400,
              textAlign: "center",
            }}
          >
            <Typography variant="h6" mb={2}>
              Do you want to assign PER for this race?
            </Typography>
            <Typography variant="body2" mb={3}>
              Track: {trackCode}, Date: {meetDate}
            </Typography>
            <Box display="flex" justifyContent="space-around">
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  await assignPer(trackCode, meetDate);
                  setShowAssignModal(false);
                }}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                onClick={() => setShowAssignModal(false)}
              >
                No
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <Typography variant="h4">Controller - Upload AAP XML Files</Typography>
      <Grid container spacing={2} mt={4}>
        <Grid item xs={12} lg={10}>
          <Button
            variant="contained"
            disabled={files.length === 0 || loading}
            color="primary"
            onClick={handleUpload}
            sx={{ position: "relative", marginRight: "10px" }}
          >
            {loading ? (
              <>
                <LinearProgress
                  color="success"
                  sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: "4px",
                  }}
                />
                Uploading...
              </>
            ) : (
              "Upload AAP XML"
            )}
          </Button>
          <Button
            disabled={!loading}
            onClick={handleCancel}
            sx={{
              border: !loading ? "none" : "1px solid black",
              color: loading ? "black" : "inherit",
              backgroundColor: "inherit",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleClear}
            disabled={files.length === 0}
            sx={{
              marginLeft: "10px",
              border: files.length === 0 ? "none" : "1px solid black",
              color: files.length === 0 ? "inherit" : "black",
              backgroundColor: "inherit",
            }}
          >
            Clear
          </Button>
        </Grid>
        <Grid item xs={12} lg={10}>
          <Box
            {...getRootProps({
              style: {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "20px",
                borderWidth: "2px",
                borderRadius: "20px",
                borderColor: "#eeeeee",
                borderStyle: "dashed",
                backgroundColor: "#fafafa",
                color: "#bdbdbd",
                outline: "none",
                transition: "border .24s ease-in-out",
                cursor: "pointer",
                height: "300px",
                justifyContent: "center",
                overflowY: "auto",
              },
            })}
          >
            <input {...getInputProps()} disabled={loading} />
            {files.length === 0 ? (
              isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag and drop AAP XML files here, or click to select files</p>
              )
            ) : (
              <Box
                style={{ maxHeight: "100%", overflowY: "auto", width: "100%" }}
              >
                {files.map((file, index) => (
                  <Box
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography mr={2}>{file}</Typography>
                    {!loading && (
                      <Button
                        sx={{
                          fontSize: 12,
                          py: 0,
                          px: 0,
                          backgroundColor: "transparent",
                          ":hover": {
                            color: "red",
                            backgroundColor: "transparent",
                          },
                        }}
                        variant="outlined"
                        color="error"
                        onClick={(event: any) => handleRemoveFile(event, file)}
                      >
                        X
                      </Button>
                    )}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} lg={10}>
          {uploadedFiles.length > 0 && (
            <Box mt={3} p={2} border="1px solid #ccc" borderRadius="8px">
              <Typography variant="h6" mb={2}>
                Uploaded Files ({uploadedFiles.length} files uploaded)
              </Typography>
              <Box display="flex" flexDirection="column" gap={1}>
                {uploadedFiles.map((file, index) => (
                  <Typography key={index} variant="body1">
                    ✅ {file}
                  </Typography>
                ))}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );

}

export default UploadAapXml;