export enum raceTrackConditionTypes {
  GOOD = "good",
  GOOD3 = "good3",
  GOOD4 = "good4",
  SOFT = "soft",
  SOFT5 = "soft5",
  SOFT6 = "soft6",
  SOFT7 = "soft7",
  HEAVY = "heavy",
  HEAVY8 = "heavy8",
  HEAVY9 = "heavy9",
  HEAVY10 = "heavy10",
  SYNTHETIC = "synthetic",
  FIRM = "firm",
  FIRM1 = "firm1",
  FIRM2 = "firm2",
  ABND = "abnd",
}

export const raceTrackConditionMapping = [
  raceTrackConditionTypes.ABND,
  raceTrackConditionTypes.SYNTHETIC,
  raceTrackConditionTypes.FIRM,
  raceTrackConditionTypes.FIRM1,
  raceTrackConditionTypes.FIRM2,
  raceTrackConditionTypes.GOOD,
  raceTrackConditionTypes.GOOD3,
  raceTrackConditionTypes.GOOD4,
  raceTrackConditionTypes.HEAVY,
  raceTrackConditionTypes.HEAVY8,
  raceTrackConditionTypes.HEAVY9,
  raceTrackConditionTypes.HEAVY10,
  raceTrackConditionTypes.SOFT,
  raceTrackConditionTypes.SOFT5,
  raceTrackConditionTypes.SOFT6,
  raceTrackConditionTypes.SOFT7,
];
