import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

interface Item {
  label: string;
  value: string;
}

interface SelectDropdownProps {
  value?: string | string[];
  onChange?: (event: any) => void;
  disabled?: boolean;
  items?: (string | Item)[];
  label?: string;
  multiple?: boolean;
  name?: string;
  style?: any;
  loading?: boolean;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  value,
  onChange,
  disabled,
  items,
  label,
  multiple,
  name,
  style,
  loading,
}) => {
  return (
    <FormControl fullWidth required variant="outlined">
      <InputLabel
        id="select-label"
        sx={
          style || {
            color: "text.primary",
            "&.Mui-focused": {
              color: "darkcyan",
            },
          }
        }
      >
        {label || "Select an item"}
      </InputLabel>
      <Select
        name={name || label || "Select"}
        labelId="select-label"
        multiple={multiple || false}
        value={value}
        onChange={onChange}
        disabled={disabled || false}
        label={label}
        sx={
          style || {
            textAlign: "left",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "darkcyan",
            },
          }
        }
      >
        {items &&
          items.map((item, index) =>
            typeof item === "string" ? (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ) : (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ),
          )}
      </Select>
    </FormControl>
  );
};
export default SelectDropdown;
