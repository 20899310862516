import axios from "axios";

let connection;
export default function http_lazy() {
  const token = localStorage.getItem(
    process.env.TOKEN_NAME || "PTPtokenFromStorageToAuthenticateUserOnAdmin",
  );

  connection = axios.create({
    baseURL: process.env.REACT_APP_BASE_LAZY,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return connection;
}
