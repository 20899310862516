import { Button, Link } from "@mui/material";
import React from "react";
import { useRoles } from "useRoles";
import "./DynamicButton.css";

type ButtonProps = {
  color?: string;
  onHoverBg?: string;
  onHoverColor?: string;
  backgroundColor?: string;
  href?: string;
  disabled?: boolean;
  label: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  textSize?: number;
  state?: any;
  requiredRoles?: string[];
  width?: string | number;
};

const DynamicButton = ({
  label,
  onClick,
  color,
  onHoverBg,
  onHoverColor,
  backgroundColor,
  href,
  disabled,
  textSize,
  requiredRoles,
  width,
}: ButtonProps) => {
  const { hasRole } = useRoles();
  const style = {
    color: color || "darkcyan",
    backgroundColor: backgroundColor || "rgba(255, 255, 255, 0.38)",
    justifyContent: "center",
    fontSize: textSize || 14,
    opacity: disabled ? 0.5 : 1,
    cursor: disabled ? "not-allowed" : "pointer",
    textDecoration: "none",
    width: width || "100%",
  };

  const isAuthorized = !requiredRoles || hasRole(requiredRoles);

  if (!isAuthorized) {
    return null;
  }

  return href ? (
    <Link
      href={"/#" + href}
      sx={{
        ...style,
        ":hover": {
          color: onHoverColor || "white",
          backgroundColor:
            onHoverBg || color === "white" ? "darkcyan" : color || "darkcyan",
          padding: "6px 8px",
        },
      }}
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        }
      }}
    >
      {label.toUpperCase()}
    </Link>
  ) : (
    <Button
      onClick={(e) => {
        if (disabled) {
          e.preventDefault();
        } else {
          onClick && onClick(e);
        }
      }}
      sx={{
        ...style,
        ":hover": {
          color: onHoverColor || "white",
          backgroundColor: onHoverBg || "darkcyan",
        },
      }}
    >
      {label}
    </Button>
  );
};

export default DynamicButton;
