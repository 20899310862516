import { roleTypes } from "Helpers/role-types.enum";
import axios from "axios";
import dayjs from "dayjs";
import http_lazy from "http-common-no-memory";
import http from "../http-common";

// Auth Routes

const userLogin = async (userLoginData: {
  email: string;
  password: string;
  isAdminLogin?: boolean;
}) => {
  try {
    return http_lazy().post(`/auth/login`, userLoginData);
  } catch (error) {
    console.log("User logging in error", error);
  }
};

// User Routes

const getUsers = async () => {
  try {
    return http().get(`/admin/find-all-users`);
  } catch (error) {
    console.log("Get users error", error);
  }
};

const getNabPlayers = async () => {
  try {
    return http().get(`/admin/find-nab-players`);
  } catch (error) {
    console.log("Get NAB players error", error);
  }
}

const getUser = async (id: string) => {
  try {
    return http().get(`/admin/find-user/${id}`);
  } catch (error) {
    console.log("Get user error", error);
  }
};

const getUserRoles = async (id: string) => {
  try {
    return http().get(`/admin/find-user-roles/${id}`);
  } catch (error) {
    console.log("Get user roles error", error);
  }
};

const editUser = async (id: string, user: any) => {
  try {
    return http().post(`/admin/update-user/${id}`, user);
  } catch (error) {
    console.log("Edit user error", error);
  }
};

const getAdmins = async () => {
  try {
    return http().get(`/admin/find-all-admins`);
  } catch (error) {
    console.log("Get admins error", error);
  }
};

// Home Routes

const getHomeCardsInformation = async () => {
  try {
    return http().get(`/admin/fetch-cards-information`);
  } catch (error) {
    console.log("Get Cards Information error", error);
  }
};

// Venues Routes

const getVenuesInformation = async () => {
  try {
    return http().get(`/admin/fetch-venues-information`);
  } catch (error) {
    console.log("Get Venues Information error", error);
  }
};

const addVenue = async (venue: {
  venueName: string;
  venueCountry: string;
  venueState: string;
  venueCode: string;
}) => {
  try {
    return http().post(`/admin/add-new-venue`, venue);
  } catch (error) {
    console.log("Add venue error", error);
  }
};

const getVenuesByDate = async (date: string) => {
  try {
    return http().get(`/admin/fetch-venues-by-date/${date}`);
  } catch (error) {
    console.log("Get Venues by date error", error);
  }
};

const getVenuesByPostGrass = async (startDate: string, endDate: string) => {
  try {
    const data = { startDate, endDate };
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_COLD_SOTRAGE}Race/GetRaces`,
      data,
    );

    return response.data;
  } catch (error) {
    console.log("Get Venues By Post Grass by date error", error);
  }
};

const editVenueInformation = async (venue: {
  venueId: string;
  name: string;
  country: string;
  venueState: string;
  venueCode: string;
}) => {
  try {
    return http().post(`/admin/edit-venue-information`, venue);
  } catch (error) {
    console.log("Update venue error", error);
  }
};

const editRaceInformation = async (race: {}) => {
  try {
    return http().post(`/admin/edit-race-information`, race);
  } catch (error) {
    console.log("Update race error", error);
  }
};

const editRaceHorsesInformation = async () => {
  try {
    return http().post(`/admin/edit-race-horses-information`);
  } catch (error) {
    console.log("Update race horses error", error);
  }
};

const fetchVenueInformation = async (
  venueId: string | undefined,
  date: string | undefined,
) => {
  try {
    return http().get(`/admin/fetch-venue-information/${venueId}/${date}`);
  } catch (error) {
    console.log("Fetch venue information error", error);
  }
};

const uploadAapRace = (races: any[], options = {}) => {
  try {
    return http_lazy()
      .post(`/cold-storage/uploadAApRace`, { races }, options)
      .then((response) => response);
  } catch (error) {
    console.log(" Upload AAP race failed", error);
  }
};

const uploadAapHorse = (horses: any[], options = {}) => {
  try {
    return http_lazy()
      .post(`/cold-storage/uploadAApHorse`, { horses }, options)
      .then((response) => response);
  } catch (error) {
    console.log("Upload AAP horse failed", error);
  }
};

const uploadAapForm = (form: any[], options = {}) => {
  try {
    const filteredForm = Array.from(
      new Map(
        form.map((item) => [
          `${item.HORSENAME}-${item.MEETDATE}-${item.TRACKCODE}-${item.FORMRACENUMBER}`,
          item,
        ]),
      ).values(),
    );

    return http_lazy()
      .post(`/cold-storage/uploadAApForm`, { form: filteredForm }, options)
      .then((response) => response);
  } catch (error) {
    console.log("Upload AAP form failed", error);
  }
};

const assignPer = async (date: string, trackCode: string) => {
  try {
    return http().post(`/aap/assign-per`, { meetdate: date, trackcode: trackCode });
  } catch (error) {
    console.log("Assign PER failed", error);
  }
};

const uploadAapXml = (xmlDataArray: any[], options = {}) => {
  try {
    const xmlString = xmlDataArray.join("");

    return http_lazy()
      .post(`/aap/upload-xml`, { xmlString }, options)
      .then((response) => response);
  } catch (error) {
    console.log("Upload AAP XML form failed", error);
  }
};

const updateHorsePaceInRides = async (startDate: Date, endDate: Date) => {
  try {
    return http_lazy().get(
      `/cold-storage/updateHorseRidePace/${dayjs(startDate)}/${dayjs(endDate)}`,
    );
  } catch (err) {
    console.log("Failed to update Horse Pace in Rides ", err);
  }
};

const getUnibetRacesByDate = async (fromDate: any, toDate: any) => {
  try {
    return http().get(`/uni-bet`, {
      params: {
        fromDate,
        toDate,
      },
    });
  } catch (error) {
    console.log("Get Unibet raced by date failed", error);
  }
};

const getLadbrokesRacesByDate = async (fromDate: any, toDate: any) => {
  try {
    return http().get(`/lad-brokes`, {
      params: {
        fromDate,
        toDate,
      },
    });
  } catch (error) {
    console.log("Get Ladbrokes raced by date failed", error);
  }
};

const getHorsesForRace = (raceId?: string) => {
  try {
    return http().get(`/admin/fetch-horses/${raceId}`);
  } catch (error) {
    console.log("Get horses by race id failed", error);
  }
};

const getHorseById = (horseId?: string) => {
  try {
    return http().get(`/admin/fetch-horse/${horseId}`);
  } catch (error) {
    console.log("Get horse by id failed", error);
  }
};

const getHorseForming = (horseId?: string) => {
  try {
    return http().get(`/admin/fetch-horse-forming/${horseId}`);
  } catch (error) {
    console.log("Get horse forming by id failed", error);
  }
};

const deleteHorse = (horseId?: string) => {
  try {
    return http().delete(`/admin/delete-horse/${horseId}`);
  } catch (error) {
    console.log("Delete horse by id failed", error);
  }
};

const updateTrackConditionForAllRaces = async ({
  trackCondition,
  venueId,
}: {
  trackCondition: string | undefined;
  venueId: string | undefined;
}) => {
  try {
    return http().post(`/admin/venue-update-track-condition`, {
      trackCondition,
      venueId,
    });
  } catch (error) {
    console.log("Update track condition for races failed", error);
  }
};

const fetchDropDownVenues = async (venueName: string) => {
  try {
    return http().post(`/admin/fetch-drop-down-venues`, { venueName });
  } catch (error) {
    console.log("Fetch drop down venues failed", error);
  }
};

const updateRacesVenueId = async (venueId: string, raceIds: string[]) => {
  try {
    return http().post(`/admin/update-races-venueId`, { venueId, raceIds });
  } catch (error) {
    console.log("updating races venue Id Failed", error);
  }
};

const deleteRacesById = async (races: object[]) => {
  try {
    const res = await http().post(`/admin/delete-races-venue`, races);
    return res.data;
  } catch (error) {
    console.error("Failed to delete races:", error);
  }
};

const sendEmailToRole = async (
  role: roleTypes,
  subject: string,
  message: string,
) => {
  try {
    const res = await http_lazy().post(`/admin/send-email-to-user-roles`, {
      role,
      subject,
      message,
    });
    return res.data;
  } catch (error) {
    console.log("Failed to send email to role: ", role);
  }
};

const sendEmailToAll = async ({
  subject,
  message,
}: {
  subject: string;
  message: string;
}) => {
  try {
    const res = await http_lazy().post(`/admin/send-email-to-everyone`, {
      subject,
      message,
    });
    return res.data;
  } catch (error) {
    console.log("Failed to send emails to all");
  }
};

const sendEmailToSingleUser = async ({
  email,
  subject,
  message,
}: {
  email: string;
  subject: string;
  message: string;
}) => {
  try {
    const res = await http_lazy().post(`/admin/send-email-to-single-user`, {
      subject,
      message,
    });
    return res.data;
  } catch (error) {
    console.log("Failed to send emails to all");
  }
};

const saveRaceResults = async (resultData: { position: number; rideId: string }[]) => {
  try {
    const res = await http().post(`/admin/save-race-results`, resultData);
    return res.data;
  } catch (error) {
    console.log("Failed to save race results", error);
    throw error;
  }
};


export const UserService = {
  deleteRacesById,
  userLogin,
  getUsers,
  getNabPlayers,
  getUser,
  editUser,
  getAdmins,
  getUserRoles,
  getHomeCardsInformation,
  getVenuesInformation,
  addVenue,
  getVenuesByDate,
  editVenueInformation,
  editRaceInformation,
  editRaceHorsesInformation,
  fetchVenueInformation,
  uploadAapRace,
  uploadAapForm,
  uploadAapXml,
  uploadAapHorse,
  getUnibetRacesByDate,
  getLadbrokesRacesByDate,
  getHorsesForRace,
  getHorseById,
  getHorseForming,
  getVenuesByPostGrass,
  deleteHorse,
  updateTrackConditionForAllRaces,
  fetchDropDownVenues,
  updateRacesVenueId,
  sendEmailToRole,
  sendEmailToAll,
  sendEmailToSingleUser,
  updateHorsePaceInRides,
  saveRaceResults,
  assignPer
};
