export class Utilities {
  private static readonly metersPerFurlong = 201.168;

  /**
   * Converts furlongs to meters.
   * @param {number} furlongs - The distance in furlongs.
   * @returns {number} The distance in meters.
   */
  static convertFurlongsToMeters(furlongs: number): number {
    return furlongs * this.metersPerFurlong;
  }
}
