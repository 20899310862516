import React from "react";
import "./markee.css";

const Marquee = ({ text }: { text: string }) => {
  const letters = text.split("").map((letter: string, index: number) => (
    <span key={index} style={{ animationDelay: `${0.3 * index}s` }}>
      {letter}
    </span>
  ));

  return <div className="marquee">{letters}</div>;
};

export default Marquee;
