import { Box, Skeleton } from "@mui/material";
import React from "react";

function SkeletonLoading({
  skeletonCardCount,
  size,
}: {
  skeletonCardCount?: number;
  size?: "big" | "small";
}) {
  skeletonCardCount = skeletonCardCount || 6;
  size = size || "small";

  return (
    <Box
      display={"flex"}
      flexWrap={"wrap"}
      justifyContent={"start"}
      gap={"2rem"}
      mt={4}
    >
      {Array.from({ length: skeletonCardCount }).map((_, index) => (
        <Box
          key={index}
          width={size === "big" ? "100%" : 300}
          marginRight={2}
          my={1}
        >
          <Skeleton
            variant="rectangular"
            height={27}
            width={"100%"}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            height={27}
            width={"100%"}
            animation="wave"
          />
          <Skeleton
            variant="rectangular"
            height={27}
            width={"100%"}
            animation="wave"
          />
        </Box>
      ))}
    </Box>
  );
}

export default SkeletonLoading;
