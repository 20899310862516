import { getDecodedRoles } from "Helpers/Constants";

export const useRoles = () => {
  const hasRole = (requiredRoles: string[]) => {
    if (getDecodedRoles() === null) return false;
    return getDecodedRoles()?.find((userRole: any) =>
      requiredRoles?.includes(userRole),
    );
  };

  return { hasRole };
};
