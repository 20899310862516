import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { Mobile } from "Helpers/Constants";
import { ShortErrorToast } from "components/Toast/Toastify";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoginThunk } from "redux/createAsyncThunk";

function LoginModal({ open, setOpen }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: Mobile() ? "85%" : 600,
    bgcolor: "background.paper",
    borderRadius: 8,
    boxShadow: 24,
    p: 4,
    TextField: {
      input: { color: "black" },
      "& label.Mui-focused": { color: "darkcyan" },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": { borderColor: "lightcyan" },
        "&:hover fieldset": { borderColor: "darkcyan" },
      },
    },
  };

  const handleClose = () => {};

  const { loading } = useSelector((state) => state.auth);

  const handleLogin = async () => {
    try {
      const login = await dispatch(
        LoginThunk({ email, password, isAdminLogin: true }),
      );
      if (login.payload?.statusCode === 500)
        return ShortErrorToast({ message: login.payload.message });
      window.location.href = "/#/";
    } catch (error) {
      console.log(error);
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      return handleLogin();
    }
    if(event.key === "Space"){
      return handleLogin()
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="login-modal-title"
        aria-describedby="login-modal-description"
      >
        <Box sx={style}>
          <Typography id="login-modal-title" variant="h5" component="h2">
            Sign In
          </Typography>
          <Typography
            id="login-modal-description"
            variant="body2"
            component="p"
          >
            Please enter your credentials to proceed.
          </Typography>
          <InputLabel sx={{ mt: 2 }} htmlFor="email">
            Email Address
          </InputLabel>
          <TextField
            value={email}
            onKeyDown={handleKeyPress}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            margin="normal"
            required
            fullWidth
            id="email"
            name="email"
            autoComplete="email"
            type="email"
            autoFocus
            sx={style.TextField}
          />
          <InputLabel sx={{ mt: 2 }} htmlFor="password">
            Password
          </InputLabel>
          <TextField
            value={password}
            onKeyDown={handleKeyPress}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            margin="normal"
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            sx={style.TextField}
          />
          <Button
            onClick={handleLogin}
            disabled={loading}
            style={{
              marginTop: "20px",
              width: "100%",
              borderRadius: 8,
              backgroundColor: "darkcyan",
              color: "white",
              "&:hover": { backgroundColor: "lightcyan", color: "black" },
            }}
          >
            {!loading ? (
              "Log in"
            ) : (
              <CircularProgress
                sx={{ maxWidth: "25px", maxHeight: "25px", color: "white " }}
              />
            )}
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default LoginModal;
