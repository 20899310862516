import { Box, Grid, TextField, Typography } from "@mui/material";
import { Mobile } from "Helpers/Constants";
import { UserService } from "Services/UserService";
import { ShortErrorToast } from "components/Toast/Toastify";
import DynamicButton from "components/dynamic-button/DynamicButton";
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";

function ViewHorse() {
  const params = useParams();

  const horseId: string | undefined = params.horseId;
  const [horse, setHorse] = useState<any>();

  const fetchHorse = useCallback(async () => {
    try {
      const res = await UserService.getHorseForming(horseId);
      if (res?.data.statusCode === 201) {
        return setHorse(res?.data.data);
      }
      return ShortErrorToast({ message: "Failed to Fetch Horse" });
    } catch (err) {
      console.log(err);
    }
  }, [horseId]);

  useEffect(() => {
    fetchHorse();
  }, [fetchHorse]);

  const style = {
    TextFieldhorse: {
      "& .Mui-disabled": {
        WebkitTextFillColor: "darkcyan !important",
        color: "darkcyan !important",
        textAlign: "center",
      },
      WebkitTextFillColor: "darkslategray !important",
      color: "darkslategray !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "black",
        },
        "&:hover fieldset": {
          borderColor: "black",
        },
        "&.Mui-focused fieldset": {
          borderColor: "black",
        },
      },

      ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "1px solid darkcyan",
      },
      "&:not(.Mui-disabled) .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "1px solid darkcyan",
        },
    },

    TextField: {
      "& .Mui-disabled": {
        WebkitTextFillColor: "darkcyan !important",
        color: "darkcyan !important",
      },
      WebkitTextFillColor: "darkslategray !important",
      color: "darkslategray !important",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "black",
        },
        "&:hover fieldset": {
          borderColor: "black",
        },
        "&.Mui-focused fieldset": {
          borderColor: "black",
        },
      },

      ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "1px solid darkcyan",
      },
      "&:not(.Mui-disabled) .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "1px solid darkcyan",
        },
    },
  };

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <Box
        display="flex"
        sx={{
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography gutterBottom variant={Mobile() ? "h6" : "h4"} width="100%">
          Horse Details - Forming
        </Typography>

        <DynamicButton
          label="Back"
          color="black"
          width={100}
          onClick={() => window.history.back()}
        />
      </Box>
      <Grid container alignItems={"center"} mt={4} spacing={2}>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Meet Date"
            variant="outlined"
            name="MEETDATE"
            value={dayjs(horse?.MEETDATE).format("YYYY-MM-DD") || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Age"
            variant="outlined"
            name="AGE"
            value={horse?.AGE || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Barrier"
            variant="outlined"
            name="BARRIER"
            value={horse?.BARRIER || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Class Code"
            variant="outlined"
            name="CLASSCODE"
            value={horse?.CLASSCODE || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Distance"
            variant="outlined"
            name="DISTANCE"
            value={horse?.DISTANCE || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="FF Rating"
            variant="outlined"
            name="FFRATING"
            value={horse?.FFRATING || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Form Race Number"
            variant="outlined"
            name="FORMRACENUMBER"
            value={horse?.FORMRACENUMBER || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Going"
            variant="outlined"
            name="GOING"
            value={horse?.GOING || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="HCP Weight"
            variant="outlined"
            name="HCPWGT"
            value={horse?.HCPWGT || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Horse Prize"
            variant="outlined"
            name="HORSEPRIZE"
            value={horse?.HORSEPRIZE || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Jockey"
            variant="outlined"
            name="JOCKEY"
            value={horse?.JOCKEY || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Limit"
            variant="outlined"
            name="LIMIT"
            value={horse?.LIMIT || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Meet Type"
            variant="outlined"
            name="MEETTYPE"
            value={horse?.MEETTYPE || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Other R1 Weight"
            variant="outlined"
            name="OTHRR1WGT"
            value={horse?.OTHRR1WGT || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Other R2 Weight"
            variant="outlined"
            name="OTHRR2WGT"
            value={horse?.OTHRR2WGT || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Other R3 Weight"
            variant="outlined"
            name="OTHRR3WGT"
            value={horse?.OTHRR3WGT || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="OT Rating"
            variant="outlined"
            name="OTRATING"
            value={horse?.OTRATING || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Per"
            variant="outlined"
            name="PER"
            value={horse?.PER || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Place"
            variant="outlined"
            name="PLACE"
            value={horse?.PLACE || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Price OPN"
            variant="outlined"
            name="PRICEOPN"
            value={horse?.PRICEOPN || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Price SP"
            variant="outlined"
            name="PRICESP"
            value={horse?.PRICESP || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Race Name"
            variant="outlined"
            name="RACENAME"
            value={horse?.RACENAME || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Race Number"
            variant="outlined"
            name="RACENUM"
            value={horse?.RACENUM || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Race Prize"
            variant="outlined"
            name="RACEPRIZE"
            value={horse?.RACEPRIZE || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Race Time"
            variant="outlined"
            name="RACETIME"
            value={horse?.RACETIME || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Section Distance Time"
            variant="outlined"
            name="SECTDISTTIME"
            value={horse?.SECTDISTTIME || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Starters"
            variant="outlined"
            name="STARTERS"
            value={horse?.STARTERS || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Time Length"
            variant="outlined"
            name="TIME_LENGTH"
            value={horse?.TIME_LENGTH || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Track Area"
            variant="outlined"
            name="TRACKAREA"
            value={horse?.TRACKAREA || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Track Code"
            variant="outlined"
            name="TRACKCODE"
            value={horse?.TRACKCODE || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="Winning Margins"
            variant="outlined"
            name="WINNINGMARGINS"
            value={horse?.WINNINGMARGINS || ""}
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <TextField
            sx={style.TextField}
            fullWidth
            label="WP Margin"
            variant="outlined"
            name="WPMARGIN"
            value={horse?.WPMARGIN || ""}
            disabled={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
export default ViewHorse;
