import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { roleTypes, userRoles } from "Helpers/role-types.enum";
import { UserService } from "Services/UserService";
import { ShortErrorToast, ShortSuccessToast } from "components/Toast/Toastify";
import SelectDropdown from "components/select/Select";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const recipientOptions = ["Role", "Specific User", "Everyone"] as const;
type RecipientType = (typeof recipientOptions)[number];

const EmailForm: React.FC = () => {
  const [recipientType, setRecipientType] = useState<RecipientType>("Role");
  const [role, setRole] = useState<roleTypes | "">("");
  const [specificUserEmail, setSpecificUserEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleRecipientTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    setRecipientType(event.target.value as RecipientType);
    setRole("");
    setSpecificUserEmail("");
  };

  const handleRoleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedRole = event.target.value as roleTypes;
    setRole(selectedRole);
  };

  const handleSubmit = async () => {
    if (!subject || !message) {
      ShortErrorToast({ message: "Please fill in all fields." });
      return;
    }

    const plainTextMessage = message;

    let sendEmail;

    if (recipientType === "Role" && role) {
      sendEmail = await UserService.sendEmailToRole(
        role,
        subject,
        plainTextMessage,
      );
    } else if (recipientType === "Specific User" && specificUserEmail) {
      // sendEmail = await UserService.sendEmailToUser({
      //     email: specificUserEmail,
      //     subject,
      //     message,
      // });
    } else if (recipientType === "Everyone") {
      sendEmail = await UserService.sendEmailToAll({
        subject,
        message: plainTextMessage,
      });
    } else {
      ShortErrorToast({ message: "Please select a valid recipient." });
      return;
    }

    if (sendEmail?.statusCode === 201) {
      ShortSuccessToast({ message: sendEmail?.message });
    } else {
      ShortErrorToast({ message: sendEmail?.message });
    }

    setRecipientType("Role");
    setRole("");
    setSpecificUserEmail("");
    setSubject("");
    setMessage("");
  };

  return (
    <Box
      sx={{
        maxWidth: 600,
        margin: "auto",
        padding: 3,
        boxShadow: 3,
        borderRadius: 2,
        backgroundColor: "#fff",
      }}
    >
      <Typography variant="h5" component="h1" gutterBottom align="center">
        Send Email
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth required>
            <SelectDropdown
              label="Recipient Type"
              value={recipientType}
              onChange={handleRecipientTypeChange}
              items={recipientOptions.map((r) => r)}
            />
          </FormControl>
        </Grid>

        {recipientType === "Role" && (
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <SelectDropdown
                label="Roles"
                value={role}
                onChange={handleRoleChange}
                items={userRoles}
              />
            </FormControl>
          </Grid>
        )}

        {recipientType === "Specific User" && (
          <Grid item xs={12}>
            <TextField
              label="User Email"
              type="email"
              value={specificUserEmail}
              onChange={(e) => setSpecificUserEmail(e.target.value)}
              fullWidth
              required
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <TextField
            label="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            fullWidth
            required
          />
        </Grid>
        <Grid item xs={12} spacing={2}>
          <Typography variant="body1" gutterBottom>
            Message
          </Typography>
          <ReactQuill
            theme="snow"
            value={message}
            onChange={setMessage}
            placeholder="Write your message here..."
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={
              !subject ||
              !message ||
              (recipientType === "Role" && !role) ||
              (recipientType === "Specific User" && !specificUserEmail)
            }
          >
            Send Email
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailForm;
