// Table.tsx
import { Box, TextField, Typography } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid";
import { Mobile } from "Helpers/Constants";
import Markee from "components/markee/markee";
import React, { useCallback, useMemo, useState } from "react";

interface TableProps {
  columns: GridColDef[];
  rows: any[];
  searchable: boolean;
  disableRowSelectionOnClick?: boolean;
  title?: string;
  autoPageSize?: boolean;
  setEditedRow?: (row: any) => void; // Optional, no longer used in render
  onCellEditStart?: (params: any) => void;
  processRowUpdate?: (newRow: any, oldRow: any) => any;
  defaultSortKey?: string;
  defaultSortDirection?: GridSortDirection;
  loading?: boolean;
}

function Table({
  columns,
  rows,
  searchable,
  disableRowSelectionOnClick,
  title,
  autoPageSize,
  setEditedRow, // No longer used in isCellEditable
  onCellEditStart,
  processRowUpdate,
  defaultSortKey = "userCreatedAt",
  defaultSortDirection = "desc",
  loading = false,
}: TableProps) {
  const [searchText, setSearchText] = useState("");
  const [rowData, setRowData] = useState(rows);

  const [sortModel, setSortModel] = useState<GridSortModel>(
    defaultSortKey
      ? [{ field: defaultSortKey, sort: defaultSortDirection }]
      : [],
  );

  // Update sort model if defaultSortKey or defaultSortDirection changes
  React.useEffect(() => {
    if (defaultSortKey) {
      setSortModel([{ field: defaultSortKey, sort: defaultSortDirection }]);
    }
  }, [defaultSortKey, defaultSortDirection]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const handleProcessRowUpdate = (newRow: any, oldRow: any) => {
    if (processRowUpdate) {
      return processRowUpdate(newRow, oldRow);
    }
    const updatedRows = rowData.map((row) =>
      row.id === oldRow.id ? newRow : row,
    );
    setRowData(updatedRows);
    return newRow;
  };

  function useSearch(rows: any[], searchText: string) {
    return useMemo(() => {
      return rows
        .filter((row: any) => {
          const searchableString = Object.values(row).join(" ").toLowerCase();
          return searchableString.includes(searchText.toLowerCase());
        })
        .map((row: any, index: number) => ({ ...row, id: index }));
    }, [rows, searchText]);
  }

  const filteredRows = useSearch(rows, searchText);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {searchable && (
        <Box
          sx={{
            display: "flex",
            justifyContent: title ? "space-between" : "flex-end",
            p: 1,
          }}
        >
          {!Mobile() && title && (
            <Typography textAlign={"left"} variant={"h4"}>
              <Markee text={title} />
            </Typography>
          )}
          <TextField
            id="search-bar"
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={handleSearchChange}
            fullWidth
            sx={{
              width: "100%",
              maxWidth: "500px",
              "& .MuiInputLabel-outlined": { color: "darkcyan" },
              "& .MuiOutlinedInput-notchedOutline": { borderColor: "darkcyan" },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                { borderColor: "darkcyan" },
              "& .MuiOutlinedInput-input": { color: "black" },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                color: "black",
              },
              "& .MuiInputLabel-outlined.Mui-focused": { color: "darkcyan" },
            }}
          />
        </Box>
      )}
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          onCellEditStart={onCellEditStart}
          processRowUpdate={handleProcessRowUpdate}
          isCellEditable={(params: any) => {
            return params.colDef.editable;
          }}
          showCellVerticalBorder={false}
          disableRowSelectionOnClick={disableRowSelectionOnClick ?? true}
          density={"compact"}
          rows={filteredRows}
          columns={columns}
          pagination
          pageSizeOptions={[
            { label: "14", value: 14 },
            { label: "25", value: 25 },
            { label: "50", value: 50 },
            { label: "100", value: 100 },
          ]}
          sortModel={sortModel}
          onSortModelChange={(model) => {
            setSortModel(model);
          }}
          loading={loading}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#99c4c4",
              color: "#fff",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold",
            },
            "& .MuiDataGrid-sortIcon": {
              color: "black",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-menuIcon": {
              color: "black",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            ".MuiDataGrid-root": {
              fontSize: "1rem",
            },
            ".MuiDataGrid-loading": {
              color: "darkcyan",
            },
          }}
        />
      </Box>
    </Box>
  );
}

export default React.memo(Table);
