enum raceStatusTypes {
  OPEN = "Open",
  CLOSED = "Closed",
  ABND = "Abandoned",
}

export const raceStatusMapping = [
  raceStatusTypes.OPEN,
  raceStatusTypes.CLOSED,
  raceStatusTypes.ABND,
];
