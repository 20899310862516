import { Box } from "@mui/material";
import InfoCard from "components/Card/InfoCard";
import SkeletonLoading from "components/Skeleton/SkeletonLoading";
import { ShortErrorToast } from "components/Toast/Toastify";
import React, { useEffect, useState } from "react";
import { UserService } from "Services/UserService";
import { socket } from "Socket"; // import your socket instance

interface CardInfo {
  title: string;
  link?: string;
  info?: any;
}

export default function Homepage() {
  const [cardsInformation, setCardsInformation] = useState<CardInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchCardsInformation = async () => {
    try {
      setLoading(true);
      const res = await UserService.getHomeCardsInformation();
      if (res?.data?.statusCode === 201) {
        const sortedData = res.data.data.map((card: CardInfo) => {
          if (Array.isArray(card.info)) {
            const sortedInfo = [...card.info].sort(
              (a: any, b: any) => b.count - a.count,
            );
            return {
              ...card,
              info: sortedInfo,
            };
          }
          return card;
        });
        setCardsInformation(sortedData);
      } else {
        ShortErrorToast({ message: "Failed to retrieve cards information." });
      }
    } catch (error) {
      console.error("Fetch Cards Information Error:", error);
      ShortErrorToast({
        message: "An error occurred while fetching cards information.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCardsInformation();

    socket.on("userChangeData", () => {
      console.log("Real-time update detected, fetching latest data...");
      fetchCardsInformation(); // Fetch updated data when a change is detected
    });

    return () => {
      socket.off("userChangeData");
    };
  }, []);

  const renderCardInfo = (info: any) => {
    if (Array.isArray(info)) {
      return (
        <ul>
          {info.map((infoItem: any, idx: number) => (
            <li key={infoItem._id ? `${infoItem._id}-${idx}` : idx}>
              {Array.isArray(infoItem._id)
                ? infoItem._id.join(", ") + ": "
                : `${infoItem._id}: `}{" "}
              {infoItem.count}
            </li>
          ))}
        </ul>
      );
    } else if (
      typeof info === "object" &&
      info !== null &&
      "total" in info &&
      "verified" in info
    ) {
      return (
        <>
          <div>Total: {info.total}</div>
          <div>Verified: {info.verified}</div>
        </>
      );
    } else if (typeof info === "string") {
      return <div>{info}</div>;
    } else {
      return <div>Unknown Information</div>;
    }
  };

  return !loading ? (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
        justifyContent: "flex-start",
      }}
    >
      {cardsInformation.map((card) => (
        <InfoCard
          key={card.title}
          title={card.title}
          link={card.link || ""}
          info={renderCardInfo(card.info)}
        />
      ))}
    </Box>
  ) : (
    <SkeletonLoading skeletonCardCount={7} />
  );
}
