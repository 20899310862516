export const venuesFromPostgresAdapter = (values: any[]) => {
  return values.map((value) => ({
    _id: value.$id,
    name: value.venueName,
    trackCode: value.trackCode,
    trackCondition: value.trackcondition,
    races: RacesFromPostgresAdapter(value.races),
  }));
};

export const RacesFromPostgresAdapter = (values: any[]) => {
  return values.map((value) => ({
    _id: value.$id,
    classes: value.classes,
    distance: value.distance,
    dateTimeUtc: value.eventTime,
    hasFixedPrices: value.hasFixedPrice,
    hasSpg: value.hasSpg,
    isFuture: value.isFuture,
    ptpResult: value.ptpResult,
    racePrize: value.racePrize,
    resultStatus: value.resultStatus,
    sequence: value.sequence,
    silksUrl: value.silksUrl,
    status: value.status,
    trackCondition: value.trackcondition,
    venueId: value.venueId,
  }));
};
