import { Grid, Typography } from "@mui/material";
import AddVenueCard from "components/Card/AddVenueCard";
import EditVenueCard from "components/Card/EditVenueCard";
import Table from "components/Table/Table";
import { Mobile } from "Helpers/Constants";
import { useGeneralContext } from "pages/context/Context";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { UserService } from "Services/UserService";
import { socket } from "Socket";
import { VenueViewColumns } from "./VenueColumns";

function Venues() {
  const { getData, setData } = useGeneralContext();

  const [venues, setVenues] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);

  const fetchVenuesInfo = useCallback(async () => {
    const cachedVenues = await getData<any[]>("venues");
    if (cachedVenues && cachedVenues.length > 0) {
      setVenues(cachedVenues);
    } else {
      const venuesInfo = await UserService.getVenuesInformation();
      if (venuesInfo?.data?.statusCode === 201) {
        const venuesData = venuesInfo.data.data;
        setVenues(venuesData);
        await setData("venues", venuesData);
      }
    }
    setLoading(false);
  }, [getData, setData]);

  useEffect(() => {
    fetchVenuesInfo();

    socket.on("venuesChangeData", (change) => {
      const { operationType, fullDocument, documentKey, updateDescription } =
        change;
      const updatedVenueId = documentKey?._id;

      if (operationType === "insert" && fullDocument) {
        setVenues((prevVenues) => {
          const newVenues = [...prevVenues, fullDocument];
          setData("venues", newVenues);
          return newVenues;
        });
      } else if (
        operationType === "update" &&
        updateDescription?.updatedFields
      ) {
        const updatedFields = updateDescription.updatedFields;

        setVenues((prevVenues) => {
          const updatedVenues = prevVenues.map((venue) =>
            venue._id === updatedVenueId
              ? { ...venue, ...updatedFields }
              : venue,
          );

          setData("venues", updatedVenues);
          return updatedVenues;
        });
      } else if (operationType === "delete") {
        setVenues((prevVenues) => {
          const updatedVenues = prevVenues.filter(
            (venue) => venue._id !== updatedVenueId,
          );
          setData("venues", updatedVenues);
          return updatedVenues;
        });
      }
    });

    return () => {
      socket.off("venuesChangeData");
    };
  }, [fetchVenuesInfo, setData]);

  const updateVenue = useCallback(
    async (updatedVenue: any) => {
      try {
        const updatedVenues = venues.map((venue: any) =>
          venue._id === updatedVenue._id ? updatedVenue : venue,
        );
        setVenues(updatedVenues);

        const res = await UserService.editVenueInformation(updatedVenue);
        if (res?.data?.statusCode === 201) {
          await setData("venues", updatedVenues);
          socket.emit("venuesChangeData", {
            operationType: "update",
            documentKey: { _id: updatedVenue._id },
            updateDescription: { updatedFields: updatedVenue },
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [venues, setData],
  );

  const processRowUpdate = useCallback(
    async (newRow: any) => {
      await updateVenue(newRow);
      return newRow;
    },
    [updateVenue],
  );

  const memoizedColumns = useMemo(() => VenueViewColumns, []);
  const memoizedVenues = useMemo(() => venues, [venues]);

  return (
    <>
      <Typography variant="h4">Controller - Venues</Typography>
      <Grid container spacing={1} mt={4}>
        <Grid item xs={12} lg={6}>
          <EditVenueCard />
        </Grid>
        <Grid item xs={12} lg={6}>
          <AddVenueCard fetchVenuesInfo={fetchVenuesInfo} />
        </Grid>
        {!Mobile() && (
          <Grid item xs={12} lg={12}>
            <Table
              loading={loading}
              processRowUpdate={processRowUpdate}
              columns={memoizedColumns}
              rows={memoizedVenues}
              searchable
              title="Venues"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default Venues;
